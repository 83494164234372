/* eslint-disable default-case */
import * as React from 'react';
import { GridComponent, ColumnsDirective, ColumnDirective, Toolbar, ExcelExport, PdfExport, CommandColumn, Edit, Filter, Inject, Sort } from '@syncfusion/ej2-react-grids';
import { DateTimePickerComponent } from '@syncfusion/ej2-react-calendars';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import * as CustomJS from '../Custom';
import { SampleBase } from './sampleBase';
import _ from 'underscore/underscore-min';
import { ToastContainer, toast, Slide, Zoom, Flip, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import $ from 'jquery';
import moment from 'moment';

let GridData = [];

function headerTemplate(props) {
    return (<div>
        <span style={{ fontSize: '1.6vw', fontWeight: 'bolder' }}>{props.headerText}</span>
    </div>);
}

function fieldTemplate(props) { 
    let colore = (props.column.field === 'DataOraInizio' || props.column.field === 'DataOraFine') ? 'purple' : '#133B95';   
    let renderDiv = (props.column.field === 'NuovoCanc' && props.Stato === 'N') ? <div style={{ paddingTop: '7px' }}><span style={{ fontSize: '2.1vw', color: 'green', fontWeight: 'bolder' }}>N</span></div> 
                    : (props.column.field === 'NuovoCanc' && props.Stato === 'D') ? <div style={{ paddingTop: '7px' }}><span style={{ fontSize: '2.1vw', color: 'darkred', fontWeight: 'bolder' }}>C</span></div> 
                    : (props.column.field === 'DataOraInizio' || props.column.field === 'DataOraFine') ? <div><span style={{ fontSize: '1.4vw', color: colore, fontWeight: 'bolder' }}>{props[props.column.field]}</span></div> 
                    : props.column.field === 'DescrAddetto' ? <div><span style={{ fontSize: '1.1vw', color: colore, fontWeight: 'bolder' }}>{props[props.column.field]}</span></div>
                    : <div><span style={{ fontSize: '1.8vw', color: colore, fontWeight: 'bolder' }}>{props[props.column.field]}</span></div>;
    return (renderDiv);    
}

export default class GridCronologia extends SampleBase {
    constructor() {
        super(...arguments); 
        this.toolbarOptions = ['Add'];
        this.editSettings = { showDeleteConfirmDialog: true, allowAdding: true, allowDeleting: true, allowEditing: true, mode: 'Normal'};
        this.validationRules = { required: true };
        this.editparams = { params: { allowEdit: false, format: 'dd/MM/yyyy HH:mm' } };
        this.format = { format: 'dd/MM/yyyy HH:mm' };
        this.ddlFields = { text: 'TEXT', value: 'VALUE' };
        this.sortSettings = { columns: [{ field: 'DataOraInizio', direction: 'Descending' }, { field: 'DataOraFine', direction: 'Descending' }] };
        this.commands = [{ type: "Edit", buttonOption: { iconCss: "fas fa-edit fa-10x", cssClass: "e-details" } },                         
                         { type: "Delete", buttonOption: { iconCss: "fas fa-trash-alt fa-10x", cssClass: "e-details" } },
                         { type: "Ripristina Evento", buttonOption: { iconCss: "fas fa-undo fa-10x", cssClass: "e-details" } } ];  
        this.dialogButtons1 = [{
            click: () => {
                if (CustomJS.isNullEmpty(this.Dtp1.value) || CustomJS.isNullEmpty(this.Dtp2.value) || CustomJS.isNullEmpty(this.Txt1.value?.trim())
                    || (new Date(moment(this.Dtp2.value).format('YYYY/MM/DD HH:mm')) - new Date(moment(this.Dtp1.value).format('YYYY/MM/DD HH:mm'))) < 0)
                    return;
                if (!this.azioneInsert) {
                    let selRow = _.find(this.gridInstance1.dataSource, elm => { return elm.Guid === this.salvaGuid });
                    selRow.DataOraInizio = moment(this.Dtp1.value).format('DD/MM/YYYY HH:mm');
                    selRow.DataOraFine = moment(this.Dtp2.value).format('DD/MM/YYYY HH:mm');
                    selRow.DescrIntervento = `<p>${this.Txt1.value?.replace(/(?:\r\n|\r|\n)/g, '<br>').trim()}</p>`;
                    selRow.UtenteIntervento = CustomJS.isNullEmpty(this.ddlInstance.value) ? JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileAuthenticationPayload')).data[0].COD_ELE : this.ddlInstance.value;
                    selRow.DescrAddetto = CustomJS.isNullEmpty(this.ddlInstance.value) ? JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileAuthenticationPayload')).data[0].DESCRIZ : this.ddlInstance.text; 
                }
                else {                    
                    let objCron = {
                        Guid: CustomJS.createGuid4(),
                        GuidIncarico: JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).GuidIncarico,
                        DataOraInizio: moment(this.Dtp1.value).format('DD/MM/YYYY HH:mm'),
                        DataOraFine: moment(this.Dtp2.value).format('DD/MM/YYYY HH:mm'),
                        DescrIntervento: `<p>${this.Txt1.value?.replace(/(?:\r\n|\r|\n)/g, '<br>').trim()}</p>`,
                        UtenteIntervento: CustomJS.isNullEmpty(this.ddlInstance.value) ? JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileAuthenticationPayload')).data[0].COD_ELE : this.ddlInstance.value,
                        DescrAddetto: CustomJS.isNullEmpty(this.ddlInstance.value) ? JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileAuthenticationPayload')).data[0].DESCRIZ : this.ddlInstance.text,
                        Stato: 'N',
                        Stato_nuovo: 'N'
                    }
                    this.gridInstance1.dataSource.unshift(objCron);
                }
                this.gridInstance1.refresh();                
                this.dialogInstance1.hide();                                          
            },
            buttonModel: {
                content: 'SALVA',
                cssClass: 'e-primary',
                isPrimary: true
            }
        },
        {
            click: () => {
                this.dialogInstance1.hide();
            },
            buttonModel: {
                content: 'ANNULLA',
            }
        }];    
        this.dialogAnimationSettings = { effect: 'FadeZoom' };
        this.azioneInsert = false; 
        this.salvaGuid = '';
    }
    state = {
        GridData2: GridData,
        showDialog1: false
    }     
    onQueryCellInfo(args) {   
        if (args.column.field === "DescrIntervento")
            args.cell.innerHTML = `<div><span style="font-size: 1.8vw; color: #133B95; font-weight: bolder;">${args.cell.innerHTML}</span></div>`;
    }
    onDatabound(args) {
        for (let ind = 0; ind <= 6; ind++)
            document.getElementById('GridCronologia').querySelectorAll('.e-columnheader')[0].children[ind].style.backgroundColor = 'Linen';
    }
    onRowDatabound(args) { 
        if (args.data.Stato === 'N') {
            args.row.className = 'e-row';
            args.row.bgColor = 'lightgreen';    
        }
        if (args.data.Stato === 'D') {
            args.row.className = 'e-row';
            args.row.bgColor = 'lightcoral';
        }  
        if (args.data.Stato === 'D') {
            $($(args.row).find('button')[2]).show();
            $($(args.row).find('button')[1]).hide();
        }
        else {
            $($(args.row).find('button')[2]).hide();
            $($(args.row).find('button')[1]).show();
        }        
        if (JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileAuthenticationPayload')).data[0].TIPO_UTENTE === 'ADD'
            && args.data.UtenteIntervento !== JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileAuthenticationPayload')).data[0].COD_ELE) {
            $($(args.row).find('button')[0]).hide();
            $($(args.row).find('button')[1]).hide();
        }  
    }
    toolbarClick(args) {
        switch (args.item.properties.prefixIcon) {
            case 'e-pdfexport':
                this.gridInstance.pdfExport();
                break;
            case 'e-excelexport':
                this.gridInstance.excelExport();
                break;
            case 'e-csvexport':
                this.gridInstance.csvExport();
                break;
        }        
        if (args.item.id === 'GridCronologia_add') {
            args.cancel = true;            
            this.azioneInsert = true;
            this.dialogInstance1.show();
        }
    }
    onActionBegin(args) {
        if (args.requestType === "beginEdit") {
            args.cancel = true;
        }
    }
    onComplete(args) {
        if (args.requestType === "delete") {
            var objDoc = args.data[0];
            objDoc.Stato = 'D';
            document.getElementById('GridCronologia').ej2_instances[0].dataSource.push(objDoc);
            document.getElementById('GridCronologia').ej2_instances[0].refresh();
        }        
    }
    onCommandClick(args) {
        if (args.commandColumn.buttonOption.iconCss.indexOf('fa-undo') > -1) {            
            if (!CustomJS.isNullEmpty(args.rowData.Stato_nuovo))
                args.rowData.Stato = args.rowData.Stato_nuovo;
            else
                args.rowData.Stato = 'O';
            this.gridInstance1.refresh();
        } 
        if (args.commandColumn.buttonOption.iconCss.indexOf('fa-edit') > -1) {
            this.azioneInsert = false;
            this.dialogInstance1.show();
        }       
    }  
    dialogOpen1(args) {        
        if (this.azioneInsert) {
            this.dialogInstance1.header = 'Inserimento nuovo intervento';
            this.salvaGuid = '';
            this.Dtp1.value = new Date();
            this.Dtp2.value = new Date(); 
            this.Txt1.value = null;
            this.ddlAddettiInstance.value = null;
        }
        else {
            this.dialogInstance1.header = 'Modifica intervento';
            this.salvaGuid = this.gridInstance1.getSelectedRecords()[0].Guid;
            this.Dtp1.value = this.gridInstance1.getSelectedRecords()[0].DataOraInizio;
            this.Dtp2.value = this.gridInstance1.getSelectedRecords()[0].DataOraFine; 
            this.Txt1.value = this.gridInstance1.getSelectedRecords()[0].DescrIntervento?.replace(/<br>/g, '\n').replace(/<p>/g, '').replace(/<\/p>/g, '');
            this.ddlInstance.value = this.gridInstance1.getSelectedRecords()[0].UtenteIntervento;  
        } 
    }

    render() {
        return(<div className="control-section card-control-section basic_card_layout">
                     <ToastContainer containerId='toastContainer4' transition={Zoom} style={{ fontSize: '1.3vw', color: 'midnightblue', fontWeight: 'bold', width: '30vw' }}></ToastContainer>
                     <DialogComponent id="defaultDialog1" target='body' animationSettings={this.dialogAnimationSettings} visible={this.state.showDialog1} width='90%'
                                      ref={dialog => this.dialogInstance1 = dialog} height='55vh' minHeight='55vh' isModal={true} buttons={this.dialogButtons1} open={this.dialogOpen1.bind(this)}>
                            <div className="col-lg-12 control-section card-control-section basic_card_layout">
                                <div className="e-card-resize-container">
                                    <div className="card-layout">
                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">                                           
                                            <div tabIndex="0" className="e-card" id="main_card">                                                
                                                <div className="e-card-content">
                                                    <div className='row'>
                                                        <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                                                            <DateTimePickerComponent id='DataEventoDa' showClearButton={false} step={15} ref={dtp1 => this.Dtp1 = dtp1} format='dd/MM/yyyy HH:mm' allowEdit={false} cssClass='e-outline'></DateTimePickerComponent>
                                                        </div>
                                                        <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                                                            <DateTimePickerComponent id='DataEventoA' showClearButton={false} step={15} ref={dtp2 => this.Dtp2 = dtp2} format='dd/MM/yyyy HH:mm' allowEdit={false} cssClass='e-outline'></DateTimePickerComponent>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className="col-xs-12 col-sm-6 col-lg-4 col-md-4">
                                                        <DropDownListComponent id='Addetto' fields={this.ddlFields} ref={ ddlObj => this.ddlInstance = ddlObj } showClearButton={true}                                                             
                                                            placeholder="Addetto (se diverso da sè stessi)" floatLabelType='auto' popupHeight="250px" cssClass='e-outline'/>       
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                                            <TextBoxComponent id='DescrIntervento' showClearButton={true} value='' multiline={true} ref={txt1 => this.Txt1 = txt1} 
                                                                              floatLabelType='Auto' placeholder='Descrizione Intervento' cssClass='e-outline'>
                                                            </TextBoxComponent>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                     </DialogComponent>
                     <div className="e-card-resize-container">
                        <div className="card-layout">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">                                
                                <div tabIndex="0" className="e-card" id="main_card">
                                    <div className="e-card-header">
                                        <div className="e-card-header-caption">
                                            <span style={{ fontSize: '3.0vw', fontWeight: 'bolder', paddingLeft: '5px', color:'#133B95'}}>RESOCONTO INTERVENTO</span>
                                        </div>
                                    </div>
                                    <div className="e-card-content"> 
                                        <div className="row">
                                            <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>               
                                                <GridComponent id="GridCronologia" dataSource={this.state.GridData2} ref={ grid => this.gridInstance1 = grid } toolbar={this.toolbarOptions} actionBegin={this.onActionBegin.bind(this)}
                                                    editSettings={this.editSettings} sortSettings={this.sortSettings} queryCellInfo={this.onQueryCellInfo.bind(this)} actionComplete={this.onComplete} pageSettings={this.pageSettings}
                                                    allowSelection={true} enableHover={false} dataBound={this.onDatabound.bind(this)} rowDataBound={this.onRowDatabound.bind(this)} commandClick={this.onCommandClick.bind(this)}
                                                    toolbarClick={this.toolbarClick.bind(this)} allowTextWrap={true} allowPaging={false} allowSorting={true} allowFiltering={false} enableAltRow={true} enablePersistence={false}>
                                                    <ColumnsDirective>
                                                        <ColumnDirective field='Guid' visible={false} isPrimaryKey={false}></ColumnDirective>
                                                        <ColumnDirective field='DataOraInizio' headerText='Data/ora Inizio' format={this.format} editType='datetimepickeredit' validationRules={this.validationRules} edit={this.editparams} width='18%' headerTemplate={headerTemplate} template={fieldTemplate} textAlign='Center'></ColumnDirective>
                                                        <ColumnDirective field='DataOraFine' headerText='Data/ora Fine' format={this.format} editType='datetimepickeredit' validationRules={this.validationRules} edit={this.editparams} width='18%' headerTemplate={headerTemplate} template={fieldTemplate} textAlign='Center'></ColumnDirective>
                                                        <ColumnDirective field='DescrIntervento' headerText="Descrizione Intervento" width='43%' headerTemplate={headerTemplate} validationRules={this.validationRules} disableHtmlEncode={false} textAlign='Left' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                                        <ColumnDirective field='DescrAddetto' headerText="Addetto" width='10%' visible={JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileAuthenticationPayload')).data[0].TIPO_UTENTE === 'SQD'} headerTemplate={headerTemplate} template={fieldTemplate}></ColumnDirective>
                                                        <ColumnDirective field='NuovoCanc' headerText="" width='5%' template={fieldTemplate} textAlign='Center' allowEditing={false}></ColumnDirective>
                                                        <ColumnDirective field='Comandi' headerText="" commands={this.commands} width='5%' textAlign='Center'></ColumnDirective>
                                                    </ColumnsDirective>
                                                    <Inject services={[Toolbar, ExcelExport, PdfExport, Filter, Edit, CommandColumn, Sort]} />
                                                </GridComponent>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        );
    }
    rendereComplete() {
        this.caricaDati();
        this.Txt1.addAttributes({ rows: 5 });
        //Intervento per permettere di scrivere il resoconto intervento per TUTTI gli addetti e non solo per quelli assegnati all'incarico in quel momento
        // this.dsAddetti = JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).Addetti?.split(',');
        // this.dsAddetti = this.dsAddetti?.map(elm => {return { VALUE: elm, TEXT: _.find(JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileTabellaADD')), elm2 => { return elm2.VALUE === elm }).TEXT } });
        this.dsAddetti = JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileTabellaADD'));
        this.ddlInstance.dataSource = this.dsAddetti;
        this.ddlInstance.enabled = JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileAuthenticationPayload')).data[0].TIPO_UTENTE === 'SQD';
    }  
    caricaDati() {
        const CodDiv = JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileAuthenticationPayload')).data[0].COD_DIV,
        CodSoc = JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileAuthenticationPayload')).data[0].COD_SOC,
        CodFil = JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileAuthenticationPayload')).data[0].COD_FIL,
        GuidIncarico = JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).GuidIncarico,
        GuidDocumento = 'NULL';

        CustomJS.callAPI(`${CustomJS.APIJfishTeamsURLProd}/api/JellyfishTeams/caricaListeIncarico/${CodDiv}/${CodSoc}/${CodFil}/${GuidIncarico}/${GuidDocumento}/CRON`, '', 'GET',
        '', JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileAuthenticationPayload')).token, retData => {
            if (retData.response === 'KO') {
                let messaggio = (retData.msg === 'UtenteNonAutorizzato') ? CustomJS.messaggioUtenteNonAutorizzato : (retData.msg === 'SessioneScaduta') ? CustomJS.messaggioSessioneScaduta : retData.msg;
                toast.warn(messaggio, {
                    onClose: () => toast.dismiss(),                    
                    position: "bottom-right",
                    autoClose: 15000,
                    hideProgressBar: false,            
                    closeOnClick: true, 
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                });
                return;
            }               
            GridData = [];
            retData.forEach(elm => {           
                GridData.push({ Guid: elm.GUID, GuidIncarico: elm.GUID_INCARICO, DataOraInizio: moment(elm.DATA_ORA_INIZIO).format('DD/MM/YYYY HH:mm'), 
                                DataOraFine: moment(elm.DATA_ORA_FINE).format('DD/MM/YYYY HH:mm'), DescrIntervento: elm.DESCRIZIONE_INTERVENTO, UtenteIntervento: elm.UTENTE_INTERVENTO,
                                DescrAddetto: CustomJS.isNullEmpty(_.find(JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileTabellaADD')), elm2 => { return elm2.VALUE === elm.UTENTE_INTERVENTO })?.TEXT) 
                                            ? JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileAuthenticationPayload')).data[0].DESCRIZ 
                                            : _.find(JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileTabellaADD')), elm2 => { return elm2.VALUE === elm.UTENTE_INTERVENTO })?.TEXT ,
                                Stato: 'O', NuovoCanc: '' });
            });
            this.setState({ GridData2: GridData});
        }, error => {
            let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
            toast.warn(messaggio, {
                onClose: () => toast.dismiss(),                    
                position: "bottom-right",
                autoClose: 15000,
                hideProgressBar: false,            
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true});        
        }, true, false);
    }  
}