/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable no-extend-native */
import * as React from 'react';
import { createRoot } from 'react-dom/client'
import { DropDownListComponent, MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';
import { FormValidator } from '@syncfusion/ej2-inputs';
import { DataManager, Query, Predicate } from '@syncfusion/ej2-data';
import { TabComponent, TabItemDirective, TabItemsDirective, TabAnimationSettings } from '@syncfusion/ej2-react-navigations';
import { TextBoxComponent, NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { ProgressButtonComponent } from '@syncfusion/ej2-react-splitbuttons';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { DatePickerComponent, DateTimePickerComponent } from '@syncfusion/ej2-react-calendars';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { RichTextEditorComponent, Toolbar as RTEtoolbar, Inject as RTEinject, Image, Link, HtmlEditor, Count, QuickToolbar, Table } from '@syncfusion/ej2-react-richtexteditor';
import SituazioneIncarichi from './SituazioneIncarichi';
import GridMaterialiManodopera from './GridMaterialiManodopera';
import GridCronologia from './GridCronologia';
import GridDocumentazioneSopralluogo from './GridDocumentazioneSopralluogo';
import GridDocumentazioneIncarico from './GridDocumentazioneIncarico';
import PaginaFirma from './PaginaFirma';
import { SampleBase } from './sampleBase';
import { ToastContainer, toast, Slide, Zoom, Flip, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BlockUi from '@availity/block-ui';
import { Browser, addClass, removeClass } from '@syncfusion/ej2-base';
import * as CustomJS from '../Custom';
import _ from 'underscore/underscore-min';
import $ from 'jquery';
import { random, isUndefined } from 'underscore';
import moment from 'moment';

Date.prototype.addHours = function(h) {
    this.setTime(this.getTime() + (h*60*60*1000));
    return this;
}

//Questo override serve per fare in modo che la funzione JSON.stringify non perda il time zone della data/ora (altrimenti mette sempre la data in UTC)
Date.prototype.toJSON = function () { return moment(this).format(); }

export default class DettaglioIncarico extends SampleBase {
    constructor() {
        super(...arguments);  
        this.childKey = 0;
        //this.toolbarOptions = ['ExcelExport', 'PdfExport', 'CsvExport', 'Search'];
        this.toolbarOptions = ['Search'];
        this.toggleBlocking = this.toggleBlocking.bind(this);        
        this.tabHeaderText = [{ text: "INFO DI BASE" }, { text: "MATERIALI/MANODOPERA LAVORI" }, { text: "RESOCONTO LAVORI ESEGUITI" }, { text: "DOCUMENTAZIONE SOPRALLUOGO" },
                              { text: "DOCUMENTAZIONE LAVORI" },{ text: "FIRMA FINE LAVORI" }]; 
        this.ddlFields = { text: 'TEXT', value: 'VALUE' };
        this.dsStati = JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).Stato === '00007' ?
                       JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileTabellaSTP')).filter(elm => { return elm.VALUE === '00007' || elm.VALUE === '00008' || elm.VALUE === '00009'; })
                       : JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).Stato === '00008' ?
                       JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileTabellaSTP')).filter(elm => { return elm.VALUE === '00008' || elm.VALUE === '00009'; })
                       : JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).Stato === '00009' ?
                       JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileTabellaSTP')).filter(elm => { return elm.VALUE === '00009'; }) 
                       : [];
        this.dsClienti = JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).GuidIncarico === 'NEW' ? JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileTabellaCLI')).filter(elm => { return elm.TEXT.indexOf('[A]') === -1 }) : JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileTabellaCLI'));
        this.dsCantieri = JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).GuidIncarico === 'NEW' ? JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileTabellaCAN')).filter(elm => { return elm.TEXT.indexOf('[A]') === -1 }) : JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileTabellaCAN'));
        this.dsProv = JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileTabellaPRV'));
        this.dsNaz = JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileTabellaNAZ'));
        this.dsAddetti = JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileTabellaADD')).filter(elm => {return !CustomJS.isNullEmpty(elm.VALUE)});
        this.dsCSAss = _.filter(JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileTabellaSQD')), elm => { return !CustomJS.isNullEmpty(elm.VALUE) && (elm.SERVIZIO_STR4 === 'S' || 
                                JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileAuthenticationPayload')).data[0].LISTA_SQD_VEDI_ASS?.indexOf('ALL') > -1 || 
                                JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileAuthenticationPayload')).data[0].LISTA_SQD_VEDI_ASS?.indexOf(elm.VALUE) > -1 || 
                                JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileAuthenticationPayload')).data[0].COD_ELE === elm.VALUE) });
        this.RTEitems = ['Bold', 'Italic', 'Underline', 'StrikeThrough',
            'FontName', 'FontSize', 'FontColor', 'BackgroundColor',
            'LowerCase', 'UpperCase', '|',
            'Formats', 'Alignments', 'OrderedList', 'UnorderedList',
            'Outdent', 'Indent', '|',
            'CreateTable', 'CreateLink', '|', 'ClearFormat', 'Print',
            'FullScreen', '|', 'Undo', 'Redo'
        ];
        this.RTEtoolbarSettings = {
            items: this.RTEitems
        };
        this.RTEiframeSettings = { enable: true };
        this.dialogButtons1 = [{
            click: () => {
                this.dialogInstance1.hide();    
                setTimeout(() => {
                    sessionStorage.removeItem('JellyfishTeamsMobileDatiIncarico');
                    ++this.childKey;
                    const container = document.getElementById('contenutoDinamico2');
                    const root = createRoot(container); 
                    root.render(<SituazioneIncarichi key={this.childKey}></SituazioneIncarichi>); 
                }, 200);            
            },
            buttonModel: {
                content: 'ESCI',
                cssClass: 'e-primary',
                isPrimary: true
            }
        },
        {
            click: () => {
                this.dialogInstance1.hide();
            },
            buttonModel: {
                content: 'ANNULLA',
            }
        }];
        this.dialogButtons2 = [{
            click: () => {
                this.dialogInstance1.hide();    
                setTimeout(() => {
                    let NumeroIncarico = JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).NumeroIncarico;
                    this.eseguiSalva(NumeroIncarico, true)  
                }, 200);            
            },
            buttonModel: {
                content: 'CONFERMA',
                cssClass: 'e-primary',
                isPrimary: true
            }
        },
        {
            click: () => {
                this.dialogInstance1.hide();
            },
            buttonModel: {
                content: 'ANNULLA',
            }
        }];
        this.dialogAnimationSettings = { effect: 'FadeZoom' };  
        this.formOptions = {
            rules: {
                'Stato': {
                    required: [true, 'Stato è un campo obbligatorio']
                },
                'DataInizioLavori': {
                    required: [true, 'Data Inizio Lavori è un campo obbligatorio']
                },
                'Cliente': {
                    required: [true, 'Cliente è un campo obbligatorio']
                },
                'Indirizzo': {
                    required: [true, 'Indirizzo è un campo obbligatorio'],
                    maxLength: [100, 'Lunghezza massima 100 caratteri']
                },
                'Citta': {
                    required: [true, 'Citta è un campo obbligatorio'],
                    maxLength: [50, 'Lunghezza massima 50 caratteri']
                },
                'CAP': {
                    maxLength: [10, 'Lunghezza massima 10 caratteri']
                },
                'Contatto': {
                    maxLength: [50, 'Lunghezza massima 50 caratteri']
                },
                // 'Telefono': {
                //     required: [true, 'Telefono è un campo obbligatorio'],
                //     maxLength: [50, 'Lunghezza massima 50 caratteri']
                // }
            }
        }; 
        this.listaEventiCreaGoogle = [];     
    }
    state = {
        blocking: false,       
        showDialog1: false,
        showDialog2: false 
    }
    toggleBlocking() {        
        this.setState({blocking: !this.state.blocking});
    }  
    onTagging(e) {  
        e.setClass(CustomJS.colorsData[random(26)].Color.toLowerCase());
    }    
    onChangeStati(args) {
        if (args.value === "00007" || args.value === "00009") {
            this.tabInstance.hideTab(2, true);
            this.tabInstance.hideTab(5, true);
            if (args.value === "00009") {   
                if (JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileAuthenticationPayload')).data[0].TIPO_UTENTE === 'SQD')                
                    this.tabInstance.hideTab(5, false);  
                this.tabInstance.hideTab(2, false);        
                document.getElementById('divDataFineLavori').style.removeProperty('display');
                document.getElementById('DataFineLavori').ej2_instances[0].value = new Date();
                this.formObject.rules.DataFineLavori = {
                    required: [true, 'Data Fine Lavori è un campo obbligatorio']
                };                
            }
            else {
                document.getElementById('divDataFineLavori').style.display = 'none';
                document.getElementById('DataFineLavori').ej2_instances[0].value = null;
                delete this.formObject.rules.DataFineLavori;  
            }
        }
        else {
            this.tabInstance.hideTab(1, false);
            this.tabInstance.hideTab(2, false);
            this.tabInstance.hideTab(5, true);
            document.getElementById('divDataFineLavori').style.display = 'none';
            document.getElementById('DataFineLavori').ej2_instances[0].value = null;
            delete this.formObject.rules.DataFineLavori;            
        }
        //TODO Verificare se c'era un motivo per cui la Durata Prevista era stata mesa obbligatoria
        // if (args.value === "00007" || args.value === "00008") {
        //     this.formObject.rules.DurataPrevistaLavori = {
        //         required: [true, 'Durata Prevista Lavori è un campo obbligatorio']
        //     };  
        // }
        // else
        //     delete this.formObject.rules.DurataPrevistaLavori;  
    }   
    onChangeCliente(args) {
        let ddlObj = document.getElementById('Cantiere').ej2_instances[0];
        ddlObj.enabled = !CustomJS.isNullEmpty(args.value);
        let tempQuery = new Query().where('SERVIZIO_STR1','equal', args.value);
        ddlObj.query = tempQuery;
        ddlObj.value = null;
        ddlObj.text = null;
        ddlObj.dataBind();
        if (CustomJS.isNullEmpty(args.itemData)) return;
        document.getElementById('Indirizzo').ej2_instances[0].value = args.itemData.SERVIZIO_STR3;
        document.getElementById('Citta').ej2_instances[0].value = args.itemData.SERVIZIO_STR4;
        document.getElementById('CAP').ej2_instances[0].value = args.itemData.SERVIZIO_STR5;
        document.getElementById('Provincia').ej2_instances[0].value = args.itemData.SERVIZIO_STR6;
        document.getElementById('Nazione').ej2_instances[0].value = args.itemData.SERVIZIO_STR7;
        document.getElementById('Contatto').ej2_instances[0].value = args.itemData.SERVIZIO_STR8;
        document.getElementById('Telefono').ej2_instances[0].value = args.itemData.SERVIZIO_STR9;
        document.getElementById('Email').ej2_instances[0].value = args.itemData.SERVIZIO_STR10;
    }  
    onChangeCantiere(args) {
        if (!args.isInteracted) return;
        if (CustomJS.isNullEmpty(args.value)) {
            document.getElementById('Indirizzo').ej2_instances[0].value = JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).Indirizzo;
            document.getElementById('Citta').ej2_instances[0].value = JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).Citta;
            document.getElementById('Provincia').ej2_instances[0].value = JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).Provincia;
            document.getElementById('Nazione').ej2_instances[0].value = JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).Nazione;
            document.getElementById('Telefono').ej2_instances[0].value = JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).Citta;
            document.getElementById('Contatto').ej2_instances[0].value = '@Model.Contatto';
        }
        else {
            document.getElementById('Indirizzo').ej2_instances[0].value = args.itemData.SERVIZIO_STR2;
            document.getElementById('Citta').ej2_instances[0].value = args.itemData.SERVIZIO_STR3;
            document.getElementById('Provincia').ej2_instances[0].value = args.itemData.SERVIZIO_STR4;
            document.getElementById('Nazione').ej2_instances[0].value = args.itemData.SERVIZIO_STR5;
            document.getElementById('Telefono').ej2_instances[0].value = args.itemData.SERVIZIO_STR7;
            document.getElementById('Contatto').ej2_instances[0].value = args.itemData.SERVIZIO_STR6;
        }
    }
    visualizzaCampoCliente() {
        document.getElementById('NuovoCliente').ej2_instances[0].value = null;
        document.getElementById('btnAddCli').style.removeProperty('display');
        document.getElementById('btnCancAdd').style.removeProperty('display');
        document.getElementById('btnNewCli').style.display = 'none';
        document.getElementById('divCampoNuovoCliente').style.removeProperty('display');
        document.getElementById('divCampoCliente').style.display = 'none';
        document.getElementById('Cliente').ej2_instances[0].value = null;
        document.getElementById('Indirizzo').ej2_instances[0].value = null;
        document.getElementById('Citta').ej2_instances[0].value = null;
        document.getElementById('CAP').ej2_instances[0].value = null;
        document.getElementById('Provincia').ej2_instances[0].value = null;
        document.getElementById('Nazione').ej2_instances[0].value = null;
        document.getElementById('Contatto').ej2_instances[0].value = null;
        document.getElementById('Telefono').ej2_instances[0].value = null;
        document.getElementById('Email').ej2_instances[0].value = null;
        document.getElementById('Email').ej2_instances[0].readonly = false;
    }
    annullaNuovoCliente() {
        document.getElementById('btnAddCli').style.display = 'none';
        document.getElementById('btnCancAdd').style.display = 'none';
        document.getElementById('btnNewCli').style.removeProperty('display');
        document.getElementById('divCampoNuovoCliente').style.display = 'none';
        document.getElementById('divCampoCliente').style.removeProperty('display');
        document.getElementById('Cliente').ej2_instances[0].value = null;
        document.getElementById('Indirizzo').ej2_instances[0].value = null;
        document.getElementById('Citta').ej2_instances[0].value = null;
        document.getElementById('CAP').ej2_instances[0].value = null;
        document.getElementById('Provincia').ej2_instances[0].value = null;
        document.getElementById('Nazione').ej2_instances[0].value = null;
        document.getElementById('Contatto').ej2_instances[0].value = null;
        document.getElementById('Telefono').ej2_instances[0].value = null;
        document.getElementById('Email').ej2_instances[0].value = null;
        document.getElementById('Email').ej2_instances[0].readonly = true;
    }
    creaNuovoCliente() {
        if (CustomJS.isNullEmpty(document.getElementById('NuovoCliente').ej2_instances[0].value?.trim())) {
            document.getElementById('NuovoCliente').ej2_instances[0].value = null;
            return;
        } 
        let newGuid = CustomJS.createGuid4(); 
        let newCode = CustomJS.makeID(10);
        let cliObj = {
            SERVIZIO_STR1: newCode,
            SERVIZIO_STR2: null,
            SERVIZIO_STR3: CustomJS.isNullEmpty(document.getElementById('Indirizzo').ej2_instances[0].value?.trim()) ? null : document.getElementById('Indirizzo').ej2_instances[0].value?.trim(),
            SERVIZIO_STR4: CustomJS.isNullEmpty(document.getElementById('Citta').ej2_instances[0].value?.trim()) ? null : document.getElementById('Citta').ej2_instances[0].value?.trim(),
            SERVIZIO_STR5: CustomJS.isNullEmpty(document.getElementById('CAP').ej2_instances[0].value?.trim()) ? null : document.getElementById('CAP').ej2_instances[0].value?.trim(),
            SERVIZIO_STR6: document.getElementById('Provincia').ej2_instances[0].value,
            SERVIZIO_STR7: document.getElementById('Nazione').ej2_instances[0].value,
            SERVIZIO_STR8: CustomJS.isNullEmpty(document.getElementById('Contatto').ej2_instances[0].value?.trim()) ? null : document.getElementById('Contatto').ej2_instances[0].value?.trim(),
            SERVIZIO_STR9: CustomJS.isNullEmpty(document.getElementById('Telefono').ej2_instances[0].value?.trim()) ? null : document.getElementById('Telefono').ej2_instances[0].value?.trim(),
            SERVIZIO_STR10: CustomJS.isNullEmpty(document.getElementById('Email').ej2_instances[0].value?.trim()) ? null : document.getElementById('Email').ej2_instances[0].value?.trim(),
            TEXT: document.getElementById('NuovoCliente').ej2_instances[0].value?.trim(),
            VALUE: newGuid
        }
        let dsCli = JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileTabellaCLI'));
        dsCli.push(cliObj); 
        sessionStorage.setItem('JellyfishTeamsMobileTabellaCLI', JSON.stringify(dsCli));         
        document.getElementById('NuovoCliente').ej2_instances[0].value = null;     
        document.getElementById('btnAddCli').style.display = 'none';
        document.getElementById('btnCancAdd').style.display = 'none';
        document.getElementById('btnNewCli').style.removeProperty('display');
        document.getElementById('divCampoNuovoCliente').style.display = 'none';
        document.getElementById('divCampoCliente').style.removeProperty('display');
        document.getElementById('Cliente').ej2_instances[0].value = null;
        document.getElementById('Indirizzo').ej2_instances[0].value = null;
        document.getElementById('Citta').ej2_instances[0].value = null;
        document.getElementById('CAP').ej2_instances[0].value = null;
        document.getElementById('Provincia').ej2_instances[0].value = null;
        document.getElementById('Nazione').ej2_instances[0].value = null;
        document.getElementById('Contatto').ej2_instances[0].value = null;
        document.getElementById('Telefono').ej2_instances[0].value = null;
        document.getElementById('Email').ej2_instances[0].value = null;
        document.getElementById('Email').ej2_instances[0].readonly = true;
        this.ddlCliInstance.dataSource = JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileTabellaCLI'));
        this.ddlCliInstance.value = newGuid;
    }
    handleFullScreen(e) {
        let sbCntEle = document.querySelector('.sb-content.e-view');
        let sbHdrEle = document.querySelector('.sb-header.e-view');
        let leftBar;
        let transformElement;
        if (Browser.isDevice) {
            leftBar = document.querySelector('#right-sidebar');
            transformElement = document.querySelector('.sample-browser.e-view.e-content-animation');
        }
        else {
            leftBar = document.querySelector('#left-sidebar');
            transformElement = document.querySelector('#right-pane');
        }
        if (e.targetItem === 'Maximize') {
            if (Browser.isDevice && Browser.isIos) {
                addClass([sbCntEle, sbHdrEle], ['hide-header']);
            }
            addClass([leftBar], ['e-close']);
            removeClass([leftBar], ['e-open']);
            if (!Browser.isDevice) {
                transformElement.style.marginLeft = '0px';
            }
            transformElement.style.transform = 'inherit';
        }
        else if (e.targetItem === 'Minimize') {
            if (Browser.isDevice && Browser.isIos) {
                removeClass([sbCntEle, sbHdrEle], ['hide-header']);
            }
            removeClass([leftBar], ['e-close']);
            if (!Browser.isDevice) {
                addClass([leftBar], ['e-open']);
                transformElement.style.marginLeft = leftBar.offsetWidth + 'px';
            }
            transformElement.style.transform = 'translateX(0px)';
        }
    }     
    tabContent0(args) {
        return(<React.Fragment>
                <form id="formIncarico"  method="post">
                    <div className="control-section card-control-section basic_card_layout">
                        <div className="e-card-resize-container">
                            <div className="card-layout">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">                                
                                    <div tabIndex="0" className="e-card" id="main_card">
                                        <div className="e-card-header">
                                            <div className="e-card-header-caption">
                                                <div className="row">
                                                    <div className='col-lg-4 col-md-4 col-sm-6 col-xs-6'>
                                                        <span style={{ fontSize: '3.0vw', fontWeight: 'bolder', paddingLeft: '5px', color:'#133B95'}}>INFO DI BASE</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="e-card-content">
                                            <div className="row">
                                                <div className='col-lg-4 col-md-4 col-sm-6 col-xs-6 mt-3'>
                                                    <DropDownListComponent id='Stato' fields={this.ddlFields} ref={ ddlObj => this.ddlStatiInstance = ddlObj } 
                                                        readonly={JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileAuthenticationPayload')).data[0].TIPO_UTENTE === 'ADD'}
                                                        dataSource={this.dsStati} change={this.onChangeStati.bind(this)} 
                                                        value={JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).Stato} 
                                                        placeholder="Stato" floatLabelType='auto' popupHeight="250px" cssClass='e-outline'/>       
                                                </div>  
                                                <div className='col-lg-3 col-md-3 col-sm-6 col-xs-6 mt-3'>  
                                                    <DateTimePickerComponent id='DataInizioLavori' showClearButton={false} step={15} readonly={JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileAuthenticationPayload')).data[0].TIPO_UTENTE === 'ADD' || JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).Stato !== '00007'} format='dd/MM/yyyy HH:mm' allowEdit={false} 
                                                            value={JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).DataInizioLavori} floatLabelType='Auto' placeholder='Data/Ora Inizio Lavori' cssClass='e-outline'></DateTimePickerComponent>
                                                </div> 
                                                <div id="divDurataPrevista" className='col-lg-2 col-md-2 col-sm-6 col-xs-6 mt-3'>  
                                                    <NumericTextBoxComponent id='DurataPrevistaLavori' showClearButton={JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileAuthenticationPayload')).data[0].TIPO_UTENTE !== 'ADD' && JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).Stato !== '00009'} 
                                                                            readonly={JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileAuthenticationPayload')).data[0].TIPO_UTENTE === 'ADD' || JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).Stato === '00009'} 
                                                                            format='N0' decimals='0' validateDecimalOnType={true} max='9999' min="0" showSpinButton={false} floatLabelType='Auto' placeholder='Durata Prevista Lavori (ore)'
                                                                            value={JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).DurataPrevistaLavori} cssClass='e-outline'></NumericTextBoxComponent>
                                                    <button id='btnInsertDataOra' type='button' className='btn btn-outline-info btn-sm' style={{width:'150px', height:'25px', fontSize: 'small'}} onClick={this.cambiaPrevCalend.bind(this)}>Inserisci Data/Ora</button> 
                                                </div> 
                                                <div id='divDataFineLavPrev' className='col-lg-3 col-md-3 col-sm-6 col-xs-6 mt-3' style={{ display: 'none' }}>  
                                                    <DateTimePickerComponent id='DataFineLavoriPrevista' showClearButton={false} step={15}  format='dd/MM/yyyy HH:mm' allowEdit={false} 
                                                                            readonly={JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileAuthenticationPayload')).data[0].TIPO_UTENTE === 'ADD' || JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).Stato === '00009'} 
                                                                             value={JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).DataFineLavoriPrevista} floatLabelType='Auto' placeholder='Data/Ora Fine Lavori Prevista' cssClass='e-outline'></DateTimePickerComponent>
                                                    <button id='btnInsertOre' type='button' className='btn btn-outline-info btn-sm' style={{width:'110px', height:'25px', fontSize: 'small'}} onClick={this.cambiaPrevOre.bind(this)}>Inserisci Ore</button> 
                                                </div> 
                                                <div id='divDataFineLavori' className='col-lg-3 col-md-3 col-sm-6 col-xs-6 mt-3' style={{ display: 'none' }}>  
                                                    <DateTimePickerComponent id='DataFineLavori' showClearButton={false} step={15} readonly={false} format='dd/MM/yyyy HH:mm' allowEdit={false} 
                                                            value={JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).DataFineLavori} floatLabelType='Auto' placeholder='Data/Ora Fine Lavori' cssClass='e-outline'></DateTimePickerComponent>
                                                </div> 
                                            </div>
                                            <div className="row">
                                                <div className='col-lg-4 col-md-4 col-sm-6 col-xs-6 ml-3 mt-3'> 
                                                    <div id='divCampoCliente'>             
                                                        <DropDownListComponent id="Cliente" ref={ddlCli => this.ddlCliInstance = ddlCli} dataSource={this.dsClienti} fields={this.ddlFields}
                                                            placeholder="Cliente" floatLabelType='Auto' cssClass='e-outline' popupHeight="250px"
                                                            readonly={JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).GuidIncarico !== 'NEW'}
                                                            value={JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).Cliente}
                                                            allowFiltering={true} filterType='Contains' change={this.onChangeCliente.bind(this)}/> 
                                                    </div>
                                                    <div id='divCampoNuovoCliente'>
                                                        <TextBoxComponent id='NuovoCliente' showClearButton={true} readonly={JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).GuidIncarico !== 'NEW'} 
                                                                value={JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).Indirizzo} floatLabelType='Auto' placeholder='Nuovo Cliente' cssClass='e-outline'></TextBoxComponent>
                                                    </div>
                                                    <button id='btnNewCli' type='button' className='btn btn-outline-info btn-lg fas fa-plus' onClick={this.visualizzaCampoCliente}></button> 
                                                    <button id='btnAddCli' type='button' className='btn btn-outline-info btn-lg fas fa-save' onClick={this.creaNuovoCliente.bind(this)}></button> 
                                                    <button id='btnCancAdd' type='button' className='btn btn-outline-info btn-lg fas fa-times' onClick={this.annullaNuovoCliente}></button> 
                                                </div>  
                                                <div className='col-lg-3 col-md-3 col-sm-6 col-xs-6 ml-3 mt-3'>              
                                                    <TextBoxComponent id='NomeCliDann' showClearButton={JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).GuidIncarico === 'NEW'} 
                                                                    readonly={false} value={JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).NomeCliDann} floatLabelType='Auto' placeholder='Nome Cliente/Danneggiato' cssClass='e-outline'></TextBoxComponent>
                                                </div>
                                                <div className='col-lg-3 col-md-3 col-sm-6 col-xs-6 ml-3 mt-3'>   
                                                    <DropDownListComponent id="Cantiere" ref={ddlCan => this.ddlCanInstance = ddlCan} dataSource={this.dsCantieri} fields={this.ddlFields}
                                                        placeholder="Cantiere" floatLabelType='Auto' cssClass='e-outline' popupHeight="250px" showClearButton={true} 
                                                        value={JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).Cantiere} change={this.onChangeCantiere.bind(this)}
                                                        readonly={JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileAuthenticationPayload')).data[0].TIPO_UTENTE === 'ADD'}
                                                        allowFiltering={false} filterType='Contains'/>            
                                                </div>
                                                <div id='divCampoCSAss' className='col-lg-2 col-md-2 col-sm-6 col-xs-6 ml-3 mt-3'>   
                                                    <DropDownListComponent id="CapoSquadraAssegnato" ref={ddlCan => this.ddlCSAInstance = ddlCan} dataSource={this.dsCSAss} fields={this.ddlFields}
                                                        placeholder="Capo Squadra Assegnato" floatLabelType='Auto' cssClass='e-outline' popupHeight="250px" showClearButton={false} readonly={false}
                                                        value={JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).Squadra} 
                                                        allowFiltering={false} filterType='Contains'/>            
                                                </div>
                                            </div>
                                            <div className="row">                                         
                                                <div className='col-lg-3 col-md-3 col-sm-6 col-xs-6 ml-3 mt-3'>              
                                                    <TextBoxComponent id='Indirizzo' showClearButton={JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).GuidIncarico === 'NEW'} readonly={JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).GuidIncarico !== 'NEW'} 
                                                            value={JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).Indirizzo} floatLabelType='Auto' placeholder='Indirizzo' cssClass='e-outline'></TextBoxComponent>
                                                </div>
                                                <div className='col-lg-2 col-md-2 col-sm-6 col-xs-6 ml-3 mt-3'>              
                                                    <TextBoxComponent id='Citta' showClearButton={JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).GuidIncarico === 'NEW'} readonly={JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).GuidIncarico !== 'NEW'}
                                                            value={JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).Citta} floatLabelType='Auto' placeholder='Citta' cssClass='e-outline'></TextBoxComponent>
                                                </div>
                                                <div className='col-lg-2 col-md-2 col-sm-3 col-xs-3 ml-3 mt-3'>     
                                                    <TextBoxComponent id='CAP' showClearButton={JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).GuidIncarico === 'NEW'} readonly={JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).GuidIncarico !== 'NEW'}
                                                            value={JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).CAP} floatLabelType='Auto' placeholder='CAP' cssClass='e-outline'></TextBoxComponent>
                                                </div>
                                                <div className='col-lg-1 col-md-1 col-sm-3 col-xs-3 ml-3 mt-3'>
                                                    <ButtonComponent id='BtnVisualGeoloc' floatLabelType='Auto' type='button' title='Visualizza Geolocalizazione' cssClass='e-details' iconCss='fas fa-globe fa-10x' onClick={this.btnVisualGeoloc.bind(this)}></ButtonComponent>
                                                </div>
                                                <div id='divAddetti' className='col-lg-4 col-md-4 col-sm-4 col-xs-4'>
                                                    <MultiSelectComponent id="Addetti" dataSource={this.dsAddetti} cssClass='e-outline' mode="Box" 
                                                        value={JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).Addetti?.split(',')} fields={this.ddlFields} ref={ddlComp => this.multiSelectInstance = ddlComp} 
                                                        placeholder="Addetti" floatLabelType='Auto' tagging={this.onTagging.bind(this)} />
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-lg-4 col-md-3 col-sm-6 col-xs-6 ml-3 mt-3'>
                                                    <DropDownListComponent id='Provincia' fields={this.ddlFields} ref={ ddlObj => this.ddlProvInstance = ddlObj } 
                                                            dataSource={this.dsProv} allowFiltering={true} filterType='Contains'
                                                            readonly={JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).GuidIncarico !== 'NEW'}
                                                            value={JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).Provincia}
                                                            placeholder="Provincia" floatLabelType='Auto' popupHeight="250px" cssClass='e-outline'/>       
                                                </div> 
                                                <div className='col-lg-3 col-md-3 col-sm-6 col-xs-6 ml-3 mt-3'>
                                                    <DropDownListComponent id='Nazione' fields={this.ddlFields} ref={ ddlObj => this.ddlNazInstance = ddlObj } 
                                                            dataSource={this.dsNaz} allowFiltering={true} filterType='Contains'
                                                            readonly={JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).GuidIncarico !== 'NEW'}
                                                            value={JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).Nazione}
                                                            placeholder="Nazione" floatLabelType='Auto' popupHeight="250px" cssClass='e-outline'/>       
                                                </div> 
                                                <div className='col-lg-2 col-md-3 col-sm-6 col-xs-6 ml-3 mt-3'>              
                                                    <TextBoxComponent id='Contatto' showClearButton={true}  
                                                            value={JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).Contatto} floatLabelType='Auto' placeholder='Contatto' cssClass='e-outline'></TextBoxComponent>
                                                </div>
                                                <div className='col-lg-2 col-md-3 col-sm-6 col-xs-6 ml-3 mt-3'>              
                                                    <TextBoxComponent id='Telefono' showSpinButton={false} showClearButton={true} type='number'  
                                                            value={JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).Telefono} floatLabelType='Auto' placeholder='Telefono' cssClass='e-outline'></TextBoxComponent>
                                                </div>
                                                <div className='col-lg-3 col-md-4 col-sm-6 col-xs-6 ml-3 mt-3'>              
                                                    <TextBoxComponent id='Email' showSpinButton={false} type='email'   
                                                            value={JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).Email} floatLabelType='Auto' placeholder='Email' cssClass='e-outline'></TextBoxComponent>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className="col-xs-10 col-sm-10 col-lg-10 col-md-10 ml-3" style={{ marginTop: '5px'}}>
                                                    <span style={{ fontSize: '1.8vw', fontWeight: 'bolder', color:'#133B95'}}>Note Incarico</span>
                                                </div>
                                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 ml-3">
                                                    <RichTextEditorComponent id="NotaIncarico" ref={richtexteditor => { this.RTEObj = richtexteditor; }} showCharCount={true} enableHtmlEncode={true}
                                                            maxLength={5000} toolbarSettings={this.RTEtoolbarSettings} width={Browser.isDevice ? '94%' : '98%'} height='300px' actionBegin={this.handleFullScreen.bind(this)}
                                                            iframeSettings={this.RTEiframeSettings}>
                                                    <RTEinject services={[RTEtoolbar, Image, Link, HtmlEditor, Count, QuickToolbar, Table]}/>
                                                    </RichTextEditorComponent>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                    
                    </div>
                </form>
            </React.Fragment>);
    }
    tabContent1(args) {
        return(<React.Fragment>
                <GridMaterialiManodopera></GridMaterialiManodopera>
            </React.Fragment>);
    }
    tabContent2(args) {
        return(<React.Fragment>
                <GridCronologia></GridCronologia>
            </React.Fragment>);
    }
    tabContent3(args) {
        return(<React.Fragment>
                <GridDocumentazioneSopralluogo></GridDocumentazioneSopralluogo>
            </React.Fragment>);
    }
    tabContent4(args) {
        return(<React.Fragment>
                <GridDocumentazioneIncarico></GridDocumentazioneIncarico>
            </React.Fragment>);
    }
    tabContent5(args) {
        return(<React.Fragment>
                <PaginaFirma></PaginaFirma>
            </React.Fragment>);
    }
    tabSelecting(args) {
        if (args.isSwiped) {
            args.cancel = true;
        }
    }
    tabSelected(args) {
        if (args.selectedIndex === 1 && CustomJS.isNullEmpty(JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).FirmaFineLavoriCliente)
            && document.getElementById('Stato').ej2_instances[0].value === '00009')
            setTimeout(() => {
                document.getElementById('divBtnAccettazione').style.removeProperty('display'); 
            }, 150);
        else if (args.selectedIndex === 1)
            setTimeout(() => {
                document.getElementById('divBtnAccettazione').style.display = 'none'; 
            }, 150);        
    }
    btnVisualGeoloc(args){
        let indirizzo = `${document.getElementById('Indirizzo').ej2_instances[0].value}+${document.getElementById('Citta').ej2_instances[0].value}+${document.getElementById('CAP').ej2_instances[0].value}+${document.getElementById('Provincia').ej2_instances[0].value}+${document.getElementById('Nazione').ej2_instances[0].value}`;
        this.dialogInstance2.buttons = [];
        this.dialogInstance2.height = '90%';
        this.dialogInstance2.minHeight='80%';
        this.dialogInstance2.width = '80%'; 
        this.dialogInstance2.beforeOpen = (args) => {
            document.getElementById('mappaGoogle').src= '';  
        };
        this.dialogInstance2.open = (args) => {
            setTimeout(() => {
                document.getElementById('mappaGoogle').src=`https://www.google.com/maps/embed/v1/place?key=AIzaSyDIusSsqd2WctS-RfkcEJjFLuP7q3d0pcI&q=${indirizzo}`;  
            }, 400);
        };
        this.dialogInstance2.show();   
    }
    ProgrButtCreated1() {      
        document.getElementById('btnSalvaEsci').ej2_instances[0].element.addEventListener('click', () => {
            if (this.formObject.validate()) {
                const CodDiv = JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileAuthenticationPayload')).data[0].COD_DIV,
                      CodSoc = JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileAuthenticationPayload')).data[0].COD_SOC,
                      CodFil = JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileAuthenticationPayload')).data[0].COD_FIL,
                      token = JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileAuthenticationPayload')).token;

                let NumeroIncarico = '';
                if (JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).GuidIncarico === 'NEW') {   
                    CustomJS.getProgressivo(CustomJS.APIJfishTeamsURLProd, token, CodDiv, CodSoc, CodFil, "INCARICO", "NULL", true, retData => {
                        NumeroIncarico = `${retData[0].ANNO}-${String(retData[0].NUM_PROGRESSIVO).padStart(retData[0].LUNGH_PROGRESSIVO, '0')}`; 
                        this.eseguiSalva(NumeroIncarico, true);
                    }, messaggio => {
                        toast.warn(messaggio, {
                            onClose: () => toast.dismiss(),
                            position: "bottom-right",
                            autoClose: 5000,
                            hideProgressBar: false,            
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true}
                        ); 
                    });                    
                }
                else if (JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).GuidIncarico !== 'NEW' 
                         && JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).Stato !== this.ddlStatiInstance.value) {
                    this.dialogInstance1.buttons = this.dialogButtons2;
                    this.dialogInstance1.content = `Lo stato verrà portato a <b>${this.ddlStatiInstance.text.toUpperCase()}</b>. Questa operazione potrà essere riportata indietro solo dalla sede. Confermi il cambio di stato?`;
                    this.dialogInstance1.width = '48%';
                    this.dialogInstance1.show();
                }
                else {
                    NumeroIncarico = JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).NumeroIncarico;
                    this.eseguiSalva(NumeroIncarico, true);
                }   
            } 
            else
                toast.error(CustomJS.messaggioErroreObbligo, {
                    onClose: () => toast.dismiss(),                    
                    position: "top-left",
                    autoClose: 7000,
                    hideProgressBar: false,            
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true});           
        });
    }
    salvaContinua() {      
        if (this.formObject.validate()) {
            let NumeroIncarico = '';
            if (JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).Stato !== this.ddlStatiInstance.value) {
                this.dialogInstance1.buttons = [{ click: () => { this.dialogInstance1.hide(); }, buttonModel: { content: 'OK', cssClass: 'e-primary', isPrimary: true } }];
                this.dialogInstance1.content = `Per ragioni strettamente operative, non è possibile cambiare lo stato e poi eseguire un'operazione di <b>Salva e Continua</b>. Se si vuole continuare allora riportare lo stato a quello originale e toccare/cliccare <b>Salva e Continua</b> per salvare tutto il resto. Se si vuole effettivamente cambiare lo stato allora mantenere lo stato nuovo e toccare/cliccare <b>Salva ed Esci</b>`;
                this.dialogInstance1.width = '48%';
                this.dialogInstance1.show();
            }
            else {
                NumeroIncarico = JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).NumeroIncarico;
                this.eseguiSalva(NumeroIncarico, false);
            }   
        } 
        else
            toast.error(CustomJS.messaggioErroreObbligo, {
                onClose: () => toast.dismiss(),                    
                position: "top-left",
                autoClose: 7000,
                hideProgressBar: false,            
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true});           
    }
    eseguiSalva(NumeroIncarico, Esci) { 
        const CodDiv = JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileAuthenticationPayload')).data[0].COD_DIV,
              CodSoc = JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileAuthenticationPayload')).data[0].COD_SOC,
              CodFil = JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileAuthenticationPayload')).data[0].COD_FIL,
              token = JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileAuthenticationPayload')).token,
              GuidIncarico = JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).GuidIncarico === 'NEW' ? CustomJS.createGuid4() : JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).GuidIncarico,
              GuidDocumento = CustomJS.isNullEmpty(JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).GuidDocumento) ? 
                              JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).GuidIncarico : JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).GuidDocumento;   
        const dataObj = {
            DataInizioLavori: document.getElementById('DataInizioLavori').ej2_instances[0].value,    
            DataFineLavori: document.getElementById('DataFineLavori').ej2_instances[0].value,  
            DurataPrevistaLavori: document.getElementById('DurataPrevistaLavori').ej2_instances[0].value,  
            DataFineLavoriPrevista: document.getElementById('DataFineLavoriPrevista').ej2_instances[0].value,  
            Indirizzo: document.getElementById('Indirizzo').ej2_instances[0].value,
            Citta: document.getElementById('Citta').ej2_instances[0].value,
            CAP: document.getElementById('CAP').ej2_instances[0].value,
            Provincia: document.getElementById('Provincia').ej2_instances[0].value,
            Nazione: document.getElementById('Nazione').ej2_instances[0].value,
            NomeCliDann: document.getElementById('NomeCliDann').ej2_instances[0].value,
            Cantiere: document.getElementById('Cantiere').ej2_instances[0].value,
            Telefono: document.getElementById('Telefono').ej2_instances[0].value,
            Contatto: document.getElementById('Contatto').ej2_instances[0].value,
            Email: document.getElementById('Email').ej2_instances[0].value,
            Stato: document.getElementById('Stato').ej2_instances[0].value,
            Nota: this.RTEObj.value,
            Numero: NumeroIncarico,
            Cliente: this.ddlCliInstance.value,
            Utente: JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileAuthenticationPayload')).data[0].COD_ELE,
            Squadra: !CustomJS.isNullEmpty(document.getElementById('CapoSquadraAssegnato').ej2_instances[0].value) ? document.getElementById('CapoSquadraAssegnato').ej2_instances[0].value : JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileAuthenticationPayload')).data[0].COD_ELE,
            Addetti: !CustomJS.isNullEmpty(document.getElementById('Addetti').ej2_instances[0].value) ? document.getElementById('Addetti').ej2_instances[0].value.join(',') : '',
            CodiceCliente: this.ddlCliInstance.itemData.SERVIZIO_STR1,
            RagioneSociale: this.ddlCliInstance.text,
            FirmaFineLavoriCliente: CustomJS.isNullEmpty(JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).FirmaFineLavoriCliente) ? ''
                                    : JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).FirmaFineLavoriCliente,
            ListaMaterManod: CustomJS.isNullEmpty(document.getElementById('GridMaterialiManodopera')) ? [] : document.getElementById('GridMaterialiManodopera').ej2_instances[0].dataSource.filter(elm => elm.RigaNuova === 'S'),
            ListaDocumentazioneSoprall: CustomJS.isNullEmpty(document.getElementById('GridDocumentazioneSopralluogo')) ? [] : document.getElementById('GridDocumentazioneSopralluogo').ej2_instances[0].dataSource,
            ListaDocumentazioneIncarico: CustomJS.isNullEmpty(document.getElementById('GridDocumentazioneIncarico')) ? [] : document.getElementById('GridDocumentazioneIncarico').ej2_instances[0].dataSource,
            ListaCronologia: CustomJS.isNullEmpty(document.getElementById('GridCronologia')) ? [] : document.getElementById('GridCronologia').ej2_instances[0].dataSource
        };
        CustomJS.callAPI(`${CustomJS.APIJfishTeamsURLProd}/api/JellyfishTeams/salvaIncarico/${CodDiv}/${CodSoc}/${CodFil}/${GuidIncarico}/${GuidDocumento}`, JSON.stringify(dataObj), 'POST',
            'application/json', token, retData => { 
                if (retData.response === 'KO') {
                    let messaggio = (retData.msg === 'UtenteNonAutorizzato') ? CustomJS.messaggioUtenteNonAutorizzato : (retData.msg === 'SessioneScaduta') ? CustomJS.messaggioSessioneScaduta : retData.msg;
                    toast.warn(messaggio, {
                        onClose: () => toast.dismiss(),                    
                        position: "bottom-right",
                        autoClose: 15000,
                        hideProgressBar: false,            
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true
                    });
                    return;
                }    
                if (retData.response === 'OK') {   
                    if (JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileAuthenticationPayload')).data[0].TIPO_UTENTE === 'SQD') {
                        if (JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).GuidIncarico === 'NEW') { 
                            this.listaEventiCreaGoogle = [];
                            _.filter(JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileTabellaGGC')), elm => { return elm.SERVIZIO_STR2 === 'S' && ((elm.SERVIZIO_STR6 === "AND" && (CustomJS.isNullEmpty(elm.SERVIZIO_STR4) || elm.SERVIZIO_STR4?.indexOf('ALL') > -1 || elm.SERVIZIO_STR4?.indexOf(dataObj.Squadra) > -1) && (CustomJS.isNullEmpty(elm.SERVIZIO_STR5) || elm.SERVIZIO_STR5?.indexOf('ALL') > -1 || _.intersection(JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).Addetti?.split(','), elm.SERVIZIO_STR5?.split(',')).length > 0)) 
                                                                                                                                                          || (elm.SERVIZIO_STR6 === "OR" && (CustomJS.isNullEmpty(elm.SERVIZIO_STR4) || elm.SERVIZIO_STR4?.indexOf('ALL') > -1 || elm.SERVIZIO_STR4?.indexOf(dataObj.Squadra) > -1 || CustomJS.isNullEmpty(elm.SERVIZIO_STR5) || elm.SERVIZIO_STR5?.indexOf('ALL') > -1 || _.intersection(JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).Addetti?.split(','), elm.SERVIZIO_STR5?.split(',')).length > 0))) }).forEach(elm2 => { 
                                const dataObj2 = {
                                    NuovoIncarico: true,
                                    tokenAuth: CustomJS.TokenAuthGoogleCalendar,
                                    eventObj: {
                                        codDiv: CodDiv,
                                        codSoc: CodSoc,
                                        codFil: CodFil,
                                        applicazione: 'JFTEAMS',
                                        guidIncarico: GuidIncarico,
                                        idCalendario: elm2.SERVIZIO_STR1,
                                        descrizioneEvento: `Incarico: ${NumeroIncarico} - Capo Squadra: ${!CustomJS.isNullEmpty(this.ddlCSAInstance.value) ? this.ddlCSAInstance.text : JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileAuthenticationPayload')).data[0].DESCRIZ} - ${this.ddlCliInstance.text} (${CustomJS.isNullEmpty(document.getElementById('NomeCliDann').ej2_instances[0].value) ? '' : document.getElementById('NomeCliDann').ej2_instances[0].value})`,
                                        indirizzoEvento: `${dataObj.Indirizzo} ${dataObj.Citta} ${dataObj.CAP} (${document.getElementById('Provincia').ej2_instances[0].text}) ${document.getElementById('Nazione').ej2_instances[0].text}`,
                                        notaEvento: dataObj.Nota?.replaceAll('&lt;','<').replaceAll('&gt;', '>'),
                                        dataInizio: dataObj.DataInizioLavori, 
                                        dataFine: !CustomJS.isNullEmpty(dataObj.DataFineLavori) ? dataObj.DataFineLavori : !CustomJS.isNullEmpty(dataObj.DataFineLavoriPrevista) ? dataObj.DataFineLavoriPrevista : new Date(dataObj.DataInizioLavori).addHours(dataObj.DurataPrevistaLavori)                    
                                    }
                                };  
                                this.listaEventiCreaGoogle.push(dataObj2);
                            });
                        }
                        else {
                            this.listaEventiCreaGoogle = [];
                            _.filter(JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileTabellaGGC')), elm => { return elm.SERVIZIO_STR2 === 'S' && ((elm.SERVIZIO_STR6 === "AND" && (CustomJS.isNullEmpty(elm.SERVIZIO_STR4) || elm.SERVIZIO_STR4?.indexOf('ALL') > -1 || elm.SERVIZIO_STR4?.indexOf(dataObj.Squadra) > -1) && (CustomJS.isNullEmpty(elm.SERVIZIO_STR5) || elm.SERVIZIO_STR5?.indexOf('ALL') > -1 || _.intersection(JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).Addetti?.split(','), elm.SERVIZIO_STR5?.split(',')).length > 0)) 
                                                                                                                                                          || (elm.SERVIZIO_STR6 === "OR" && (CustomJS.isNullEmpty(elm.SERVIZIO_STR4) || elm.SERVIZIO_STR4?.indexOf('ALL') > -1 || elm.SERVIZIO_STR4?.indexOf(dataObj.Squadra) > -1 || CustomJS.isNullEmpty(elm.SERVIZIO_STR5) || elm.SERVIZIO_STR5?.indexOf('ALL') > -1 || _.intersection(JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).Addetti?.split(','), elm.SERVIZIO_STR5?.split(',')).length > 0))) }).forEach(elm2 => { 
                                const dataObj2 = {
                                    NuovoIncarico: false,
                                    tokenAuth: CustomJS.TokenAuthGoogleCalendar,
                                    eventObj: {
                                        codDiv: CodDiv,
                                        codSoc: CodSoc,
                                        codFil: CodFil,
                                        applicazione: 'JFTEAMS',
                                        guidIncarico: GuidIncarico,
                                        idCalendario: elm2.SERVIZIO_STR1,
                                        descrizioneEvento: `Incarico: ${NumeroIncarico} - Capo Squadra: ${this.ddlCSAInstance.text} - ${this.ddlCliInstance.text} (${CustomJS.isNullEmpty(document.getElementById('NomeCliDann').ej2_instances[0].value) ? '' : document.getElementById('NomeCliDann').ej2_instances[0].value})`,
                                        indirizzoEvento: `${dataObj.Indirizzo} ${dataObj.Citta} ${dataObj.CAP} (${document.getElementById('Provincia').ej2_instances[0].text}) ${document.getElementById('Nazione').ej2_instances[0].text}`,
                                        notaEvento: dataObj.Nota?.replaceAll('&lt;','<').replaceAll('&gt;', '>'),
                                        dataInizio: dataObj.DataInizioLavori, 
                                        dataFine: !CustomJS.isNullEmpty(dataObj.DataFineLavori) ? dataObj.DataFineLavori : !CustomJS.isNullEmpty(dataObj.DataFineLavoriPrevista) ? dataObj.DataFineLavoriPrevista : new Date(dataObj.DataInizioLavori).addHours(dataObj.DurataPrevistaLavori)                    
                                    }
                                };  
                                this.listaEventiCreaGoogle.push(dataObj2);
                            });
                        }
                        if (this.listaEventiCreaGoogle.length > 0) {
                            const dataObj = {
                                tokenAuth: CustomJS.TokenAuthGoogleCalendar,
                                listaEventi: this.listaEventiCreaGoogle
                            };
                            CustomJS.callAPI(`${CustomJS.APIJfishTeamsURLProd}/api/JellyfishTeams/creaAggiornaEventoCalendarioGoogleCiclico`, JSON.stringify(dataObj), 'POST',
                            'application/json', '', retData => { 
                                if (retData.response === 'KO') {
                                    let messaggio = (retData.msg === 'UtenteNonAutorizzato') ? CustomJS.messaggioUtenteNonAutorizzato : (retData.msg === 'SessioneScaduta') ? CustomJS.messaggioSessioneScaduta : retData.msg;
                                    toast.warn(messaggio, {
                                        onClose: () => toast.dismiss(),                    
                                        position: "bottom-right",
                                        autoClose: 15000,
                                        hideProgressBar: false,            
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: false,
                                        progress: undefined,
                                        newestOnTop: true,
                                        rtl: false,
                                        pauseOnFocusLoss: true
                                    });
                                    return;
                                }    
                                if (retData.response === 'OK') {                             
                                }  
                            }, error => {
                                let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                                toast.warn(messaggio, {
                                    onClose: () => toast.dismiss(),
                                    position: "bottom-right",
                                    autoClose: 15000,
                                    hideProgressBar: false,            
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: false,
                                    progress: undefined,
                                    newestOnTop: true,
                                    rtl: false,
                                    pauseOnFocusLoss: true})
                            }, true, false);  
                        } 
                        this.invioNotifica(dataObj, GuidIncarico, GuidDocumento);  
                        if (JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).GuidIncarico === 'NEW') {
                            CustomJS.getProgressivo(CustomJS.APIJfishTeamsURLProd, token, CodDiv, CodSoc, CodFil, "INCCANONE", "NULL", true, retData => {
                            }, messaggio => {
                                toast.warn(messaggio, {
                                    onClose: () => toast.dismiss(),
                                    position: "bottom-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,            
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: false,
                                    progress: undefined,
                                    newestOnTop: true,
                                    rtl: false,
                                    pauseOnFocusLoss: true}
                                ); 
                            }); 
                        }  
                    }
                    if (Esci) {
                        setTimeout(() => {
                            sessionStorage.removeItem('JellyfishTeamsMobileDatiIncarico');
                            ++this.childKey;
                            const container = document.getElementById('contenutoDinamico2');
                            const root = createRoot(container); 
                            root.render(<SituazioneIncarichi key={this.childKey}></SituazioneIncarichi>); 
                        }, 300); 
                    }
                    else {
                        toast.success('Salvataggio effettuato con successo!!', {
                            //onClose: () => toast.dismiss(),
                            position: "top-left",
                            autoClose: 3000,
                            hideProgressBar: false,            
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                            newestOnTop: true,
                            rtl: false,
                            pauseOnFocusLoss: true
                        }); 
                        if (document.getElementById('GridCronologia'))
                            document.getElementById('GridCronologia').ej2_instances[0].dataSource = document.getElementById('GridCronologia')?.ej2_instances[0].dataSource.filter(elm => { return elm.Stato !== 'D'});
                        document.getElementById('GridCronologia')?.ej2_instances[0].dataSource.forEach(elm => { 
                            elm.Stato = 'O';
                            elm.NuovoCanc = '';
                        });
                        if (document.getElementById('GridDocumentazioneSopralluogo'))
                            document.getElementById('GridDocumentazioneSopralluogo').ej2_instances[0].dataSource = document.getElementById('GridDocumentazioneSopralluogo')?.ej2_instances[0].dataSource.filter(elm => { return elm.Stato !== 'D'});
                        document.getElementById('GridDocumentazioneSopralluogo')?.ej2_instances[0].dataSource.forEach(elm => { 
                            elm.Stato = 'O';
                            elm.NuovoCanc = '';
                        });
                        if (document.getElementById('GridDocumentazioneIncarico'))
                            document.getElementById('GridDocumentazioneIncarico').ej2_instances[0].dataSource = document.getElementById('GridDocumentazioneIncarico')?.ej2_instances[0].dataSource.filter(elm => { return elm.Stato !== 'D'});
                        document.getElementById('GridDocumentazioneIncarico')?.ej2_instances[0].dataSource.forEach(elm => { 
                            elm.Stato = 'O';
                            elm.NuovoCanc = '';
                        });
                        document.getElementById('GridMaterialiManodopera')?.ej2_instances[0].dataSource.filter(elm => elm.RigaNuova === 'S').forEach(elm => { 
                            elm.RigaNuova = '';
                        });
                        document.getElementById('GridCronologia')?.ej2_instances[0].refresh();
                        document.getElementById('GridDocumentazioneSopralluogo')?.ej2_instances[0].refresh();
                        document.getElementById('GridDocumentazioneIncarico')?.ej2_instances[0].refresh();
                        document.getElementById('GridMaterialiManodopera')?.ej2_instances[0].refresh();
                    }
                }  
        }, error => {
            let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
            toast.warn(messaggio, {
                onClose: () => toast.dismiss(),
                position: "bottom-right",
                autoClose: 15000,
                hideProgressBar: false,            
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true});
        }, true, false);
    }
    invioNotifica(dataObj, GuidIncarico, GuidDocumento) {        
        const datiNotif = {
            CodDiv: JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileAuthenticationPayload')).data[0].COD_DIV,
            CodSoc: JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileAuthenticationPayload')).data[0].COD_SOC,
            CodFil: JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileAuthenticationPayload')).data[0].COD_FIL,
            LinguaAppl: 'ITA',
            FormatoData: 'dd/MM/yyyy',
            GuidIncarico: GuidIncarico,
            GuidDocumento: GuidDocumento,
            Numero: dataObj.Numero,
            Squadra: dataObj.Squadra,
            Addetti: JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).Addetti,
            Cliente: dataObj.Cliente,
            NomeCliDann: dataObj.NomeCliDann,
            Indirizzo: dataObj.Indirizzo,
            Citta: dataObj.Citta,
            CAP: dataObj.CAP,
            Provincia: dataObj.Provincia,
            Telefono: dataObj.Telefono,
            DataInizioLavori: dataObj.DataInizioLavori
        }
        CustomJS.getRemoteData4(`${CustomJS.URLApplicazioneProd}/api/NotificaFromOutside/InvioNotifica`, { dati_notifica_json: JSON.stringify(datiNotif) }, 'POST', 
            {'Authorization':  CustomJS.tokenAuthAPIAppTeamsBackoffice }, retData => {
        }, error => {
            let messaggio = _.isString(error) ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
            toast.warn(messaggio, {
                containerId: 'toastContainer6',
                onClose: () => toast.dismiss(),                    
                position: "bottom-right",
                autoClose: 15000,
                hideProgressBar: false,            
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true});
            this.toggleBlocking();
        }, true, false);
    }
    ProgrButtCreated2() {
        document.getElementById('btnEsci').ej2_instances[0].element.addEventListener('click', () => {
            this.dialogInstance1.buttons = this.dialogButtons1;
            this.dialogInstance1.content = CustomJS.messaggioConfermaUscita;
            this.dialogInstance1.width = '35%';
            this.dialogInstance1.show();
        });
    }    
    cambiaPrevCalend() {
        document.getElementById('DurataPrevistaLavori').ej2_instances[0].value = null;
        document.getElementById('divDurataPrevista').style.display = 'none';
        document.getElementById('DataFineLavoriPrevista').ej2_instances[0].value = null;
        document.getElementById('divDataFineLavPrev').style.removeProperty('display');
    }
    cambiaPrevOre() {
        document.getElementById('DurataPrevistaLavori').ej2_instances[0].value = null;
        document.getElementById('divDurataPrevista').style.removeProperty('display');
        document.getElementById('DataFineLavoriPrevista').ej2_instances[0].value = null;
        document.getElementById('divDataFineLavPrev').style.display = 'none';
    }
    render() {
        const titoloPagina = JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).GuidIncarico === 'NEW' ? 'INSERIMENTO NUOVO INCARICO' : `DETTAGLIO INCARICO NUMERO ${JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).NumeroIncarico}`;
        return (<React.Fragment>            
            <BlockUi id='blockUIIncarico' tag='div' blocking={this.state.blocking} keepInView={true} loader={CustomJS.loaderSpinners[random(CustomJS.loaderSpinners.length - 1)]}>                            
                <DialogComponent id="defaultDialogDettInc2" showCloseIcon={true} target='body' animationSettings={this.dialogAnimationSettings} visible={this.state.showDialog2} 
                        ref={dialog => this.dialogInstance2 = dialog} header='<span style="color:#133B95;font-weight:bolder;font-size:1.3em;">Visualizzazione Georeferenza</span>' isModal={true} height='90%' minHeight='90%'
                        content=''>
                            <iframe id='mappaGoogle' src="" frameBorder="1" width='100%' height='99%' allowFullScreen></iframe>
                </DialogComponent> 
                <DialogComponent id="defaultDialogDettInc" showCloseIcon={true} target='body' animationSettings={this.dialogAnimationSettings} visible={this.state.showDialog1} width='35%'
                        ref={dialog => this.dialogInstance1 = dialog} header='Attenzione!!' buttons={this.dialogButtons1} height='180px' minHeight='180px' isModal={true} 
                        content=''>
                </DialogComponent> 
                <div className='container-fluid'> 
                    <div className='row'>&nbsp;</div>
                    <div className="row">                        
                        <div className="col-xs-10 col-sm-10 col-lg-10 col-md-10" style={{ marginTop: '5px'}}>
                            <span style={{ fontSize: '3.0vw', fontWeight: 'bolder', color:'#133B95'}}>{titoloPagina}</span>
                        </div>  
                        <div className="col-xs-1 col-sm-1 col-lg-1 col-md-1 mt-3"> 
                            <ProgressButtonComponent id='btnSalvaEsci' content="Salva ed esci" enableProgress duration='1000' spinSettings={{ position: 'Center' }} 
                                animationSettings={{effect: 'SlideRight'}} created={this.ProgrButtCreated1.bind(this)} cssClass="e-fill e-success"></ProgressButtonComponent> 
                            <div id='divSalvaContinua'>
                                <ProgressButtonComponent key={++this.childKey} id='btnSalvaContinua' content="Salva e continua" enableProgress duration='1000' style={{ marginTop: '5px'}} spinSettings={{ position: 'Center' }} 
                                    animationSettings={{effect: 'SlideRight'}} onClick={this.salvaContinua.bind(this)} cssClass="e-fill e-info"></ProgressButtonComponent>
                            </div>
                            <ProgressButtonComponent id='btnEsci' content="Esci" enableProgress duration='1000' style={{ marginTop: '5px'}} spinSettings={{ position: 'Center' }} 
                                animationSettings={{effect: 'SlideRight'}} created={this.ProgrButtCreated2.bind(this)} cssClass="e-fill e-danger"></ProgressButtonComponent>
                        </div>
                    </div>
                    <div className='row'> 
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12"> 
                            <ToastContainer containerId='toastContainer2' transition={Zoom} style={{ fontSize: '1.3vw', color: 'midnightblue', fontWeight: 'bold', width: '30vw' }}></ToastContainer>
                            <TabComponent id='TabIncarico' cssClass='e-fill' headerPlacement='Top' width='100%' ref={tabComp => this.tabInstance = tabComp} selecting={this.tabSelecting.bind(this)} 
                                          selected={this.tabSelected.bind(this)}
                                          animation={{ previous: { effect: 'FadeIn', duration: 500, easing: 'ease' }, next: { effect: 'FadeIn', duration: 500, easing: 'ease' } }}>
                                <TabItemsDirective>
                                    <TabItemDirective header={this.tabHeaderText[0]} content={this.tabContent0.bind(this)} width='100%'/>
                                    <TabItemDirective header={this.tabHeaderText[1]} content={this.tabContent1.bind(this)} width='100%'/>
                                    <TabItemDirective header={this.tabHeaderText[2]} content={this.tabContent2.bind(this)} width='100%'/>
                                    <TabItemDirective header={this.tabHeaderText[3]} content={this.tabContent3.bind(this)} width='100%'/>
                                    <TabItemDirective header={this.tabHeaderText[4]} content={this.tabContent4.bind(this)} width='100%'/>
                                    <TabItemDirective header={this.tabHeaderText[5]} content={this.tabContent5.bind(this)} width='100%'/>
                                </TabItemsDirective>
                            </TabComponent>       
                        </div>                
                    </div>
                </div>
            </BlockUi>
        </React.Fragment>);
    }    
    componentDidMount() {        
        setTimeout(() => {             
            this.formObject = new FormValidator('#formIncarico', this.formOptions);
            if (JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).GuidIncarico === 'NEW')
               document.getElementById('btnNewCli').style.removeProperty('display');
            else
               document.getElementById('btnNewCli').style.display = 'none';
            document.getElementById('btnAddCli').style.display = 'none';
            document.getElementById('btnCancAdd').style.display = 'none';
            document.getElementById('divCampoNuovoCliente').style.display = 'none';
            $("hamburger").unbind("click");
            this.RTEObj.value = JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).NotaIncarico?.replace(/&amp;lt;/g, '<')?.replace(/&amp;gt;/g, '>')?.replace(/&quot;/g, '"');
            if (this.ddlStatiInstance.value === "00007" || this.ddlStatiInstance.value === "00009") {
                this.tabInstance.hideTab(2, true);
                this.tabInstance.hideTab(5, true);
                if (this.ddlStatiInstance.value === "00009") {    
                    if (JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileAuthenticationPayload')).data[0].TIPO_UTENTE === 'SQD')                
                        this.tabInstance.hideTab(5, false);  
                    this.tabInstance.hideTab(2, false);   
                    document.getElementById('divDataFineLavori').style.removeProperty('display');
                }
            }
            else {
                this.tabInstance.hideTab(1, false);
                this.tabInstance.hideTab(2, false); 
                this.tabInstance.hideTab(5, true);
            }
            //TODO Verificare se c'era un motivo per cui la Durata Prevista era stata messa obbligatoria
            // if ((this.ddlStatiInstance.value === "00007" || this.ddlStatiInstance.value === "00008") 
            //      && JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileAuthenticationPayload')).data[0].TIPO_UTENTE === 'SQD') {
            //     this.formObject.rules.DurataPrevistaLavori = {
            //         required: [true, 'Durata Prevista Lavori è un campo obbligatorio']
            //     };  
            // }
            // else
            //     delete this.formObject.rules.DurataPrevistaLavori;
            if (JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).GuidIncarico !== 'NEW')
                $('#divSalvaContinua').show();            
            else 
               $('#divSalvaContinua').hide();
            if (!CustomJS.isNullEmpty(JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).DataFineLavoriPrevista)) {
                document.getElementById('divDataFineLavPrev').style.removeProperty('display');
                document.getElementById('divDurataPrevista').style.display = 'none';
            }   
            if (this.ddlStatiInstance.value === "00009" || JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileAuthenticationPayload')).data[0].TIPO_UTENTE === 'ADD') {
                document.getElementById('btnInsertOre').style.display = 'none';
                document.getElementById('btnInsertDataOra').style.display = 'none';
            }
            if (JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileAuthenticationPayload')).data[0].TIPO_UTENTE === 'ADD')
                document.getElementById('divCampoCSAss').style.display = 'none';
            if (JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileAuthenticationPayload')).data[0].VEDI_MOD_ADD !== 'S')
                document.getElementById('divAddetti').style.display = 'none';
            this.onChangeCliente({ value: this.ddlCliInstance.value });
            setTimeout(() => {
                this.ddlCanInstance.value = JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).Cantiere;
            }, 200);
            $(`[id=NotaIncarico_toolbar_FontColor_Target]`).hide();
            $(`[id=NotaIncarico_toolbar_BackgroundColor_Target]`).hide();                    
        }, 500);
    }
    componentWillUnmount() {
        this.RTEObj.destroy();
    }
}