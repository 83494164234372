/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable default-case */
import * as React from 'react';
import { createRoot } from 'react-dom/client'
import { CallbackWrapper } from 'react-callback';
import { GridComponent, ColumnsDirective, ColumnDirective, Toolbar, ExcelExport, PdfExport,  CommandColumn, Filter, Inject, Sort, Page } from '@syncfusion/ej2-react-grids';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { PdfViewerComponent, Toolbar as PDFVToolbar, Magnification, Navigation, LinkAnnotation, BookmarkView, ThumbnailView, Print, TextSelection, TextSearch, Annotation, FormFields, FormDesigner, Inject as PDFVInject } from '@syncfusion/ej2-react-pdfviewer';
import DettaglioIncarico from './DettaglioIncarico';
import { SampleBase } from './sampleBase';
import { ToastContainer, toast, Slide, Zoom, Flip, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BlockUi from '@availity/block-ui';
import * as CustomJS from '../Custom';
import _ from 'underscore/underscore-min';
import { random, isUndefined } from 'underscore';
import $ from 'jquery';
import moment from 'moment';

let GridData = [];

function headerTemplate(props) {
    return (<div>
        <span style={{ fontSize: '1.4vw', fontWeight: 'bolder' }}>{props.headerText}</span>
    </div>);
}

function fieldTemplate(props) {
    let colore = props.column.field === 'NumeroIncarico' ? '#133B95' : 'purple';
    let fontSize = (props.column.field === 'AddettiDescr' || props.column.field === 'CapoSquadraDescr') ? '0.8vw' : '1.6vw';
    let renderDiv = (props.column.field === 'Notifica' && props.Emergenza === 'S') ? <div style={{ paddingTop: '7px' }}><span title='Creato da Mobile' style={{ fontSize: '2.0vw', color: 'red', fontWeight: 'bolder' }}>M</span></div> 
                    : <div><span style={{ fontSize: fontSize, color: colore, fontWeight: 'bolder' }}>{props[props.column.field]}</span></div>;
    return (
        renderDiv
    );
}
export default class SituazioneIncarichi extends SampleBase {
    constructor() {
        super(...arguments);               
        this.toggleBlocking = this.toggleBlocking.bind(this);        
        this.editSettings = { allowAdding: true, mode: 'Normal'};
        this.pageSettings = { pageSize : 1000 };
        this.ddlFields = { text: 'TEXT', value: 'VALUE' };
        this.dsStati = JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileTabellaSTP')).filter(elm => { return elm.VALUE === '00007' || elm.VALUE === '00008' || elm.VALUE === '00009' });
        this.toolbarOptions = ['Add', { template: this.ddlStatiToolbar.bind(this) }, '   ', {text: 'Aggiorna Tabella', prefixIcon: "e-aggiornatabella", id: "aggiornatabella" }, 'Search' ]; 
        this.commands = [{ type: "Edit", buttonOption: { iconCss: "fas fa-edit fa-10x", cssClass: "e-details" } },
                         { type: "Vedi Documento Fine Lavori", buttonOption: { iconCss: "fas fa-eye fa-10x", cssClass: "e-details" }}];  
        this.commands2 = [{ type: "Edit", buttonOption: { iconCss: "fas fa-edit fa-10x", cssClass: "e-details" } }];
        this.childKey = 0;
        this.dialogAnimationSettings = { effect: 'FadeZoom' };  
    }
    state = {
        blocking: false,
        GridData2: GridData,
        showDialog1: false   
    } 
    toggleBlocking() {        
        this.setState({blocking: !this.state.blocking});
    }   
    onDatabound(args) {
        for (let ind = 0; ind <= 6; ind++)
            document.getElementById('GridSituazioneIncarichi').querySelectorAll('.e-columnheader')[0].children[ind].style.backgroundColor = 'Linen';
        setTimeout(() => {
            if (JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileAuthenticationPayload')).data[0].TIPO_UTENTE === 'ADD')
                this.gridInstance.toolbarModule.enableItems(['GridSituazioneIncarichi_add'], false);
        }, 100);
    }
    onRowDatabound(args) {
        $($(args.row).find('button')[1]).hide(); 
        if (args.data.Stato === '00007') {
            args.row.className = 'e-row';
            args.row.bgColor = 'yellow';  
        }   
        if (args.data.Stato === '00008') {
            args.row.className = 'e-row';
            args.row.bgColor = 'lawngreen';  
        }   
        if (args.data.Stato === '00009' && !CustomJS.isNullEmpty(args.data.FirmaFineLavoriCliente) && 
            JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileAuthenticationPayload')).data[0].TIPO_UTENTE === 'SQD') {
            $($(args.row).find('button')[1]).show(); 
        }
    }
    toolbarClick(args) {
        switch (args.item.properties.prefixIcon) {
            case 'e-pdfexport':
                this.gridInstance.pdfExport();
                break;
            case 'e-excelexport':
                this.gridInstance.excelExport();
                break;
            case 'e-csvexport':
                this.gridInstance.csvExport();
                break;
        }
        if (args.item.id === 'GridSituazioneIncarichi_add') {
            sessionStorage.setItem('JellyfishTeamsMobileDatiIncarico', JSON.stringify({ GuidIncarico: 'NEW', GuidDocumento: null, NotaIncarico: '', Nazione: 'ITA', Stato: '00007' }));
            ++this.childKey;
            const container = document.getElementById('contenutoDinamico2');
            const root = createRoot(container); 
            root.render(<DettaglioIncarico key={this.childKey}></DettaglioIncarico>);  
        }
        if (args.item.id === 'aggiornatabella') {
            this.caricaDati();
        }
    }
    onCommandClick(args) {
        if (args.commandColumn.buttonOption.iconCss.indexOf('fa-edit') > -1) {
            this.toggleBlocking();
            sessionStorage.setItem('JellyfishTeamsMobileDatiIncarico', JSON.stringify(args.rowData));   
            sessionStorage.setItem('JellyfishTeamsMobileArrivoDaCalend', 'false');        
            ++this.childKey;
            const container = document.getElementById('contenutoDinamico2');
            const root = createRoot(container); 
            if (document.getElementById('defaultDialogSitInc'))
                document.getElementById('defaultDialogSitInc').ej2_instances[0].destroy();
            if (document.getElementById('defaultDialogDettInc'))
                document.getElementById('defaultDialogDettInc').ej2_instances[0].destroy();
            if (document.getElementById('defaultDialogDettInc2'))
                document.getElementById('defaultDialogDettInc2').ej2_instances[0].destroy();
            if (document.getElementById('defaultDialogDettSoprall'))
                document.getElementById('defaultDialogDettSoprall').ej2_instances[0].destroy();
            root.render(<DettaglioIncarico key={this.childKey}></DettaglioIncarico>);
        }
        if (args.commandColumn.buttonOption.iconCss.indexOf('fa-eye') > -1) {
            const datiSoc = {
                CodDiv: JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileAuthenticationPayload')).data[0].COD_DIV,
                CodSoc: JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileAuthenticationPayload')).data[0].COD_SOC,
                CodFil: JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileAuthenticationPayload')).data[0].COD_FIL,
                LinguaAppl: 'ITA',
                FormatoData: 'dd/MM/yyyy'
            }
            this.toggleBlocking();
            CustomJS.getRemoteData4(`${CustomJS.URLApplicazioneProd}/api/PdfCreator/CreaDocumentoFineLavoriDaJFTeamsMobile`, { guid_inc: args.rowData.GuidIncarico, dati_soc_json: JSON.stringify(datiSoc) }, 'POST', 
            {'Authorization':  CustomJS.tokenAuthAPIAppTeamsBackoffice }, retData => {
                this.toggleBlocking();
                this.dialogInstance.header = `VISUALIZZAZIONE DOCUMENTO FINE LAVORI NUM. ${args.rowData.NumeroIncarico}`;  
                this.dialogInstance.width = '85%';
                this.dialogInstance.height = '90%';
                this.dialogInstance.minHeight = '90%';
                this.dialogInstance.buttons = []; 
                this.dialogInstance.beforeOpen = (args) => {
                    const container = document.getElementById('defaultDialogSitIncContent');
                    const root = createRoot(container); 
                    root.unmount();
                };
                this.dialogInstance.open = (args) => { 
                    const container = document.getElementById('defaultDialogSitIncContent');
                    const root = createRoot(container); 
                    root.render(<CallbackWrapper callback={() => {
                        document.getElementById('PDFViewer').ej2_instances[0].enableDownload = true;
                        document.getElementById('PDFViewer').ej2_instances[0].serviceUrl = `${CustomJS.URLApplicazioneProd}/api/PdfViewerFromOutside`;
                        document.getElementById('PDFViewer').ej2_instances[0].ajaxRequestSettings.ajaxHeaders = [{ headerName: 'Authorization', headerValue: CustomJS.tokenAuthAPIAppTeamsBackoffice }];
                        setTimeout(() => {
                            document.getElementById('PDFViewer').ej2_instances[0].load(retData, null);
                            //document.getElementById('PDFViewer').ej2_instances[0].load(`data:application/pdf;base64,${retData}`, null);
                        }, 100);   
                    }}>{this.PDFViewerRender()}</CallbackWrapper>); 
                };
                this.dialogInstance.show();   
            }, error => {
                let messaggio = _.isString(error) ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),                    
                    position: "bottom-right",
                    autoClose: 15000,
                    hideProgressBar: false,            
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true});
                this.toggleBlocking();
            }, true, false);
        }
    }
    onChangeStato(args) {
        sessionStorage.setItem('JellyfishTeamsMobileFiltroStatoSituazioneIncarichi', args.value);
        if (args.isInteracted)
            this.caricaDati();
    }
    ddlStatiToolbar(args) {      
        return(<DropDownListComponent id='ddlStati' fields={this.ddlFields} index='0' ref={ ddlObj => this.ddlInstance = ddlObj } dataSource={this.dsStati} change={this.onChangeStato.bind(this)} allowFiltering={false} filterType='Contains'
                    style={{width: '22vw'}} placeholder="Selezionare uno stato da visualizzare" floatLabelType='always' popupHeight="250px" cssClass='e-outline'/>); 
    }
    PDFViewerRender() {
        ++this.childKey;
        return(<React.Fragment>
                <div className="control-section card-control-section basic_card_layout">
                    <div className="e-card-resize-container">
                        <div className="card-layout">
                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">                                           
                                <div tabIndex="0" className="e-card" id="main_card">                                                
                                    <div className="e-card-content">
                                        <div className='row'>
                                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                                <PdfViewerComponent id='PDFViewer' key={this.childKey} width="auto" style={{ 'height': '730px' }} 
                                                    enablePrint={true} enableDownload={true} enableAnnotation={false} enableCommentPanel={false}>
                                                <PDFVInject services={[PDFVToolbar, Magnification, Navigation, LinkAnnotation, BookmarkView, ThumbnailView, Print, TextSelection, TextSearch, Annotation, FormFields, FormDesigner]} />
                                                </PdfViewerComponent>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>);
    }

    render() {
        let titoloPagina = `SITUAZIONE INCARICHI - ${JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileAuthenticationPayload')).data[0].DESCRIZ}`;
        let tipoUtente = JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileAuthenticationPayload')).data[0].TIPO_UTENTE === 'SQD' ? 'Squadra/Capo Squadra' : 'Addetto';
        return (<React.Fragment>
            <DialogComponent id="defaultDialogSitInc" showCloseIcon={true} target='body' animationSettings={this.dialogAnimationSettings} visible={this.state.showDialog1} 
                    ref={dialog => this.dialogInstance = dialog} isModal={true} height='90%' width='90%' minHeight='90%'>   
                    <div id='defaultDialogSitIncContent'></div>                         
            </DialogComponent> 
            <BlockUi id='blockUILogin' tag='div' blocking={this.state.blocking} keepInView={true} loader={CustomJS.loaderSpinners[random(CustomJS.loaderSpinners.length - 1)]}>                            
                <ToastContainer containerId='toastContainer1' transition={Zoom} style={{ fontSize: '1.3vw', color: 'darkred', fontWeight: 'bold', width: '30vw' }}></ToastContainer>
                <div className='container-fluid'> 
                    <div className="row" style={{ marginTop: '2.5%'}}>                        
                        <div id='divTitoloPagina' className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                            <span style={{ fontSize: '2.7vw', fontWeight: 'bolder', color:'#133B95'}}>{titoloPagina}<span style={{ fontSize: '1.7vw', fontWeight: 'bolder', color:'#133B95' }}>({tipoUtente})</span></span>
                        </div>                                           
                    </div>
                    <div className='row'>  
                        <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>               
                            <GridComponent id="GridSituazioneIncarichi" dataSource={this.state.GridData2} ref={ grid => this.gridInstance = grid } toolbar={this.toolbarOptions} editSettings={this.editSettings} toolbarClick={this.toolbarClick.bind(this)}
                                allowSelection={false} enableHover={false} dataBound={this.onDatabound.bind(this)} rowDataBound={this.onRowDatabound.bind(this)} commandClick={this.onCommandClick.bind(this)} 
                                allowTextWrap={true} allowPaging={false} allowSorting={true} allowFiltering={false} enableAltRow={true} enablePersistence={false}>
                                <ColumnsDirective>
                                    <ColumnDirective field='NumeroIncarico' headerText='Numero Incarico' width='12%' headerTemplate={headerTemplate} template={fieldTemplate} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                    <ColumnDirective field='DataInizioLavori' headerText="Data/Ora Inizio Lavori" format='dd/MM/yyyy HH:mm' width='12%' headerTemplate={headerTemplate} template={fieldTemplate} textAlign='Center'></ColumnDirective>
                                    <ColumnDirective field='NomeIndirizzoCliente' headerText="Nominativo e Indirizzo Cliente" width='50%' headerTemplate={headerTemplate} template={fieldTemplate} textAlign='Left'></ColumnDirective>
                                    <ColumnDirective field='CapoSquadraDescr' headerText="Capo Squadra" width='8%' headerTemplate={headerTemplate} template={fieldTemplate} textAlign='Center'></ColumnDirective>
                                    <ColumnDirective field='AddettiDescr' headerText="Addetti" visible={JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileAuthenticationPayload')).data[0].TIPO_UTENTE === 'SQD'} width='8%' headerTemplate={headerTemplate} template={fieldTemplate} textAlign='Center'></ColumnDirective>
                                    <ColumnDirective field='Notifica' headerText="" width='3%' template={fieldTemplate} textAlign='Center' allowEditing={false} allowSorting={false}></ColumnDirective>
                                    <ColumnDirective field='Comandi' headerText="" width='5%' commands={this.commands} textAlign='Center' allowSorting={false}></ColumnDirective>
                                </ColumnsDirective>
                                <Inject services={[Toolbar, ExcelExport, PdfExport, Filter, CommandColumn, Sort]} />
                            </GridComponent>
                        </div>
                    </div>
                </div>
            </BlockUi>
        </React.Fragment>);
    }    
    componentDidMount() {
        setTimeout(() => {   
            if (CustomJS.isNullEmpty(sessionStorage.getItem('JellyfishTeamsMobileFiltroStatoSituazioneIncarichi')))
                sessionStorage.setItem('JellyfishTeamsMobileFiltroStatoSituazioneIncarichi', '00007');
            this.caricaDati();
        }, 250);
    }
    componentWillUnmount () {
        this.dialogInstance.destroy();
    }
    caricaDati() {
        const CodDiv = JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileAuthenticationPayload')).data[0].COD_DIV,
              CodSoc = JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileAuthenticationPayload')).data[0].COD_SOC,
              CodFil = JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileAuthenticationPayload')).data[0].COD_FIL,
              CodUtente = JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileAuthenticationPayload')).data[0].COD_ELE,
              Stato = sessionStorage.getItem('JellyfishTeamsMobileFiltroStatoSituazioneIncarichi'),
              TipoUtente = JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileAuthenticationPayload')).data[0].TIPO_UTENTE,
              ListaSQDVediAss = !CustomJS.isNullEmpty(JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileAuthenticationPayload')).data[0].LISTA_SQD_VEDI_ASS) ? JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileAuthenticationPayload')).data[0].LISTA_SQD_VEDI_ASS : '[NoParam]',
              APIURL = `${CustomJS.APIJfishTeamsURLProd}/api/JellyfishTeams/situazioneIncarichi/${CodDiv}/${CodSoc}/${CodFil}/${CodUtente}/${Stato}/${TipoUtente}/${ListaSQDVediAss}`;
        this.toggleBlocking();
        CustomJS.callAPI(APIURL, '', 'GET', '', JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileAuthenticationPayload')).token, retData => { 
            if (retData.response === 'KO') {
                let messaggio = (retData.msg === 'UtenteNonAutorizzato') ? CustomJS.messaggioUtenteNonAutorizzato : (retData.msg === 'SessioneScaduta') ? CustomJS.messaggioSessioneScaduta : retData.msg;
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    onClose: () => toast.dismiss(),                    
                    position: "bottom-right",
                    autoClose: 15000,
                    hideProgressBar: false,            
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                });
                this.toggleBlocking();
                return;
            }
            GridData = [];
            retData.forEach(elm => {
                let addettiArr = elm.ADDETTI?.split(',');
                let addettiDescr = addettiArr?.map(elm => { return _.find(JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileTabellaADD')), elm2 => { return elm2.VALUE === elm }).TEXT }).join(', ');
                let capoSquadraDescr = _.find(JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileTabellaSQD')), elm2 => { return elm2.VALUE === elm.SQUADRA }).TEXT;
                GridData.push({ GuidIncarico: elm.GUID, GuidDocumento: elm.GUID_DOCUMENTO, NumeroIncarico: elm.NUMERO, DataInizioLavori: moment(elm.DATA_INIZIO_LAVORI).format('DD/MM/YYYY HH:mm'), DataFineLavoriPrevista: CustomJS.isNullEmpty(elm.DATA_FINE_LAVORI_PREV) ? null : moment(elm.DATA_FINE_LAVORI_PREV).format('DD/MM/YYYY HH:mm'), 
                    DataFineLavori: moment(elm.DATA_FINE_LAVORI).format('DD/MM/YYYY HH:mm'), DurataPrevistaLavori: elm.DURATA_PREVISTA, Cliente: elm.CLIENTE, Indirizzo: elm.INDIRIZZO, Citta: elm.CITTA, CAP: elm.CAP, Provincia: elm.PROVINCIA, NomeCliDann: elm.NOME_CLIENTE_DANNEGG, Cantiere: elm.CANTIERE, Squadra: elm.SQUADRA, Addetti: elm.ADDETTI, 
                    Nazione: elm.NAZIONE, NomeIndirizzoCliente: elm.INDIRIZZO_COMPLETO, Contatto: elm.CONTATTO, Telefono: elm.TELEFONO, Email: elm.EMAIL, NotaIncarico: elm.NOTA, FirmaFineLavoriCliente: elm.FIRMA_FINE_LAVORI, AddettiDescr: addettiDescr, CapoSquadraDescr: capoSquadraDescr,
                    Stato: elm.STATO, Emergenza: elm.EMERGENZA });
            });
            this.ddlInstance.value = sessionStorage.getItem('JellyfishTeamsMobileFiltroStatoSituazioneIncarichi');
            this.setState({GridData2: GridData});        
            this.toggleBlocking();
        }, error => {
            let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
            toast.warn(messaggio, {
                containerId: 'toastContainer1',
                onClose: () => toast.dismiss(),                    
                position: "bottom-right",
                autoClose: 15000,
                hideProgressBar: false,            
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true});
            this.toggleBlocking();
        }, true, false)
    }
}