import React from 'react';
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import InternetSpeedMeter from './InternetSpeedMeter';
import registerServiceWorker from './registerServiceWorker';
import ErrorBoundary from './errorBoundary';
import 'underscore/underscore-min';
import 'bootstrap/dist/css/bootstrap.css';
import './Custom.css';
import './BlockUI.css';
import './assets/fontawesome/css/all.min.css';
import 'react-internet-meter/dist/index.css';
import { registerLicense } from '@syncfusion/ej2-base';

// Registering Syncfusion license key
registerLicense('Ngo9BigBOggjHTQxAR8/V1NGaF5cXmVCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdgWXldcHRdRWJYVUFxV0c=');

const rootElement = document.getElementById('root');
const root = createRoot(rootElement); 
root.render(<BrowserRouter>
      <ErrorBoundary>
         <App />
         <InternetSpeedMeter></InternetSpeedMeter>
      </ErrorBoundary>
   </BrowserRouter>);

registerServiceWorker();