/* eslint-disable default-case */
import * as React from 'react';
import { GridComponent, ColumnsDirective, ColumnDirective, Toolbar, ExcelExport, PdfExport, CommandColumn, Filter, Inject, Sort, Edit } from '@syncfusion/ej2-react-grids';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { DropDownList } from '@syncfusion/ej2-react-dropdowns';
import { ProgressButtonComponent } from '@syncfusion/ej2-react-splitbuttons';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { Query, DataManager } from '@syncfusion/ej2-data';
import { Browser, addClass, removeClass } from '@syncfusion/ej2-base';
import * as CustomJS from '../Custom';
import { SampleBase } from './sampleBase';
import _ from 'underscore/underscore-min';
import { ToastContainer, toast, Slide, Zoom, Flip, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import $ from 'jquery';

let GridData = [];

function headerTemplate(props) {
    return (<div>
        <span style={{ fontSize: '1.6vw', fontWeight: 'bolder' }}>{props.headerText}</span>
    </div>);
}

function fieldTemplate(props) {  
    let colore = props.column.field === 'Descrizione' ? '#133B95' : 'purple';
    let renderDiv = props.column.field === 'Descrizione' ? props[props.column.field] : <div><span style={{ fontSize: '2.1vw', color: colore, fontWeight: 'bolder' }}>{props[props.column.field]}</span></div>;
    return (
        renderDiv
    );
}

export default class GridMaterialiManodopera extends SampleBase {
    constructor() {
        super(...arguments); 
        this.editSettings = { allowAdding: true, allowEditing: true, allowDeleting: true, showDeleteConfirmDialog: true };
        this.toolbarOptions = ['Add', 'Cancel', 'Update', { text: 'Inserimento/Modifica riga descrittiva', prefixIcon: "e-insertrigadescr", id: "insertrigadescr" }, 'Search'];
        this.ddlObj1 = null;
        this.ddlObj2 = null;
        this.elem1 = null;
        this.elem2 = null;
        this.commands = [{ type: "Edit", buttonOption: { iconCss: "fas fa-edit fa-10x", cssClass: "e-details" } }, { type: "Delete", buttonOption: { iconCss: "fas fa-trash-alt fa-10x", cssClass: "e-details" } }];
        this.elemSelezUDM = null;
        this.elemSelezIVA = null;
        this.ddlUDMDatasource = JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileTabellaUDM')).filter(elm => { return !CustomJS.isNullEmpty(elm.VALUE) });
        this.ddlIVADatasource = JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileTabellaIVA')).filter(elm => { return !CustomJS.isNullEmpty(elm.VALUE) });
        this.UDMEditOptions = { 
            create: () => { 
                this.elem1 = document.createElement('input');
                return this.elem1;
            }, 
            destroy: () =>{
                this.ddlObj1.destroy();
            },
            read: () =>{
                return this.ddlObj1.value;
            },
            write: (args) =>{
                this.ddlObj1 = new DropDownList({       
                    fields: { text: 'TEXT', value: 'VALUE' },
                    placeholder: 'Seleziona unità di misura',
                    popupHeight: '150px',
                    allowFiltering: true,
                    filtering: function (e) {
                        let dropdown_query = new Query();
                        dropdown_query = (e.text !== '') ? dropdown_query.where('Text', 'contains', e.text, true) : dropdown_query;
                        e.updateData(this.ddlUDMDatasource, dropdown_query);
                    },
                    change: function (args2) { this.elemSelezUDM = args2.value; },
                    dataSource: this.ddlUDMDatasource,
                    sortOrder: 'Ascending',
                    id: 'ddlNuovoUDM',             
                    value: args.rowData[args.column.field]
                });
                this.ddlObj1.appendTo(this.elem1);
            }
        }
        this.IVAEditOptions = { 
            create: () => { 
                this.elem2 = document.createElement('input');
                return this.elem2;
            }, 
            destroy: () =>{
                this.ddlObj2.destroy();
            },
            read: () =>{
                return this.ddlObj2.value;
            },
            write: (args) =>{
                this.ddlObj2 = new DropDownList({       
                    fields: { text: 'TEXT', value: 'VALUE' },
                    placeholder: 'Seleziona aliquota IVA',
                    popupHeight: '150px',
                    allowFiltering: true,
                    filtering: function (e) {
                        let dropdown_query = new Query();
                        dropdown_query = (e.text !== '') ? dropdown_query.where('Text', 'contains', e.text, true) : dropdown_query;
                        e.updateData(this.ddlIVADatasource, dropdown_query);
                    },
                    change: function (args2) { this.elemSelezIVA = args2.value; },
                    dataSource: this.ddlIVADatasource,
                    sortOrder: 'Ascending',
                    id: 'ddlNuovoIVA',             
                    value: args.rowData[args.column.field]
                });
                this.ddlObj2.appendTo(this.elem2);
            }
        }
        this.numericParams = { params: { decimals: 2, showSpinButton: false, min: 0 } };
        this.validationRules = { required: true};
        this.dialogButtons1 = [{
            click: () => {
                if (CustomJS.isNullEmpty(this.Txt1.value.trim()))
                    return;                
                if (this.rigaDescrMod)
                    this.gridInstance1.getSelectedRecords()[0].Descrizione = `<p>${this.Txt1.value?.replace(/(?:\r\n|\r|\n)/g, '<br>').trim()}</p>`;
                else
                    this.gridInstance1.dataSource.unshift({
                        Guid: CustomJS.createGuid4(), Progr: 0, Descrizione: `<p>${this.Txt1.value?.replace(/(?:\r\n|\r|\n)/g, '<br>').trim()}</p>`, RigaDescr: 'D', RigaNuova: 'S'
                    });
                this.dialogInstance1.hide();
                this.gridInstance1.refresh();       
            },
            buttonModel: {
                content: 'SALVA',
                cssClass: 'e-primary',
                isPrimary: true
            }
        },
        {
            click: () => {
                this.dialogInstance1.hide();
            },
            buttonModel: {
                content: 'ANNULLA',
            }
        }];    
        this.dialogAnimationSettings = { effect: 'FadeZoom' };
        this.salvaGuid = '';
        this.rigaDescrMod = false;
        this.azioneInsert = false;
    }
    state = {
        GridData2: GridData,
        showDialog1: false
    } 
    handleFullScreen(e) {
        let sbCntEle = document.querySelector('.sb-content.e-view');
        let sbHdrEle = document.querySelector('.sb-header.e-view');
        let leftBar;
        let transformElement;
        if (Browser.isDevice) {
            leftBar = document.querySelector('#right-sidebar');
            transformElement = document.querySelector('.sample-browser.e-view.e-content-animation');
        }
        else {
            leftBar = document.querySelector('#left-sidebar');
            transformElement = document.querySelector('#right-pane');
        }
        if (e.targetItem === 'Maximize') {
            if (Browser.isDevice && Browser.isIos) {
                addClass([sbCntEle, sbHdrEle], ['hide-header']);
            }
            addClass([leftBar], ['e-close']);
            removeClass([leftBar], ['e-open']);
            if (!Browser.isDevice) {
                transformElement.style.marginLeft = '0px';
            }
            transformElement.style.transform = 'inherit';
        }
        else if (e.targetItem === 'Minimize') {
            if (Browser.isDevice && Browser.isIos) {
                removeClass([sbCntEle, sbHdrEle], ['hide-header']);
            }
            removeClass([leftBar], ['e-close']);
            if (!Browser.isDevice) {
                addClass([leftBar], ['e-open']);
                transformElement.style.marginLeft = leftBar.offsetWidth + 'px';
            }
            transformElement.style.transform = 'translateX(0px)';
        }
    }     
    onQueryCellInfo(args) {        
        if (args.column.field === "Descrizione")
            args.cell.innerHTML = `<div><span style="font-size: 1.8vw; color: #133B95; font-weight: bolder;">${args.cell.innerHTML}</span></div>`;
        else if (args.column.field === 'Quantita' || args.column.field === 'PrezzoUnitario')
            args.cell.innerHTML = `<div><span style="font-size: 2.0vw; color: purple; font-weight: bolder;">${args.cell.innerHTML}</span></div>`;
    }
    onDatabound(args) {
        for (let ind = 0; ind <= 6; ind++)
            document.getElementById('GridMaterialiManodopera').querySelectorAll('.e-columnheader')[0].children[ind].style.backgroundColor = 'Linen';
    }
    onRowDatabound(args) { 
        if (args.data.RigaNuova === 'S') {
            args.row.className = 'e-row';
            args.row.bgColor = 'lightgreen';   
            $($(args.row).find('button')[0]).show();
            $($(args.row).find('button')[1]).show();
        }  
        else {
            $($(args.row).find('button')[0]).hide();  
            $($(args.row).find('button')[1]).hide(); 
        }  
    }
    onToolbarClick(args) {
        switch (args.item.properties.prefixIcon) {
            case 'e-pdfexport':
                this.gridInstance.pdfExport();
                break;
            case 'e-excelexport':
                this.gridInstance.excelExport();
                break;
            case 'e-csvexport':
                this.gridInstance.csvExport();
                break;
        }
        if (args.item.id === 'insertrigadescr') {
            this.rigaDescrMod = this.gridInstance1.getSelectedRecords().length === 1;
            this.Txt1.value = this.rigaDescrMod ? this.gridInstance1.getSelectedRecords()[0].Descrizione.replace('<br>') : '';
            this.dialogInstance1.show();            
        }        
    }
    onActionBegin(args) {
        if (args.requestType === "beginEdit" && args.rowData?.RigaNuova !== 'S') {
            args.cancel = true;
            return;
        }            
        if (args.requestType === "beginEdit" && args.rowData?.RigaDescr === 'D') {
            this.azioneInsert = false;
            this.gridInstance1.selectRows([args.rowIndex]);
            this.rigaDescrMod = this.gridInstance1.getSelectedRecords().length === 1;            
            this.Txt1.value = this.rigaDescrMod ? this.gridInstance1.getSelectedRecords()[0].Descrizione.replace(/<br>/g, '\n') : '';
            this.dialogInstance1.show();            
            args.cancel = true;
            return;
        }
        if (args.requestType === 'save') {         
            args.data.RigaDescr = 'F'; 
            args.data.RigaNuova = 'S';  
            if (CustomJS.isNullEmpty(args.data.Guid))
                args.data.Guid = CustomJS.createGuid4();           
        }
    }
    onActionComplete(args) {
        if ((args.action === "edit" || args.action === "add") && args.requestType === "save") {
            args.data.Tipo_documento = '00002'; 
            setTimeout(() => { this.gridInstance1.clearSelection(); }, 100);            
        }
    }
    onCommandClick(args) {

    }
    onRowSelecting(args) {
        if (args.data.RigaNuova !== 'S') {
            args.cancel = true;
            return;
        }
    }
    ProgrButtCreated1() {
        document.getElementById('btnAccettazione').ej2_instances[0].element.addEventListener('click', () => {
            document.getElementById('TabIncarico').ej2_instances[0].select(5);
        });
    }    
    dialogOpen1(args) {        
        if (!this.rigaDescrMod) {
            this.dialogInstance1.header = 'Inserimento riga descrittiva';
            this.salvaGuid = '';
            this.Txt1.value = null;
        }
        else {
            this.dialogInstance1.header = 'Modifica riga descrittiva';
            this.salvaGuid = this.gridInstance1.getSelectedRecords()[0].Guid;
            this.Txt1.value = this.gridInstance1.getSelectedRecords()[0].Descrizione.replace(/<br>/g, '\n').replace(/<p>/g, '').replace(/<\/p>/g, '');
        } 
    }

    render() {
        return(<div className="control-section card-control-section basic_card_layout">
                     <ToastContainer containerId='toastContainer5' transition={Zoom} style={{ fontSize: '1.3vw', color: 'midnightblue', fontWeight: 'bold', width: '30vw' }}></ToastContainer>
                     <DialogComponent id="defaultDialog1" target='body' animationSettings={this.dialogAnimationSettings} visible={this.state.showDialog1} width='90%'
                                      ref={dialog => this.dialogInstance1 = dialog} height='55vh' minHeight='55vh' isModal={true} buttons={this.dialogButtons1} open={this.dialogOpen1.bind(this)}>
                            <div className="col-lg-12 control-section card-control-section basic_card_layout">
                                <div className="e-card-resize-container">
                                    <div className="card-layout">
                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">                                           
                                            <div tabIndex="0" className="e-card" id="main_card">                                                
                                                <div className="e-card-content">                                                    
                                                    <div className='row'>
                                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                                            <TextBoxComponent id='DescrIntervento' showClearButton={true} value='' multiline={true} ref={txt1 => this.Txt1 = txt1} 
                                                                        floatLabelType='Auto' placeholder='Descrizione Intervento' cssClass='e-outline'>
                                                            </TextBoxComponent>                                                                                                               
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                     </DialogComponent>
                     <div className="e-card-resize-container">
                        <div className="card-layout">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">                                
                                <div tabIndex="0" className="e-card" id="main_card">
                                    <div className="e-card-header">
                                        <div className="e-card-header-caption">
                                            <span style={{ fontSize: '3.0vw', fontWeight: 'bolder', paddingLeft: '5px', color:'#133B95'}}>MATERIALI/MANODOPERA LAVORI</span>
                                        </div>
                                    </div>
                                    <div className="e-card-content">
                                        <div className="row">
                                            <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>               
                                                <GridComponent id="GridMaterialiManodopera" dataSource={this.state.GridData2} ref={ grid => this.gridInstance1 = grid } toolbar={this.toolbarOptions} editSettings={this.editSettings} 
                                                    allowSelection={true} enableHover={false} dataBound={this.onDatabound.bind(this)} rowDataBound={this.onRowDatabound.bind(this)} commandClick={this.onCommandClick.bind(this)}
                                                    allowTextWrap={true} allowPaging={false} allowSorting={false} allowFiltering={false} enableAltRow={true} enablePersistence={false} actionBegin={this.onActionBegin.bind(this)}
                                                    actionComplete={this.onActionComplete.bind(this)} queryCellInfo={this.onQueryCellInfo.bind(this)} toolbarClick={this.onToolbarClick.bind(this)} rowSelecting={this.onRowSelecting.bind(this)}>
                                                    <ColumnsDirective>
                                                        <ColumnDirective field='Descrizione' headerText='Descrizione materiale/manodopera' width='50%' headerTemplate={headerTemplate} validationRules={this.validationRules} disableHtmlEncode={false} textAlign='Center' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                                        <ColumnDirective field='UnitaMisura' headerText="Unità misura" width='14%' editType='DropDownEdit' edit={this.UDMEditOptions} headerTemplate={headerTemplate} template={fieldTemplate} textAlign='Center'></ColumnDirective>
                                                        <ColumnDirective field='Quantita' headerText="Quant." width='14%' format='n2' headerTemplate={headerTemplate} editType='NumericEdit' edit={this.numericParams} validationRules={this.validationRules} textAlign='Center'></ColumnDirective>
                                                        <ColumnDirective field='PrezzoUnitario' headerText="Prezzo Unitario" width='14%' format='n2' headerTemplate={headerTemplate} editType='NumericEdit' edit={this.numericParams} textAlign='Center'></ColumnDirective>
                                                        <ColumnDirective field='CodiceIVA' headerText="IVA" width='14%' editType='DropDownEdit' edit={this.IVAEditOptions} headerTemplate={headerTemplate} template={fieldTemplate} textAlign='Center'></ColumnDirective>
                                                        <ColumnDirective field='RigaDescr' visible={false}></ColumnDirective>
                                                        <ColumnDirective field='Comandi' headerText="" width='10%' commands={this.commands} textAlign='Center'></ColumnDirective>
                                                    </ColumnsDirective>
                                                    <Inject services={[Edit, Toolbar, ExcelExport, PdfExport, Filter, CommandColumn, Sort]} />
                                                </GridComponent>
                                            </div>
                                        </div>
                                        <div id='divBtnAccettazione' className="row">
                                           <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                                               <ProgressButtonComponent id='btnAccettazione' style={{ height: '30px' }} content="Per Accettazione" enableProgress duration='1000' spinSettings={{ position: 'Center' }} 
                                                   animationSettings={{effect: 'SlideRight'}} created={this.ProgrButtCreated1.bind(this)} cssClass="e-fill e-success"></ProgressButtonComponent>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        );
    }
    rendereComplete() {
        this.setState({GridData2: null}); 
        this.caricaDati();
        this.Txt1.addAttributes({ rows: 5 });
        if (CustomJS.isNullEmpty(JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).FirmaFineLavoriCliente)
            && (JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).Stato === '00009'))
            document.getElementById('divBtnAccettazione').style.removeProperty('display');
        else
            document.getElementById('divBtnAccettazione').style.display = 'none';
    }
    caricaDati() {
        const CodDiv = JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileAuthenticationPayload')).data[0].COD_DIV,
        CodSoc = JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileAuthenticationPayload')).data[0].COD_SOC,
        CodFil = JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileAuthenticationPayload')).data[0].COD_FIL,
        GuidIncarico = JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).GuidIncarico,
        GuidDocumento = CustomJS.isNullEmpty(JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).GuidDocumento) 
                        ? JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).GuidIncarico : JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).GuidDocumento;
        CustomJS.callAPI(`${CustomJS.APIJfishTeamsURLProd}/api/JellyfishTeams/caricaListeIncarico/${CodDiv}/${CodSoc}/${CodFil}/${GuidIncarico}/${GuidDocumento}/MATMANOD`, '', 'GET',
        '', JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileAuthenticationPayload')).token, retData => { 
            if (retData.response === 'KO') {
                let messaggio = (retData.msg === 'UtenteNonAutorizzato') ? CustomJS.messaggioUtenteNonAutorizzato : (retData.msg === 'SessioneScaduta') ? CustomJS.messaggioSessioneScaduta : retData.msg;
                toast.warn(messaggio, {
                    onClose: () => toast.dismiss(),                    
                    position: "bottom-right",
                    autoClose: 15000,
                    hideProgressBar: false,            
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                });
                return;
            }               
            GridData = [];
            retData.forEach(elm => {            
                GridData.push({ Guid: elm.GUID, Progr: elm.PROGR, Descrizione: elm.DESCRIZIONE, UnitaMisura: elm.UNITA_MISURA, CodiceIVA: elm.CODICE_IVA, Quantita: elm.QUANTITA, 
                                PrezzoUnitario: parseFloat(parseFloat(elm.PREZZO_UNITARIO + ((elm.PERC_RICARICO * elm.PREZZO_UNITARIO) / 100)).toFixed(2)).toLocaleString(), RigaDescr: elm.RIGA_DESCR })
            });
            this.setState({ GridData2: GridData});
        }, error => {
            let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
            toast.warn(messaggio, {
                onClose: () => toast.dismiss(),                    
                position: "bottom-right",
                autoClose: 15000,
                hideProgressBar: false,            
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true});        
        }, true, false);
    }
}