/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';
import { SampleBase } from './sampleBase';
import * as CustomJS from '../Custom';
import LogoDBF from '../assets/LogoDBF.jpeg';
import _ from 'underscore';

export default class Home extends SampleBase {
    constructor() {
        super(...arguments);
        this.listaLogoSocietaJFTeams = [{ id: '00001', logo: LogoDBF }];
        this.logoAttualeSocRipACS = _.find(this.listaLogoSocietaRipACS, elm => { return elm.id === JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileAuthenticationPayload')).data[0].id_user; })?.logo;
        this.logoAttualeSocJFTeams= _.find(this.listaLogoSocietaJFTeams, elm => { return elm.id === JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileAuthenticationPayload')).data[0].COD_SOC; })?.logo;        
        this.LogoSocieta = !CustomJS.isNullEmpty(JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileAuthenticationPayload')).data[0].id_user) ? this.logoAttualeSocRipACS : this.logoAttualeSocJFTeams;        
    }
    state = {
        divAddetti: <div></div>
    }

    render () {        
        return (<React.Fragment>  
            <div className="control-section card-control-section basic_card_layout">
                <div className="e-card-resize-container">
                    <div className="card-layout">
                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">                                           
                            <div tabIndex="0" className="e-card" id="main_card">                                                
                                <div className="e-card-content">
                                    <img id='imgLogo' src={this.LogoSocieta} style={{ paddingTop: '40px' }} width='50%' height='auto'></img>
                                    <div className='row'></div>
                                    <div className='row'>
                                        <div className="e-card-header">
                                            <div className="e-card-header-caption">
                                                <span style={{ fontSize: '5.0vw', fontWeight: 'bolder', paddingLeft: '15px', color:'#133B95'}}>{!CustomJS.isNullEmpty(JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileAuthenticationPayload')).data[0].id_user) ? JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileAuthenticationPayload')).data[0].artigiano_app : JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileAuthenticationPayload')).data[0].DESCRIZ }</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'></div>
                                    <div id='titoloListaAddetti' className='row'>
                                        <div className="e-card-header">
                                            <div className="e-card-header-caption">
                                                <span style={{ fontSize: '2.5vw', fontWeight: 'bolder', paddingLeft: '15px', color:'#133B95'}}>Lista addetti impegnati su incarichi assegnati</span>
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.divAddetti}                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>);
    }
    rendereComplete() {
        sessionStorage.setItem('JellyfishTeamsMobileArrivoDaCalend', 'false');
        if (!CustomJS.isNullEmpty(JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileAuthenticationPayload')).data[0].id_user)) {
            document.getElementById('titoloListaAddetti').style.display = 'none';
            return;
        }
        let cardDiv = [], rowDiv = [], addDiv = [];
        if (CustomJS.isNullEmpty(JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileAuthenticationPayload')).data[0].LISTA_ADDETTI)
            && JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileAuthenticationPayload')).data[0].TIPO_UTENTE === 'SQD') {
            cardDiv = <span style={{ fontSize: '1.7vw', fontWeight: 'bolder', paddingLeft: '15px', color:'#133B95'}}>Nessun addetto impegnato su incarichi assegnati</span>;
            this.setState({ divAddetti: cardDiv}); 
            return;
        }
        if (JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileAuthenticationPayload')).data[0].TIPO_UTENTE === 'ADD')
            document.getElementById('titoloListaAddetti').style.display = 'none';
        else {
            let arrAddetti = JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileAuthenticationPayload')).data[0].LISTA_ADDETTI.split(',');
            _.uniq(arrAddetti).forEach((elm,idx) => {  
                addDiv.push(<div className="col-xs-2 col-sm-4 col-lg-4 col-md-4">
                                <div tabIndex="0" className="e-card">
                                    <div className="e-card-header">
                                        <div className="e-card-header-caption">
                                            <div className="e-card-header-title" style={{ fontSize:'2.0vw' }}>{_.find(JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileTabellaADD')), elm2 => { return elm2.VALUE === elm })?.TEXT}</div>
                                        </div>
                                    </div>
                                    <div className="e-card-content">
                                    </div>
                                </div>
                            </div>);          
                if ((idx+1) % 3 === 0 || idx+1 === arrAddetti.length) {                
                    rowDiv.push(<div className="row card-layout">{addDiv}</div>);                
                    addDiv = [];
                }
            });    
            cardDiv.push(<div className="control-section card-control-section basic_card_layout">
                                <div className="e-card-resize-container">{rowDiv}</div> 
                                </div>);
            this.setState({ divAddetti: cardDiv }); 
        }
    }
}
