import React, { Component } from 'react';
import { createRoot } from 'react-dom/client'
import { NavMenu } from './NavMenu';
import SituazioneIncarichi from './SituazioneIncarichi';
import IdleTimeout2 from './IdleTimeout2';

export class Layout extends Component {
  static displayName = Layout.name;

  render () {
    return (<React.Fragment>      
        <NavMenu />   
        <div id="bgImageDiv2"></div> 
        <div id='contenutoDinamico2'></div>   
        <IdleTimeout2></IdleTimeout2>           
    </React.Fragment>);
  }

  componentDidMount() {   
    sessionStorage.setItem("JellyfishTeamsMobilePaginaLogin", "false");
    const container = document.getElementById('contenutoDinamico2');
    const root = createRoot(container); 
    root.render(<SituazioneIncarichi key={9999}></SituazioneIncarichi>);   
  }
}
