/* eslint-disable jsx-a11y/alt-text */
import React, { Component, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { CallbackWrapper } from 'react-callback';
import * as CustomJS from '../Custom';
import { Layout } from './Layout';
import LogoJellyfish from '../assets/LogoJellyfishTeamsMobileNuovo.png';
import LogoDigitalPraesidium from '../assets/LogoDigitalPraesidium.png';
import AmbienteTest from '../assets/AmbienteTest.png';
import RigaIndirizzoDP from '../assets/RigaIndirizzoDP.png';
import BlockUi from '@availity/block-ui';
import { ToastContainer, toast, Slide, Zoom, Flip, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { random, isUndefined } from 'underscore';
import $ from 'jquery';
import _ from 'underscore/underscore-min';

export default class Login extends Component { 
    constructor() {
        super(...arguments);           
        this.toggleBlocking = this.toggleBlocking.bind(this);
        this.childKey = 0; 
        this.tabList = ['PRV', 'CLI', 'NAZ', 'STP', 'EXT', 'ADD', 'UDM', 'GGC', 'CAN', 'SQD', 'IVA'];        
    }  
    state = {
        blocking: false       
    } 
    toggleBlocking() {        
        this.setState({blocking: !this.state.blocking});
    }   
    eseguiLogin() {
        if (ValidateForm()) {
            let dataObj = {
                CodUtente: document.getElementById("UserCode").value.trim(),
                CodAccesso: document.getElementById("Password").value.trim() 
            };
            this.toggleBlocking();
            CustomJS.callAPI(`${CustomJS.APIJfishTeamsURLProd}/api/JellyfishTeams/eseguiLogin`, JSON.stringify(dataObj), 
            'POST', 'application/json', '', retData => {                      
                if (retData.response === 'OK') { 
                    sessionStorage.setItem('JellyfishTeamsMobileAuthenticationPayload', JSON.stringify(retData)); 
                    const CodDiv = JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileAuthenticationPayload')).data[0].COD_DIV,
                          CodSoc = JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileAuthenticationPayload')).data[0].COD_SOC,
                          CodFil = JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileAuthenticationPayload')).data[0].COD_FIL, 
                          token = JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileAuthenticationPayload')).token;
                    this.toggleBlocking();
                    this.tabList.forEach(elm => {                        
                        CustomJS.caricaCombo(CustomJS.APIJfishTeamsURLProd, token, CodDiv, CodSoc, CodFil, elm, 'ITA', true, retData => {
                            if (elm === 'CLI')
                                retData = _.sortBy(retData, 'TEXT');
                            sessionStorage.setItem('JellyfishTeamsMobileTabella' + elm, JSON.stringify(retData));
                        }, messaggio => {
                            toast.warn(messaggio, {
                                onClose: () => toast.dismiss(),
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,            
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                                newestOnTop: true,
                                rtl: false,
                                pauseOnFocusLoss: true}
                            ); 
                        });
                    }); 
                    setTimeout(() => {
                        ++this.childKey;
                        $(document).unbind("keypress");
                        const container = document.getElementById('contenutoDinamico1');
                        const root = createRoot(container); 
                        root.render(<CallbackWrapper callback={() => {
                            sessionStorage.setItem("JellyfishTeamsMobileSessionTimeout", false);
                        }}><Layout key={this.childKey}></Layout></CallbackWrapper>); 
                    }, this.tabList.length * 50);                    
                }
                else if (retData.response === 'KO')
                    toast.warn(retData.msg === 'UtenteNonTrovato' ? CustomJS.messaggioUtenteNonTrovato : CustomJS.messaggioErroreNoDBSIPA, {
                        onClose: () => toast.dismiss(),
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,            
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        newestOnTop: true,
                        rtl: false,
                        pauseOnFocusLoss: true}
                    ); 
                this.toggleBlocking();
            }, error => {
                let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                toast.warn(messaggio, {
                    onClose: () => toast.dismiss(),                    
                    position: "bottom-right",
                    autoClose: 15000,
                    hideProgressBar: false,            
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true});
                this.toggleBlocking();
            }, true, false)
        }
    }
    
    render() {
        return(<React.Fragment>
            <BlockUi id='blockUILogin' blocking={this.state.blocking} keepInView={true} loader={CustomJS.loaderSpinners[random(CustomJS.loaderSpinners.length - 1)]}>                            
                <ToastContainer containerId='toastContainer1' transition={Zoom} style={{ fontSize: '2.4vw', color: 'darkred', fontWeight: 'bold', width: '40vw' }}></ToastContainer>
                <div id="bgImageDiv"></div>
                <div className="container-fluid">  
                    <div id='rowLogoJFT' className="row">
                        <div className="offset-md-4 col-md-4 offset-lg-4 col-lg-4 offset-sm-2 col-sm-8 offset-xs-1 col-xs-10">
                            &nbsp;&nbsp;&nbsp;
                            <img src={LogoJellyfish} style={{ maxWidth: '111%', height: 'auto' }} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="offset-md-3 col-md-4 offset-lg-4 col-lg-4 offset-sm-2 col-sm-8 offset-xs-1 col-xs-10">
                            <div className="containerLogin body-content-login">                                
                                <div id="login-box">
                                    <div className="row">
                                        <div className="col-lg-2 col-md-2 col-sm-2 col-xs-1">
                                            <h2 style={{ fontWeight: 'bold' }}>LOGIN</h2>
                                        </div>
                                        <div className="offset-lg-5 col-lg-3 offset-md-5 col-md-3 offset-sm-5 col-sm-3 offset-xs-3 col-xs-3 text-end" style={{ fontSize: '1.1em', fontWeight: 'bold' }}>
                                            Rel. 1.3.0
                                        </div>
                                    </div>
                                    <br />
                                    <br />
                                    <h6>Inserire le credenziali d'accesso</h6>
                                    <input type='text' id='UserCode' className='form-login' style={{ width: '350px' }} placeholder='Codice Squadra/Capo Squadra o Addetto'></input>
                                    <br />
                                    <br />
                                    <br />
                                    <input type='password' id='Password' className='form-login' style={{ width: '350px' }} placeholder='Password'></input>
                                    <div className="row col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9 text-center">
                                            <input type="button" id="BtnLogin" value="LOGIN" className="btn btn-primary btn-lg btn-default-login" onClick={this.eseguiLogin.bind(this)} />
                                        </div> 
                                    </div>                                                                   
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div id="divAmbienteTest" className="row">
                        <div className="offset-md-4 col-md-4 offset-lg-4 col-lg-4 offset-sm-2 col-sm-8 offset-xs-1 col-xs-10">
                            <img src={AmbienteTest} style={{ verticalAlign: 'top', paddingLeft: '100px' }} />
                        </div>
                    </div>    */}
                    <div className="row"></div>
                    <div className="row"></div>
                    <div id="footerLogin" className="row">
                        <div className="col-lg-4 col-md-4 col-sm-3 col-xs-3">
                            <img src={LogoDigitalPraesidium } style={{ width: '55%', height: 'auto' }} />
                        </div>
                        <div className="offset-lg-4 col-lg-4 offset-md-4 col-md-4 offset-sm-6 col-sm-3 offset-xs-6 col-xs-3">
                            <img src={RigaIndirizzoDP} style={{ width: '90%', height: 'auto' }} />
                        </div>
                    </div>               
                </div>
            </BlockUi>
        </React.Fragment>);
    }
    componentDidMount() {
        let sessioneScaduta = sessionStorage.getItem("JellyfishTeamsMobileSessionTimeout");
        if (sessioneScaduta === 'true') {
            let messaggio = CustomJS.messaggioSessioneScaduta;
            toast.warn(messaggio, {
                onClose: () => toast.dismiss(),
                position: "top-center",
                autoClose: false,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            });
            setTimeout(() => {
                sessionStorage.setItem("JellyfishTeamsMobileSessionTimeout", "false")
            }, 400);
        }
        sessionStorage.setItem("JellyfishTeamsMobilePaginaLogin", "true");
        $(document).on('keypress',
           event => {
              if (event.key === 'Enter') {
                  this.eseguiLogin();
              }
        });
        window.history.pushState(null, null, window.location.href);
        window.onpopstate = function () {
            window.history.go(1);
        };
        window.onbeforeunload = function() {
            if (sessionStorage.getItem("JellyfishTeamsMobilePaginaLogin") === "false" 
                && (CustomJS.isNullEmpty(sessionStorage.getItem("JellyfishTeamsMobileDownload")) || sessionStorage.getItem("JellyfishTeamsMobileDownload") === "false"))
                return "Attenzione!!! se lasci questa pagina perderai tutte le modifiche fatte e sarai portato alla pagina di Login. Sei sicuro/a di volerlo fare?";
        };
        $(document).on("keydown", disableF5);
        sessionStorage.setItem("JellyfishTeamsMobileCurrentView", "");
        sessionStorage.setItem("JellyfishTeamsMobileActiveCellsData", "");
        sessionStorage.setItem("JellyfishTeamsMobileCalendarioScelto", "");
        sessionStorage.setItem("JellyfishTeamsMobileDataCalendarioDa", "");
        sessionStorage.setItem("JellyfishTeamsMobileDataCalendarioA", "");
        $("#UserCode").focus();
    }
}

function ValidateForm() {
    if ($("#UserCode").val().trim() === "") {
        $("#UserCode").attr("placeholder",'Codice Squadra');
        $("#UserCode").css("border-color", "red");
        $("#UserCode").css("border-width", "3px");
        return false;
    }

    if ($("#Password").val().trim() === "") {
        $("#Password").attr("placeholder",'Password');
        $("#Password").css("border-color", "red");
        $("#Password").css("border-width", "3px");
        return false;
    }
    
    return true;
}

function disableF5(e) { 
    if ((e.which || e.keyCode) === 116) e.preventDefault(); 
}