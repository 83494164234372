/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable default-case */
import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { CallbackWrapper } from 'react-callback';
import { GridComponent, ColumnsDirective, ColumnDirective, Toolbar, ExcelExport, PdfExport, CommandColumn, Edit, Filter, Inject, Sort } from '@syncfusion/ej2-react-grids';
import { PdfViewerComponent, Toolbar as PDFVToolbar, Magnification, Navigation, LinkAnnotation, BookmarkView, ThumbnailView, Print, TextSelection, TextSearch, Annotation, FormFields, FormDesigner, Inject as PDFVInject } from '@syncfusion/ej2-react-pdfviewer';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { ProgressButtonComponent } from '@syncfusion/ej2-react-splitbuttons';
import { Browser } from '@syncfusion/ej2-base';
import * as CustomJS from '../Custom';
import { SampleBase } from './sampleBase';
import _ from 'underscore/underscore-min';
import { ToastContainer, toast, Slide, Zoom, Flip, Bounce } from 'react-toastify';
import ReactPlayer from 'react-player';
import { DashboardModal } from '@uppy/react';
import Uppy from '@uppy/core';
import XHRUpload from '@uppy/xhr-upload';
import Webcam from '@uppy/webcam';
import ImageEditor from '@uppy/image-editor';
import Italiano from '@uppy/locales/lib/it_IT';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import '@uppy/webcam/dist/style.css';
import '@uppy/image-editor/dist/style.css';
import 'react-toastify/dist/ReactToastify.css';
import $ from 'jquery';
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";

let GridData = [];

function headerTemplate(props) {
    return (<div>
        <span style={{ fontSize: '1.6vw', fontWeight: 'bolder' }}>{props.headerText}</span>
    </div>);
}

function fieldTemplate(props) {       
    let colore = props.column.field === 'NomeDocumento' ? '#133B95' : 'purple';   
    let renderDiv = (props.column.field === 'NuovoCanc' && props.Stato === 'N') ? <div style={{ paddingTop: '7px' }}><span style={{ fontSize: '2.1vw', color: 'green', fontWeight: 'bolder' }}>N</span></div> 
                    : (props.column.field === 'NuovoCanc' && props.Stato === 'D') ? <div style={{ paddingTop: '7px' }}><span style={{ fontSize: '2.1vw', color: 'darkred', fontWeight: 'bolder' }}>C</span></div> 
                    : <div><span style={{ fontSize: '2.1vw', color: colore, fontWeight: 'bolder' }}>{props[props.column.field]}</span></div>;
    return (renderDiv);    
}

export default class GridDocumentazioneIncarico extends SampleBase {
    constructor() {
        super(...arguments); 
        this.editSettings = { showDeleteConfirmDialog: false, allowAdding: true, allowDeleting: true, allowEditing: true, mode: 'Dialog'};
        this.toolbarOptions = ['Add'];
        this.sortSettings = { columns: [{ field: 'NomeDocumento', direction: 'Ascending' }] };
        this.commands = [{ type: "Edit", buttonOption: { iconCss: "fas fa-edit fa-10x", cssClass: "e-details" } },
                         { type: "Vedi Documento", buttonOption: { iconCss: "fas fa-eye fa-10x", cssClass: "e-details" } },
                         { type: "Scarica Documento", buttonOption: { iconCss: "fas fa-download fa-10x", cssClass: "e-details" } },
                         { type: "Delete", buttonOption: { iconCss: "fas fa-trash-alt fa-10x", cssClass: "e-details" } },
                         { type: "Ripristina Documento", buttonOption: { iconCss: "fas fa-undo fa-10x", cssClass: "e-details" } }];
        this.dialogAnimationSettings = { effect: 'FadeZoom' };
        this.uppy = new Uppy({ locale: Italiano, 
                               restrictions: {
                                   maxTotalFileSize: 1073741824,
                                   maxFileSize: 524288000 
                                }
                            })
            .use(XHRUpload, { method: 'POST', formData: true,  fieldName: 'documenti', bundle: true,
                              headers: { "Authorization": JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileAuthenticationPayload')).token }, 
                              endpoint: `${CustomJS.APIJfishTeamsURLProd}/api/JellyfishTeams/uploadDocumenti/JTEAMS` 
                            })
            .use(Webcam, { 
                onBeforeSnapshot: () => Promise.resolve(),
                countdown: false, 
                modes: ['video-audio', 'video-only', 'audio-only', 'picture'],
                videoConstraints: {                    
                    width: { min: 720, ideal: 1920, max: 1920 },
                    height: { min: 480, ideal: 1080, max: 1080 },
                  },
                showRecordingLength: true,
                mobileNativeCamera: Browser.isDevice,
                showVideoSourceDropdown: true,
                preferredVideoMimeType: 'video/mp4',
                preferredImageMimeType: 'image/png',
                restrictions: {}
            })
            .use(ImageEditor, {               
                quality: 0.8,
                cropperOptions: {
                  viewMode: 1,
                  background: false,
                  autoCropArea: 1,
                  responsive: true
                },
                actions: {
                  revert: true,
                  rotate: true,
                  flip: true,
                  zoomIn: true,
                  zoomOut: true,
                  cropSquare: true,
                  cropWidescreen: true,
                  cropWidescreenVertical: true
                }
              })
            .on('upload-success', (file, response) => {                               
            })
            .on('complete', (result) => {
               result.successful.forEach((elm,idx) => {
                let objDoc = {
                    Guid: elm.response.body[idx].filename.substring(0, 36),
                    GuidRiferimento: CustomJS.isNullEmpty(JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).GuidDocumento) 
                                    ? JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).GuidIncarico 
                                    : JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).GuidDocumento,
                    TipoDoc: '00002',
                    NomeDocumento: elm.response.body[idx].originalname,
                    Path: elm.response.body[idx].filename,
                    Sopralluogo: 'N',
                    CaricatoDaUtente: JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileAuthenticationPayload')).data[0].COD_ELE,
                    CaricatoDaUtenteNome: JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileAuthenticationPayload')).data[0].DESCRIZ,
                    Stato: 'N',
                    Stato_nuovo: 'N',
                    Letto: 'OD'
                };
                this.gridInstance1.dataSource.push(objDoc);
                if (objDoc.Path.substring(objDoc.Path.lastIndexOf('.')+1).toUpperCase() === 'JPEG' 
                    || objDoc.Path.substring(objDoc.Path.lastIndexOf('.')+1).toUpperCase() === 'JPG'
                    || objDoc.Path.substring(objDoc.Path.lastIndexOf('.')+1).toUpperCase() === 'PNG'
                    || objDoc.Path.substring(objDoc.Path.lastIndexOf('.')+1).toUpperCase() === 'TIF'
                    || objDoc.Path.substring(objDoc.Path.lastIndexOf('.')+1).toUpperCase() === 'TIFF'
                    || objDoc.Path.substring(objDoc.Path.lastIndexOf('.')+1).toUpperCase() === 'GIF'
                    || objDoc.Path.substring(objDoc.Path.lastIndexOf('.')+1).toUpperCase() === 'JFIF')
                    this.lightboxImages.push({ url: `${CustomJS.URLApplicazioneProd}/${CustomJS.CartellaTemporaneaUpload}/${objDoc.Path}`,
                title: objDoc.NomeDocumento});

            });                 
            this.gridInstance1.refresh();   
        })       
        this.handleClose = this.handleClose.bind(this);
        this.childKey = 0;
        this.lightboxImages = [];
        this.lightboxImage = '';
    }
    state = {
        showDialog1: false,
        showUppy: false,
        GridData2: GridData,
        showLightbox1: false,
        showLightbox2: false
    }      
    handleClose () {
        this.setState({
            showUppy: false
        })
    }
    onQueryCellInfo(args) {       
    }
    onDatabound(args) {
        for (let ind = 0; ind <= 3; ind++)
            document.getElementById('GridDocumentazioneIncarico').querySelectorAll('.e-columnheader')[0].children[ind].style.backgroundColor = 'Linen';
    }
    onRowDatabound(args) { 
        if (args.data.Stato === 'N') {
            args.row.className = 'e-row';
            args.row.bgColor = 'lightgreen';
        }    
        if (args.data.Stato === 'D') {
            args.row.className = 'e-row';
            args.row.bgColor = 'lightcoral';  
        }
        if (args.data.Stato === 'D') {
            $($(args.row).find('button')[4]).show();
            $($(args.row).find('button')[3]).hide();
        }
        else {
            $($(args.row).find('button')[4]).hide();
            $($(args.row).find('button')[3]).show();
        }
        if (args.data.Visualizzabile === 'S'
            || _.find(JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileTabellaEXT')), (obj) => { return obj.VALUE === args.data.Path.substr(args.data.Path.indexOf('.')) })?.SERVIZIO_STR4 === 'S') {
            $($(args.row).find('button')[1]).show();
        }
        else {
            $($(args.row).find('button')[1]).hide();            
        }         
    }
    onComplete(args) {
        if (args.requestType === "delete") {
            var objDoc = args.data[0];
            objDoc.Stato = 'D';
            document.getElementById('GridDocumentazioneIncarico').ej2_instances[0].dataSource.push(objDoc);
            document.getElementById('GridDocumentazioneIncarico').ej2_instances[0].refresh();
        }
    }
    toolbarClick(args) {
        switch (args.item.properties.prefixIcon) {
            case 'e-pdfexport':
                this.gridInstance.pdfExport();
                break;
            case 'e-excelexport':
                this.gridInstance.excelExport();
                break;
            case 'e-csvexport':
                this.gridInstance.csvExport();
                break;
        }
        if (args.item.id === 'GridDocumentazioneIncarico_add') {
            args.cancel = true;   
            this.setState({
                showUppy: true
            })
        }
    }
    onCommandClick(args) {
        if (args.commandColumn.buttonOption.iconCss.indexOf('fa-undo') > -1) {            
            if (!CustomJS.isNullEmpty(args.rowData.Stato_nuovo))
                args.rowData.Stato = args.rowData.Stato_nuovo;
            else
                args.rowData.Stato = 'O';
            this.gridInstance1.refresh();
        }
        if (args.commandColumn.buttonOption.iconCss.indexOf('fa-eye') > -1) {  
            let URLDocumento = args.rowData.Stato === 'N' ? `${CustomJS.URLApplicazioneProd}/${CustomJS.CartellaTemporaneaUpload}/${args.rowData.Path}`
                               : `${CustomJS.URLApplicazioneProd}/${CustomJS.CartellaDocumentazione}/${args.rowData.Path}`;
            if (args.rowData.Path.substring(args.rowData.Path.indexOf(".") + 1).toUpperCase() === "AVI" || args.rowData.Path.substring(args.rowData.Path.indexOf(".") + 1).toUpperCase() === "MP4"
                || args.rowData.Path.substring(args.rowData.Path.indexOf(".") + 1).toUpperCase() === "WMV" || args.rowData.Path.substring(args.rowData.Path.indexOf(".") + 1).toUpperCase() === "MOV"
                || args.rowData.Path.substring(args.rowData.Path.indexOf(".") + 1).toUpperCase() === "MKV" || args.rowData.Path.substring(args.rowData.Path.indexOf(".") + 1).toUpperCase() === "WEBM") {                                  
                $('#VideoPlayer2').show(); 
                this.dialogInstance1.width = '55%';
                this.dialogInstance1.height = '65%';
                this.dialogInstance1.minHeight = '65%';
                this.dialogInstance1.beforeOpen = (args) => {
                    const container = document.getElementById('defaultDialogDocIncContent');
                    const root = createRoot(container); 
                    root.unmount();
                };
                this.dialogInstance1.open = (args) => {
                    const container = document.getElementById('defaultDialogDocIncContent');
                    const root = createRoot(container); 
                    root.render(this.videoViewerRender(URLDocumento));   
                };
                this.dialogInstance1.close = (args) => {
                    const container = document.getElementById('defaultDialogDocIncContent');
                    const root = createRoot(container); 
                    root.unmount();
                };
                this.dialogInstance1.show();
            }
            else if (args.rowData.Path.substring(args.rowData.Path.indexOf(".") + 1).toUpperCase() === "PDF") {
                $('#VideoPlayer2').hide();                   
                ++this.childKey;
                this.dialogInstance1.header = `VISUALIZZAZIONE DOCUMENTO PDF`;  
                this.dialogInstance1.width = '85%';
                this.dialogInstance1.height = '90%';
                this.dialogInstance1.minHeight = '90%';
                this.dialogInstance1.close = undefined;
                this.dialogInstance1.beforeOpen = (args) => {
                    const container = document.getElementById('defaultDialogDocIncContent');
                    const root = createRoot(container); 
                    root.unmount();
                };
                this.dialogInstance1.open = (args) => {
                    const container = document.getElementById('defaultDialogDocIncContent');
                    const root = createRoot(container); 
                    root.render(<CallbackWrapper callback={() => {
                        document.getElementById('PDFViewerComp2').ej2_instances[0].enableDownload = false;
                        document.getElementById('PDFViewerComp2').ej2_instances[0].serviceUrl = `${CustomJS.URLApplicazioneProd}/api/PdfViewerFromOutside`;
                        document.getElementById('PDFViewerComp2').ej2_instances[0].ajaxRequestSettings.ajaxHeaders = [{ headerName: 'Authorization', headerValue: CustomJS.tokenAuthAPIAppTeamsBackoffice }];
                        setTimeout(() => {
                            const binDocPDF = CustomJS.getBinary(URLDocumento);
                            const base64PDF = CustomJS.base64Encode(binDocPDF);
                            document.getElementById('PDFViewerComp2').ej2_instances[0].load(`data:application/pdf;base64,${base64PDF}`, null);
                        }, 100);
                    }}>{this.PDFViewerRender()}</CallbackWrapper>); 
                };
                this.dialogInstance1.show();
            }
            else if (args.rowData.Path.substring(args.rowData.Path.lastIndexOf('.')+1).toUpperCase() === 'DOC' || args.rowData.Path.substring(args.rowData.Path.lastIndexOf('.')+1).toUpperCase() === 'DOCX'
                     || args.rowData.Path.substring(args.rowData.Path.lastIndexOf('.')+1).toUpperCase() === 'XLS' || args.rowData.Path.substring(args.rowData.Path.lastIndexOf('.')+1).toUpperCase() === 'XLSX'
                     || args.rowData.Path.substring(args.rowData.Path.lastIndexOf('.')+1).toUpperCase() === 'XLSM' || args.rowData.Path.substring(args.rowData.Path.lastIndexOf('.')+1).toUpperCase() === 'CSV') {
                    $('#VideoPlayer2').hide();                   
                    ++this.childKey;
                    this.dialogInstance1.header = `VISUALIZZAZIONE DOCUMENTO`;  
                    this.dialogInstance1.width = '75%';
                    this.dialogInstance1.height = '90%';
                    this.dialogInstance1.minHeight = '90%';
                    this.dialogInstance1.close = undefined;
                    this.dialogInstance1.beforeOpen = (args) => {
                        const container = document.getElementById('defaultDialogDocIncContent');
                        const root = createRoot(container); 
                        root.unmount();
                    };
                    this.dialogInstance1.open = (args) => {
                        const container = document.getElementById('defaultDialogDocIncContent');
                        const root = createRoot(container); 
                        root.render(<CallbackWrapper callback={() => {
                            setTimeout(() => {
                                const URLVisualGoogle = `https://docs.google.com/gview?url=${encodeURIComponent(URLDocumento)}&embedded=true`;
                                const URLVisualOffice365 = `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(URLDocumento)}`;
                                const encodedFilePath = encodeURIComponent(URLDocumento);
                                const URLVisualLocale = `http://docs.google.com/viewer?url=${encodedFilePath}&embedded=true`;
                                document.getElementById('iframeVisualDocIncarico').src = URLVisualGoogle;
                            }, 400);      
                        }}>{this.otherDocumentRender()}</CallbackWrapper>);  
                    };
                    this.dialogInstance1.show();        
            }
            else {
                this.lightboxImage = URLDocumento;
                this.setState({showLightbox2: true});
            }
        }
        if (args.commandColumn.buttonOption.iconCss.indexOf('fa-download') > -1) {
            toast.warn('Funzionalità non ancora implementata. Il rilascio verrà effettuato nel più breve tempo possibile...', {
                onClose: () => toast.dismiss(),    
                containerId: 'toastContainer3',               
                position: "bottom-right",
                autoClose: 15000,
                hideProgressBar: false,            
                closeOnClick: true, 
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true
            });
            return;

            if ($("#anchor_" + args.rowData.Guid).length === 0)
                $('<a href="" id="anchor_' + args.rowData.Guid + '" download/>').insertAfter($(args.target));
            let URLDocumento = args.rowData.Stato === 'N' ? `${CustomJS.URLApplicazioneProd}/${CustomJS.CartellaTemporaneaUpload}/${args.rowData.Path}`
                               : `${CustomJS.URLApplicazioneProd}/${CustomJS.CartellaDocumentazione}/${args.rowData.Path}`;
            $("#anchor_" + args.rowData.Guid).attr("href", URLDocumento).attr("download", args.rowData.NomeDocumento);
            sessionStorage.setItem("JellyfishTeamsMobileDownload", "true");
            document.getElementById('anchor_' + args.rowData.Guid).click();
            sessionStorage.setItem("JellyfishTeamsMobileDownload", "false");
        }
    }  
    ProgrButtCreated1() {      
        document.getElementById('btnGalleriaImmagini1').ej2_instances[0].element.addEventListener('click', () => {
            this.setState({showLightbox1: true});
        });
    }
    PDFViewerRender() {
        ++this.childKey;
        return(<React.Fragment>
                <div className="control-section card-control-section basic_card_layout">
                    <div className="e-card-resize-container">
                        <div className="card-layout">
                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">                                           
                                <div tabIndex="0" className="e-card" id="main_card">                                                
                                    <div className="e-card-content">
                                        <div className='row'>
                                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                                <PdfViewerComponent id='PDFViewerComp2' key={this.childKey} width="auto" style={{ 'height': '730px' }} 
                                                    enablePrint={true} enableDownload={true} enableAnnotation={false} enableCommentPanel={false}>
                                                <PDFVInject services={[PDFVToolbar, Magnification, Navigation, LinkAnnotation, BookmarkView, ThumbnailView, Print, TextSelection, TextSearch, Annotation, FormFields, FormDesigner]} />
                                                </PdfViewerComponent>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>);
    }
    videoViewerRender(URLVideo) {
        ++this.childKey;
        return(<React.Fragment>
                <div className="control-section card-control-section basic_card_layout">
                    <div className="e-card-resize-container">
                        <div className="card-layout">
                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">                                           
                                <div tabIndex="0" className="e-card" id="main_card">                                                
                                    <div className="e-card-content">
                                        <div className='row'>
                                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 text-center">
                                                <ReactPlayer key={this.childKey} url={URLVideo} controls={true} stopOnUnmount={true} playing={true} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>);
    }
    otherDocumentRender() {
        return(<React.Fragment>
                <div className="control-section card-control-section basic_card_layout">
                    <div className="e-card-resize-container">
                        <div className="card-layout">
                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">                                           
                                <div tabIndex="0" className="e-card" id="main_card">                                                
                                    <div className="e-card-content">
                                        <div className='row'>
                                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 text-center">
                                                <iframe id='iframeVisualDocIncarico' title='Documenti' src='' style={{width: '950px', height: '750px', border: '1px solid black'}}></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>);
    }

    render() {
        return(<div className="control-section card-control-section basic_card_layout">
                     <ToastContainer containerId='toastContainer4' transition={Zoom} style={{ fontSize: '1.3vw', color: 'midnightblue', fontWeight: 'bold', width: '30vw' }}></ToastContainer>
                     <DashboardModal uppy={this.uppy} width='40%' height='40%' closeModalOnClickOutside open={this.state.showUppy} onRequestClose={this.handleClose} plugins={['Webcam', 'ImageEditor']} />
                     <div>{this.state.showLightbox1 ? <Lightbox images={this.lightboxImages} onClose={()=>this.setState({showLightbox1: false})}/>:null}</div>                 
                     <div>{this.state.showLightbox2 ? <Lightbox image={this.lightboxImage} onClose={()=>this.setState({showLightbox2: false})}/>:null}</div>                 
                     <DialogComponent id="defaultDialogDocInc" showCloseIcon={true} target='body' animationSettings={this.dialogAnimationSettings} visible={this.state.showDialog1} 
                            ref={dialog => this.dialogInstance1 = dialog} isModal={true} height='90%' width='90%' minHeight='90%'>   
                            <div id='defaultDialogDocIncContent'></div>                         
                     </DialogComponent>
                     <div className="e-card-resize-container">
                        <div className="card-layout">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">                                
                                <div tabIndex="0" className="e-card" id="main_card">
                                    <div className="e-card-header">
                                        <div className="e-card-header-caption">
                                            <span style={{ fontSize: '2.6vw', fontWeight: 'bolder', paddingLeft: '5px', color:'#133B95'}}>DOCUMENTAZIONE INCARICO</span>
                                        </div>
                                    </div>
                                    <div className="e-card-content">  
                                        <div className="row">
                                            <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 text-end'>
                                                <ProgressButtonComponent id='btnGalleriaImmagini1' content="Galleria immagini" enableProgress duration='1000' type='button' spinSettings={{ position: 'Center' }}
                                                    animationSettings={{effect: 'SlideRight'}} cssClass="e-fill e-warning" created={this.ProgrButtCreated1.bind(this)}>
                                                </ProgressButtonComponent> 
                                            </div>
                                        </div>                                      
                                        <div className="row">
                                            <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>               
                                                <GridComponent id="GridDocumentazioneIncarico" dataSource={this.state.GridData2} ref={ grid => this.gridInstance1 = grid } toolbarClick={this.toolbarClick.bind(this)}
                                                    editSettings={this.editSettings} sortSettings={this.sortSettings} queryCellInfo={this.onQueryCellInfo.bind(this)} actionComplete={this.onComplete.bind(this)}
                                                    allowSelection={true} enableHover={false} dataBound={this.onDatabound.bind(this)} rowDataBound={this.onRowDatabound.bind(this)} commandClick={this.onCommandClick.bind(this)}
                                                    allowTextWrap={true} allowPaging={false} allowSorting={true} allowFiltering={false} enableAltRow={true} enablePersistence={false} toolbar={this.toolbarOptions}>
                                                    <ColumnsDirective>
                                                        <ColumnDirective field='Guid' visible={false} isPrimaryKey={true}></ColumnDirective>
                                                        <ColumnDirective field='NomeDocumento' headerText='Nome Documento' width='75%' headerTemplate={headerTemplate} template={fieldTemplate} textAlign='Left' clipMode="EllipsisWithTooltip"></ColumnDirective>
                                                        <ColumnDirective field='NuovoCanc' headerText="" width='5%' template={fieldTemplate} textAlign='Center' allowEditing={false}></ColumnDirective>
                                                        <ColumnDirective field='Comandi' headerText="" commands={this.commands} width='20%' textAlign='Right'></ColumnDirective>
                                                    </ColumnsDirective>
                                                    <Inject services={[Toolbar, ExcelExport, PdfExport, Filter, Edit, CommandColumn, Sort]} />
                                                </GridComponent>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        );
    }
    componentDidMount() {
        this.caricaDati();
    }
    componentWillUnmount () {
        this.uppy.close()
    }
    caricaDati() {
        const CodDiv = JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileAuthenticationPayload')).data[0].COD_DIV,
        CodSoc = JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileAuthenticationPayload')).data[0].COD_SOC,
        CodFil = JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileAuthenticationPayload')).data[0].COD_FIL,
        GuidIncarico = JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).GuidIncarico,
        GuidDocumento = CustomJS.isNullEmpty(JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).GuidDocumento) 
                        ? JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).GuidIncarico : JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).GuidDocumento;
        CustomJS.callAPI(`${CustomJS.APIJfishTeamsURLProd}/api/JellyfishTeams/caricaListeIncarico/${CodDiv}/${CodSoc}/${CodFil}/${GuidIncarico}/${GuidDocumento}/DOCSINC`, '', 'GET',
        '', JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileAuthenticationPayload')).token, retData => { 
            if (retData.response === 'KO') {
                let messaggio = (retData.msg === 'UtenteNonAutorizzato') ? CustomJS.messaggioUtenteNonAutorizzato : (retData.msg === 'SessioneScaduta') ? CustomJS.messaggioSessioneScaduta : retData.msg;
                toast.warn(messaggio, {
                    onClose: () => toast.dismiss(),   
                    containerId: 'toastContainer4',                
                    position: "bottom-right",
                    autoClose: 15000,
                    hideProgressBar: false,            
                    closeOnClick: true, 
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true
                });
                return;
            }               
            GridData = [];
            retData.forEach(elm => {           
                GridData.push({ Guid: elm.GUID, TipoDoc: elm.TIPO_DOCUMENTO, GuidRiferimento: elm.GUID_RIFERIMENTO, NomeDocumento: elm.NOME_DOCUMENTO, Stato: 'O',
                                Visualizzabile: elm.VISUALIZZABILE, Path: elm.PATH_DOCUMENTO, CaricatoDaUtente: elm.CARICATO_DA_UTENTE, CaricatoDaUtenteNome: elm.NOMINATIVO,
                                Sopralluogo: elm.SOPRALLUOGO, DataOraCaricamento: elm.DATA_ORA_CARICAMENTO, Letto: elm.LETTO});
                if (elm.PATH_DOCUMENTO.substring(elm.PATH_DOCUMENTO.indexOf(".") + 1).toUpperCase() !== "AVI" && elm.PATH_DOCUMENTO.substring(elm.PATH_DOCUMENTO.indexOf(".") + 1).toUpperCase() !== "MP4"
                    && elm.PATH_DOCUMENTO.substring(elm.PATH_DOCUMENTO.indexOf(".") + 1).toUpperCase() !== "WMV" && elm.PATH_DOCUMENTO.substring(elm.PATH_DOCUMENTO.indexOf(".") + 1).toUpperCase() !== "MOV"
                    && elm.PATH_DOCUMENTO.substring(elm.PATH_DOCUMENTO.indexOf(".") + 1).toUpperCase() !== "MKV" && elm.PATH_DOCUMENTO.substring(elm.PATH_DOCUMENTO.indexOf(".") + 1).toUpperCase() !== "WEBM"
                    && elm.PATH_DOCUMENTO.substring(elm.PATH_DOCUMENTO.indexOf(".") + 1).toUpperCase() !== "PDF") {
                    this.lightboxImages.push({ url: `${CustomJS.URLApplicazioneProd}/${CustomJS.CartellaDocumentazione}/${elm.PATH_DOCUMENTO}`, title: elm.NOME_DOCUMENTO});
                }
            });
            this.setState({ GridData2: GridData});
        }, error => {
            let messaggio = _.isString(error) && error.indexOf('Not Found') > -1 ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
            toast.warn(messaggio, {
                onClose: () => toast.dismiss(), 
                containerId: 'toastContainer4',                  
                position: "bottom-right",
                autoClose: 15000,
                hideProgressBar: false,            
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true});        
        }, true, false)
    }
}