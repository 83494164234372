import * as React from 'react';
import { ProgressButtonComponent } from '@syncfusion/ej2-react-splitbuttons';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import SignaturePad from 'react-signature-canvas';
import * as CustomJS from '../Custom';

export default class PaginaFirma extends React.Component {
    constructor() {
        super(...arguments); 
        this.dialogAnimationSettings = { effect: 'FadeZoom' }; 
        this.dialogButtons1 = [{
            click: () => {
                this.dialogInstance1.hide();    
                setTimeout(() => {                         
                    let incObj = JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico'));
                    incObj.FirmaFineLavoriCliente = this.sigPad.getTrimmedCanvas().toDataURL('image/png');    
                    sessionStorage.setItem('JellyfishTeamsMobileDatiIncarico', JSON.stringify(incObj)); 
                    document.getElementById('signContainer').style.border = '4px solid green'; 
                    this.sigPad.clear();  
                    this.sigPad.fromDataURL(JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).FirmaFineLavoriCliente);   
                    this.sigPad.clearOnResize = false;
                    document.getElementById('signContainer').classList.add('disableSignPad');
                    document.getElementById('signWrapper').classList.add('disableSignWrapper');
                    document.getElementById('divBtnRipetiFirma').style.removeProperty('display');
                    document.getElementById('divBtnSalva').style.display = 'none';
                    document.getElementById('divBtnPulisci').style.display = 'none';
                    document.getElementById('divBtnRicaricaFirma').style.removeProperty('display');
                    document.getElementById('Stato').ej2_instances[0].readonly = true;
               }, 100);            
            },
            buttonModel: {
                content: 'CONFERMA',
                cssClass: 'e-primary',
                isPrimary: true
            }
        },
        {
            click: () => {
                this.dialogInstance1.hide();
            },
            buttonModel: {
                content: 'ANNULLA',
            }
        }];
    }
    state = {
        showDialog1: false
    }       
    ProgrButtCreated1() {
        document.getElementById('btnSalva').ej2_instances[0].element.addEventListener('click', () => {
            if (this.sigPad.isEmpty()) {
                document.getElementById('signContainer').style.border = '4px solid red';
                return;
            } 
            this.dialogInstance1.show();                    
        });
    }
    ProgrButtCreated2() {
        document.getElementById('btnPulisci').ej2_instances[0].element.addEventListener('click', () => {
            this.sigPad.clear();
        });
    }
    ProgrButtCreated3() {
        document.getElementById('btnRipetiFirma').ej2_instances[0].element.addEventListener('click', () => {
            document.getElementById('signContainer').style.border = '2px solid black';               
            this.sigPad.clearOnResize = true;
            this.sigPad.clear();
            document.getElementById('signContainer').classList.remove('disableSignPad');
            document.getElementById('signWrapper').classList.remove('disableSignWrapper');
            document.getElementById('divBtnRipetiFirma').style.display = 'none';
            document.getElementById('divBtnSalva').style.removeProperty('display');
            document.getElementById('divBtnPulisci').style.removeProperty('display');
            document.getElementById('divBtnRicaricaFirma').style.display = 'none';
            document.getElementById('Stato').ej2_instances[0].readonly = false;
            document.getElementById('divBtnAccettazione').style.removeProperty('display');
        });
    }
    ProgrButtCreated4() {
        document.getElementById('btnRicaricaFirma').ej2_instances[0].element.addEventListener('click', () => {
            document.getElementById('signContainer').style.border = '2px solid black';               
            this.sigPad.clearOnResize = false;
            this.sigPad.clear();
            this.sigPad.fromDataURL(JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).FirmaFineLavoriCliente);
            this.resizeCanvas.bind(this);
        });
    }
    onBeginSign() {        
        document.getElementById('signContainer').style.border = '2px solid black';
    }    
    resizeCanvas = () => {
        // When zoomed out to less than 100%, for some very strange reason,
        // some browsers report devicePixelRatio as less than 1
        // and only part of the canvas is cleared then.
        let ratio =  Math.max(window.devicePixelRatio || 1, 1);
        this.sigPad.getCanvas().width = this.sigPad.getCanvas().offsetWidth * ratio;
        this.sigPad.getCanvas().height = this.sigPad.getCanvas().offsetHeight * ratio;
        this.sigPad.getCanvas().getContext("2d").scale(ratio, ratio);
    } 
    render () {        
        return(<div className="e-card-resize-container">   
                    <DialogComponent id="defaultDialogFirma" showCloseIcon={true} target='body' animationSettings={this.dialogAnimationSettings} visible={this.state.showDialog1} width='35%'
                                     ref={dialog => this.dialogInstance1 = dialog} header='Attenzione!!' buttons={this.dialogButtons1} height='35vh' minHeight='35vh' isModal={true} 
                                     content={CustomJS.messaggioConfermaFirmaCliente}></DialogComponent>                 
                    <div className="card-layout">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">                                
                            <div tabIndex="0" className="e-card" id="main_card">
                                <div className="e-card-header">
                                    <div className="e-card-header-caption">
                                        <span style={{ fontSize: '3.0vw', fontWeight: 'bolder', paddingLeft: '5px', color:'#133B95'}}>FIRMA CLIENTE PER FINE LAVORI</span>
                                    </div>
                                </div>
                                <div className="e-card-content">        
                                    <div id='signWrapper' className='sigWrapper'>                                        
                                        <div id='signContainer' className='sigContainer'>
                                            <SignaturePad canvasProps={{className: 'sigPad'}}
                                                    ref={(ref) => { this.sigPad = ref }} onBegin={this.onBeginSign.bind(this)} />
                                        </div>
                                        <div className="row">
                                            <div id='divBtnSalva' className='col-lg-1 col-md-2 col-sm-3 col-xs-3'>
                                                <ProgressButtonComponent id='btnSalva' content="Salva" enableProgress duration='700' spinSettings={{ position: 'Center' }} 
                                                    animationSettings={{effect: 'SlideRight'}} created={this.ProgrButtCreated1.bind(this)} cssClass="e-success"></ProgressButtonComponent> 
                                            </div>
                                            <div id='divBtnPulisci' className='col-lg-1 col-md-2 col-sm-3 col-xs-3'>
                                                <ProgressButtonComponent id='btnPulisci' content="Pulisci" enableProgress duration='700' spinSettings={{ position: 'Center' }} 
                                                    animationSettings={{effect: 'SlideRight'}} created={this.ProgrButtCreated2.bind(this)} cssClass="e-danger"></ProgressButtonComponent>
                                            </div>
                                            <div id='divBtnRipetiFirma' className='col-lg-1 col-md-2 col-sm-3 col-xs-3'>
                                                <ProgressButtonComponent id='btnRipetiFirma' content="Ripeti Firma" enableProgress duration='700' spinSettings={{ position: 'Center' }} 
                                                    animationSettings={{effect: 'SlideRight'}} created={this.ProgrButtCreated3.bind(this)} cssClass="e-info"></ProgressButtonComponent>
                                            </div>
                                            <div id='divBtnRicaricaFirma' className='col-lg-1 offset-lg-9 col-md-2 offset-md-7 col-sm-3 offset-sm-5 col-xs-3 offset-xs-5'>
                                                <ProgressButtonComponent id='btnRicaricaFirma' content="Ricarica Firma" enableProgress duration='700' spinSettings={{ position: 'Center' }} 
                                                    animationSettings={{effect: 'SlideRight'}} created={this.ProgrButtCreated4.bind(this)} cssClass="e-warning"></ProgressButtonComponent>
                                            </div>
                                       </div>                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
    }   
    componentDidMount() {
        if (!CustomJS.isNullEmpty(JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).FirmaFineLavoriCliente)) {
            document.getElementById('signContainer').style.border = '4px solid green'; 
            this.sigPad.fromDataURL(JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileDatiIncarico')).FirmaFineLavoriCliente);   
            this.sigPad.clearOnResize = false;
            document.getElementById('signContainer').classList.add('disableSignPad');
            document.getElementById('signWrapper').classList.add('disableSignWrapper');
            document.getElementById('divBtnRipetiFirma').style.removeProperty('display');
            document.getElementById('divBtnSalva').style.display = 'none';
            document.getElementById('divBtnPulisci').style.display = 'none';
            document.getElementById('divBtnRicaricaFirma').style.removeProperty('display');
        }
        else {
            document.getElementById('divBtnRipetiFirma').style.display = 'none';
            document.getElementById('divBtnRicaricaFirma').style.display = 'none';
        }       
        this.resizeCanvas.bind(this);
    }
}