import React from 'react'; 
import { ReactInternetSpeedMeter } from 'react-internet-meter';
import 'react-internet-meter/dist/index.css';
 
function InternetSpeedMeter() {
    const [checkSpeed, SetCheckSpeed] = React.useState(
        "Finding internet speed."
    );
    return (
        <div>
            {/* <h2 className="mb-3">React Find Internet Speed Example</h2> */}
            <ReactInternetSpeedMeter  
                txtSubHeading="Connessione internet lenta (al di sotto dei 10 Mbit/s)...le operazioni da svolgere potrebbero essere compromesse o comunque con tempi di risposta lunghi"
                outputType="alert"
                customClassName='notifNetDown'
                txtMainHeading="ATTENZIONE..." 
                pingInterval={5000} // milliseconds 
                thresholdUnit='megabyte' // "byte" , "kilobyte", "megabyte" 
                threshold={1.25}
                imageUrl="https://tech.jfish.it/Geolocalizzata.jpg"
                downloadSize="3652464"  //bytes
                callbackFunctionOnNetworkDown={(speed)=> { 
                    console.log(`Internet speed is down ${speed*8} Mbit/s`);
                }}
                callbackFunctionOnNetworkTest={(speed)=> { SetCheckSpeed(speed) }} />
                {/* <div className="card-body mt-4">
                    <span className="display-1">{checkSpeed} MB/s</span>
                </div> */}
        </div>);
} 

export default InternetSpeedMeter;