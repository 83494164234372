/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';
import { createRoot } from 'react-dom/client';
import { CallbackWrapper } from 'react-callback';
import { MenuComponent, SidebarComponent } from '@syncfusion/ej2-react-navigations';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { SampleBase } from './sampleBase';
import SituazioneIncarichi from './SituazioneIncarichi';
import CalendarioInterventi from './CalendarioInterventi';
import PaginaHome from './PaginaHome';
import PaginaLogin from './PaginaLogin';
import LogoDigitalPraesidium from '../assets/LogoDigitalPraesidium.png';
import * as CustomJS from '../Custom';
import LogoJellyfishNav from '../assets/JFTNavbar4.png';

export class NavMenu extends SampleBase {    

    constructor(props) {
        super(...arguments);         
        this.toggleNavbar = this.toggleNavbar.bind(this);    
        this.dataList = [
            { text: 'Home' },
            { text: 'About' },
            { text: 'Careers' },
            { text: 'FAQs' },
            { text: 'Blog' },
            { text: 'Uses' },
            { text: 'Contact' }
        ];
        this.fields = { tooltip: 'text' };
        this.menuItems = [
             {id: "id1", text: 'Pagina Principale', iconCss: "fas fa-home",  pagina: "Home" },
             {id: "id2", text: 'Situazione Incarichi', iconCss: "fas fa-toolbox",  pagina: "SituazioneIncarichi" },
             {id: "id3", text: 'Uscita', iconCss: "fas fa-sign-out-alt",  pagina: 'Login' },
        ];
        this.menuItemsSQD = [
            {id: "id1", text: 'Pagina Principale', iconCss: "fas fa-home",  pagina: "Home" },
            {id: "id2", text: 'Situazione Incarichi', iconCss: "fas fa-toolbox",  pagina: "SituazioneIncarichi" },
            {id: "id3", text: 'Calendario Interventi', iconCss: "fas fa-calendar-alt",  pagina: "CalendarioInterventi" },
            {id: "id4", text: 'Uscita', iconCss: "fas fa-sign-out-alt",  pagina: 'Login' },
        ];
        this.childKey = 0; 
        this.rootContainer = null;
        this.container = null;
        this.voceMenu = 0;
    }
    state = {
        collapsed: true,
        showDialog1: false
    }    
    toggleNavbar () {
        this.setState({
          collapsed: !this.state.collapsed
        });
    }
    cambiaLinguaApplicativo(lingua) {

    } 
    openClick() {
        this.sidebarobj.show();
    }    
    onSelectMenu(args) {
        this.sidebarobj.hide();  
        let componente, divContainer = '';
        ++this.childKey;
        switch (args.item.pagina) {
            case 'Home':
                if (this.voceMenu === 1)
                    return;
                if (document.getElementById('defaultDialogSitInc'))
                    document.getElementById('defaultDialogSitInc').ej2_instances[0].destroy();
                if (document.getElementById('defaultDialogDettInc'))
                    document.getElementById('defaultDialogDettInc').ej2_instances[0].destroy();
                if (document.getElementById('defaultDialogDettInc2'))
                    document.getElementById('defaultDialogDettInc2').ej2_instances[0].destroy();
                if (document.getElementById('defaultDialogDettSoprall'))
                    document.getElementById('defaultDialogDettSoprall').ej2_instances[0].destroy();
                componente = <PaginaHome key={this.childKey}></PaginaHome>;
                divContainer = 'contenutoDinamico2';
                this.voceMenu = 1
                break;
            case 'SituazioneIncarichi':
                if (this.voceMenu === 2 || sessionStorage.getItem('JellyfishTeamsMobileArrivoDaCalend') === 'true')
                    return;
                componente = <SituazioneIncarichi key={this.childKey}></SituazioneIncarichi>;
                divContainer = 'contenutoDinamico2'; 
                this.voceMenu = 2             
                break;
            case 'CalendarioInterventi':
                if (this.voceMenu === 3 && (CustomJS.isNullEmpty(sessionStorage.getItem('JellyfishTeamsMobileArrivoDaCalend')) || sessionStorage.getItem('JellyfishTeamsMobileArrivoDaCalend') === 'false'))
                    return;
                if (document.getElementById('defaultDialogSitInc'))
                    document.getElementById('defaultDialogSitInc').ej2_instances[0].destroy();
                if (document.getElementById('defaultDialogDettInc'))
                    document.getElementById('defaultDialogDettInc').ej2_instances[0].destroy();
                if (document.getElementById('defaultDialogDettInc2'))
                    document.getElementById('defaultDialogDettInc2').ej2_instances[0].destroy();
                if (document.getElementById('defaultDialogDettSoprall'))
                    document.getElementById('defaultDialogDettSoprall').ej2_instances[0].destroy();
                componente = <CalendarioInterventi key={this.childKey}></CalendarioInterventi>;
                divContainer = 'contenutoDinamico2'; 
                this.voceMenu = 3             
                break;  
            case 'Login':
                //sessionStorage.removeItem("JellyfishTeamsMobileSessionTimeout")
                sessionStorage.removeItem('JellyfishTeamsMobileAuthenticationPayload');
                sessionStorage.removeItem('JellyfishTeamsMobileFiltroStatoSituazioneIncarichi');
                componente = <PaginaLogin key={this.childKey}></PaginaLogin>;
                divContainer = 'contenutoDinamico1';
                this.voceMenu = 0;
                break;
            default:              
                break;
        }           
        this.container = document.getElementById(divContainer);
        this.rootContainer = createRoot(this.container); 
        this.rootContainer.render(<CallbackWrapper callback={() => {
            if (divContainer === 'contenutoDinamico1')
                window.location.reload();
        }}>{componente}</CallbackWrapper>);                
    }
    onCreatedSideBar(args) {        
        this.element.style.visibility = "visible";
    }

    render() {
        let tipoMenu = JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileAuthenticationPayload')).data[0].TIPO_UTENTE === 'SQD' ? this.menuItemsSQD : this.menuItems;
        return (            
            <React.Fragment>   
                <div className="header-section" id='header'>
                    <div className="row">    
                        <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1 text-start">                            
                            <span id="hamburger" className="fas fa-bars fa-2x icon list" style={{ paddingLeft: '25px' }} onClick={this.openClick.bind(this)} ></span>                           
                        </div>        
                        <div className="col-xs-2 col-sm-2 col-md-1 col-lg-1 text-start">                            
                            <img src={LogoJellyfishNav} style={{ maxWidth: '160%', height: 'auto', marginLeft: '-50%' }} />
                        </div>
                        <div id='titoloPagina' className="col-xs-6 col-sm-6 col-md-6 col-lg-6 text-center ms-2" style={{ paddingTop: '-5px' }}>
                             <span style={{ fontSize: '2.3vw', fontWeight: 'bolder', color:'#133B95' }}>Jellyfish Teams Mobile</span>
                        </div> 
                        <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 text-end ms-2" id='tempoSessione'>
                            <span >Tempo rimanente scadenza sessione :</span><br></br>
                            <span id="tempoRimanenteNav" ></span>
                        </div>
                        <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1 text-end">                            
                            <img src={LogoDigitalPraesidium} height="auto" width="140vw"/>
                        </div>             
                    </div>
                    <SidebarComponent id='sidebar-menu' ref={Sidebar => this.sidebarobj = Sidebar} created={this.onCreatedSideBar} showBackdrop={true} closeOnDocumentClick={true} type='Over' width="220px">                        
                        <div className="title-header">
                            <div className="main-menu">
                                <div>
                                    <div id="listcontainer">
                                        <MenuComponent id='main-menubar' orientation='Vertical' items={tipoMenu} cssClass='dock-menu' select={this.onSelectMenu.bind(this)}></MenuComponent>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SidebarComponent>
                </div>
            </React.Fragment>
        );
    }
    componentDidMount() {
        this.voceMenu = 2;
    }
}