/* eslint-disable no-extend-native */
/* eslint-disable default-case */
import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { SampleBase } from './sampleBase';
import { closest, isNullOrUndefined } from '@syncfusion/ej2-base';
import { ScheduleComponent, Day, Week, WorkWeek, Month, Year, TimelineViews, TimelineMonth, TimelineYear, ViewsDirective, ViewDirective, ResourcesDirective, ResourceDirective, Inject, Resize, DragAndDrop, Agenda, Print, ExcelExport, ICalendarImport, ICalendarExport, Timezone } from '@syncfusion/ej2-react-schedule';
import { ContextMenuComponent } from '@syncfusion/ej2-react-navigations';
import { RadioButtonComponent } from '@syncfusion/ej2-react-buttons';
import { ProgressButtonComponent } from '@syncfusion/ej2-react-splitbuttons';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { DateRangePickerComponent } from '@syncfusion/ej2-react-calendars';
import DettaglioIncarico from './DettaglioIncarico';
import * as CustomJS from '../Custom';
import BlockUi from '@availity/block-ui';
import { ToastContainer, toast, Slide, Zoom, Flip, Bounce } from 'react-toastify';
import { random, isUndefined } from 'underscore';
import $ from 'jquery';
import _ from 'underscore/underscore-min';
import moment from 'moment';

String.prototype.convertToRGB = function () {
    var aRgbHex = this.match(/.{1,2}/g);
    var aRgb = [
        parseInt(aRgbHex[0], 16),
        parseInt(aRgbHex[1], 16),
        parseInt(aRgbHex[2], 16)
    ];
    return aRgb;
};

export default class CalendarioInterventi extends SampleBase {
    constructor() {
        super(...arguments);  
        this.childKey = 0;
        this.toggleBlocking = this.toggleBlocking.bind(this);        
        this.ddlFields = { text: 'Text', value: 'Value' };
        this.dsTipoIncarichi = [ { Value: 'APERTI', Text: 'Solo Aperti'}, { Value: 'TUTTI', Text: 'Tutti'} ];
        this.menuItems = [
            { text: 'Crea Incarico', iconCss: 'e-icons e-plus', id: 'CreaInc' }
        ];
        this.selectedTarget = null;
        this.listeEventiGoogle = [];
        this.tuttiInterventiGenerali = [];
        this.interventiGeneraliAperti = [];
        this.tuttiInterventiAddetti = [];
        this.interventiAddettiAperti = [];
    }
    state = {
        dsCalendGoogle: [], 
        blocking: false,
        dataCorrente: CustomJS.isNullEmpty(sessionStorage.getItem("JellyfishTeamsMobileActiveCellsData")) ? new Date() : new Date(sessionStorage.getItem("JellyfishTeamsMobileActiveCellsData")),
        vistaCorrente: CustomJS.isNullEmpty(sessionStorage.getItem("JellyfishTeamsMobileCurrentView")) ? 'WorkWeek' : sessionStorage.getItem("JellyfishTeamsMobileCurrentView")
    }
    toggleBlocking() {        
        this.setState({blocking: !this.state.blocking});
    }  
    onChangeTipoCalend(args) {
        if (CustomJS.isNullEmpty(args)) {
            sessionStorage.setItem("JellyfishTeamsMobileCalendarioScelto", 'calendAddetti');
            args = { value: 'CPIAN' };
        }
        if (args.value === 'CGENE') {
            sessionStorage.setItem("JellyfishTeamsMobileCalendarioScelto", 'calendGenerale');
            document.getElementById('CalendarioGenerale').style.removeProperty('display');
            document.getElementById('CalendarioAddetti').style.display = 'none';
            document.getElementById('CalendarioGoogle').style.display = 'none';
            document.getElementById('divCalendariGoogle').style.display = 'none';
            document.getElementById('divDateCalendario').style.removeProperty('display');
            document.getElementById('divTipoIncarichi').style.removeProperty('display');
            document.getElementById('titoloCalendInterv').innerHTML = 'CALENDARIO GENERALE INTERVENTI';
            document.getElementById('CalendarioInterventiGenerale').ej2_instances[0].refreshEvents();
        }
        else if (args.value === 'CPIAN') {
            sessionStorage.setItem("JellyfishTeamsMobileCalendarioScelto", 'calendAddetti');
            document.getElementById('CalendarioGenerale').style.display = 'none';
            document.getElementById('CalendarioAddetti').style.removeProperty('display');
            document.getElementById('CalendarioGoogle').style.display = 'none';
            document.getElementById('divCalendariGoogle').style.display = 'none';
            document.getElementById('divDateCalendario').style.removeProperty('display');
            document.getElementById('divTipoIncarichi').style.removeProperty('display');
            document.getElementById('titoloCalendInterv').innerHTML = 'CALENDARIO ADDETTI';
            document.getElementById('CalendarioInterventiAddetti').ej2_instances[0].refreshEvents();
        }
        else if (args.value === 'CGOOG') {
            sessionStorage.setItem("JellyfishTeamsMobileCalendarioScelto", 'calendGoogle');
            sessionStorage.setItem("JellyfishTeamsMobileCalendarioGoogleScelto", CustomJS.isNullEmpty(document.getElementById('ddlCalendariGoogle').ej2_instances[0].value) ? document.getElementById('ddlCalendariGoogle').ej2_instances[0].value : "");
            document.getElementById('CalendarioGenerale').style.display = 'none';
            document.getElementById('CalendarioAddetti').style.display = 'none';
            document.getElementById('CalendarioGoogle').style.removeProperty('display');
            document.getElementById('divCalendariGoogle').style.removeProperty('display');
            document.getElementById('divDateCalendario').style.display = 'none';
            document.getElementById('divTipoIncarichi').style.display = 'none';
            document.getElementById('titoloCalendInterv').innerHTML = 'CALENDARIO GOOGLE';
            document.getElementById('CalendarioEventiGoogle').ej2_instances[0].refreshEvents();
        }
    }
    onChangeCalendGoogle(args) {
        if (args.value !== 'ALL')
            document.getElementById('CalendarioEventiGoogle').ej2_instances[0].eventSettings.dataSource = this.listeEventiGoogle[parseInt(args.value)];
        else {
            let tuttiCalendariGoogle = [];
            this.listeEventiGoogle.forEach(elm => {
                tuttiCalendariGoogle = _.union(tuttiCalendariGoogle, elm);
            });
            document.getElementById('CalendarioEventiGoogle').ej2_instances[0].eventSettings.dataSource = tuttiCalendariGoogle;
        }
        document.getElementById('CalendarioEventiGoogle').ej2_instances[0].refreshEvents();
    }
    onChangeTipoIncarichi(args) {
        this.scheduleObj1.eventSettings.dataSource = args.value === 'APERTI' ? this.interventiGeneraliAperti : this.tuttiInterventiGenerali;
        this.scheduleObj2.eventSettings.dataSource = args.value === 'APERTI' ? this.interventiAddettiAperti : this.tuttiInterventiAddetti;
    }
    onEventRendered(args) {
        args.element.style.backgroundColor = args.data.Colore;
        let arrRGB = args.data.Colore.substring(1).convertToRGB();
        if ((arrRGB[0] * 0.299 + arrRGB[1] * 0.587 + arrRGB[2] * 0.114) > 186)
            args.element.style.color = 'Purple';
        else
            args.element.style.color = 'WhiteSmoke';
    }
    onEventRendered2(args) {
        if (!CustomJS.isNullEmpty(_.find(this.resourceCollection[0].dataSource, elm => { return elm.IdAddetto === args.data.IdAddetto; }).ColoreAddetto)) {
            let arrRGB = _.find(this.resourceCollection[0].dataSource, elm => { return elm.IdAddetto === args.data.IdAddetto; }).ColoreAddetto.substring(1).convertToRGB(); if ((arrRGB[0] * 0.299 + arrRGB[1] * 0.587 + arrRGB[2] * 0.114) > 186)
                args.element.style.color = 'Purple';
            else
                args.element.style.color = 'WhiteSmoke';
        }
        if (!CustomJS.isNullEmpty(args.data.Guid_Evento_PostIt))
            $(args.element).append("<div class='labelPostItObliqua'>POST-IT</div>");
    }
    onEventRendered3(args) {
        if (!CustomJS.isNullEmpty(args.data.Colore)) {
            args.element.style.background = args.data.Colore;
            let arrRGB = args.data.Colore.substring(1).convertToRGB(); 
            if ((arrRGB[0] * 0.299 + arrRGB[1] * 0.587 + arrRGB[2] * 0.114) > 186)
                args.element.style.color = 'Purple';
            else
                args.element.style.color = 'WhiteSmoke';
        }
        if (!CustomJS.isNullEmpty(args.data.Guid_Evento_PostIt))
            $(args.element).append("<div class='labelPostItObliqua'>POST-IT</div>");
    }
    onPopupOpen(args) {
        if (args.type === 'QuickInfo') {
            if (!CustomJS.isNullEmpty(_.find(this.resourceCollection[0].dataSource, elm => { return elm.IdAddetto === args.data.IdAddetto; }).ColoreAddetto)) {
                let arrRGB = _.find(this.resourceCollection[0].dataSource, elm => { return elm.IdAddetto === args.data.IdAddetto; }).ColoreAddetto.substring(1).convertToRGB();
                if ((arrRGB[0] * 0.299 + arrRGB[1] * 0.587 + arrRGB[2] * 0.114) > 186)
                    $('.e-subject.e-text-ellipsis').css('color', 'Purple');
                else
                    $('.e-subject.e-text-ellipsis').css('color', 'WhiteSmoke');
            }
        }
    }
    onPopupOpen2(args) {
        if (args.type === 'QuickInfo') {
            let arrRGB = args.data.Colore.substring(1).convertToRGB();
            if ((arrRGB[0] * 0.299 + arrRGB[1] * 0.587 + arrRGB[2] * 0.114) > 186)
                $('.e-subject.e-text-ellipsis').css('color', 'Purple');
            else
                $('.e-subject.e-text-ellipsis').css('color', 'WhiteSmoke');
        }
    }
    ProgrButtCreated1() {      
        document.getElementById('btnAggiornaCalend').ej2_instances[0].element.addEventListener('click', () => {
            //this.onChangeTipoCalend();
            this.caricaDati();
        });
    }
    onContextMenuBeforeOpen(args) {
        var targetElement = args.event.target;
        if (closest(targetElement, '.e-contextmenu')) {
            return;
        }
        this.selectedTarget = closest(targetElement, '.e-appointment,.e-work-cells,' +
                         '.e-vertical-view .e-date-header-wrap .e-all-day-cells,.e-vertical-view .e-date-header-wrap .e-header-cells');
        if (isNullOrUndefined(this.selectedTarget)) {
            args.cancel = true;
            return;
        }
        if (this.selectedTarget.classList.contains('e-appointment')) {
            args.cancel = true;
            return;
        }
    }
    onActionCompleteIntervGenerale(args) {
        if (args.requestType === "viewNavigate" || args.requestType === "dateNavigate" ){
            sessionStorage.setItem("JellyfishTeamsMobileCurrentView", this.currentView);
            let salvadata = ''
            if(this.currentView === 'Month')
                salvadata = this.activeView.monthDates.start;
            else
                salvadata = this.activeView.renderDates[0];
            sessionStorage.setItem("JellyfishTeamsMobileActiveCellsData", salvadata);
            
            setTimeout(() => {
                $('.e-all-day-appointment-section.e-icons.e-appointment-expand').click();
            }, 30)
        }
    }
    onActionCompleteIntervAddetti(args) {
        if ((args.requestType === "viewNavigate" || args.requestType === "dateNavigate") && !CustomJS.isNullEmpty(args.event)) {
            sessionStorage.setItem("JellyfishTeamsMobileCurrentView", this.currentView);
            let salvadata = ''
            if(this.currentView === 'Month')
                salvadata = this.activeView.monthDates.start;
            else
                salvadata = this.activeView.renderDates[0];
            sessionStorage.setItem("JellyfishTeamsMobileActiveCellsData", salvadata);
            setTimeout(() => {
                $('.e-all-day-appointment-section.e-icons.e-appointment-expand').click();
            }, 30);
        }    
    }
    onActionCompleteEventiGoogle(args) {
        if (args.requestType === "viewNavigate" || args.requestType === "dateNavigate") {
            sessionStorage.setItem("JellyfishTeamsMobileCurrentView", this.currentView);
            let salvadata = ''
            if (this.currentView === 'Month')
                salvadata = this.activeView.monthDates.start;
            else
                salvadata = this.activeView.renderDates[0];
            sessionStorage.setItem("JellyfishTeamsMobileActiveCellsData", salvadata);
            setTimeout(() => {
                $('.e-all-day-appointment-section.e-icons.e-appointment-expand').click();
            }, 30);
        } 
    }
    onMenuItemSelect1(args) {
        switch (args.item.id) {
            case 'CreaInc':
                let selectedCells = this.scheduleObj1.getSelectedElements();
                let activeCellsData = this.scheduleObj1.getCellDetails(selectedCells.length > 0 ? selectedCells : this.selectedTarget);
                sessionStorage.setItem('JellyfishTeamsMobileDatiIncarico', JSON.stringify({ GuidIncarico: 'NEW', GuidDocumento: null, NotaIncarico: '', Nazione: 'ITA', Stato: '00007', DataInizioLavori: moment(activeCellsData.startTime).format('YYYY-MM-DDTHH:mm:ss') }));
                sessionStorage.setItem('JellyfishTeamsMobileArrivoDaCalend', 'true');
                ++this.childKey;
                const container = document.getElementById('contenutoDinamico2');
                const root = createRoot(container); 
                root.render(<DettaglioIncarico key={this.childKey}></DettaglioIncarico>); 
            break;
        }
    }
    onMenuItemSelect2(args) {
        switch (args.item.id) {
            case 'CreaInc':
                let selectedCells = this.scheduleObj2.getSelectedElements();
                let activeCellsData = this.scheduleObj2.getCellDetails(selectedCells.length > 0 ? selectedCells : this.selectedTarget);
                sessionStorage.setItem('JellyfishTeamsMobileDatiIncarico', JSON.stringify({ GuidIncarico: 'NEW', GuidDocumento: null, NotaIncarico: '', Nazione: 'ITA', Stato: '00007', DataInizioLavori: moment(activeCellsData.startTime).format('YYYY-MM-DDTHH:mm:ss') }));
                sessionStorage.setItem('JellyfishTeamsMobileArrivoDaCalend', 'true');
                ++this.childKey;
                const container = document.getElementById('contenutoDinamico2');
                const root = createRoot(container); 
                root.render(<DettaglioIncarico key={this.childKey}></DettaglioIncarico>); 
            break;
        }
    }
    onMenuItemSelect3(args) {
        switch (args.item.id) {
            case 'CreaInc':
                let selectedCells = this.scheduleObj3.getSelectedElements();
                let activeCellsData = this.scheduleObj3.getCellDetails(selectedCells.length > 0 ? selectedCells : this.selectedTarget);
                sessionStorage.setItem('JellyfishTeamsMobileDatiIncarico', JSON.stringify({ GuidIncarico: 'NEW', GuidDocumento: null, NotaIncarico: '', Nazione: 'ITA', Stato: '00007', DataInizioLavori: moment(activeCellsData.startTime).format('YYYY-MM-DDTHH:mm:ss') }));
                sessionStorage.setItem('JellyfishTeamsMobileArrivoDaCalend', 'true');
                ++this.childKey;
                const container = document.getElementById('contenutoDinamico2');
                const root = createRoot(container); 
                root.render(<DettaglioIncarico key={this.childKey}></DettaglioIncarico>); 
            break;
        }
    }

    render() {
        return(<React.Fragment>
                    <BlockUi id='blockUICalendari' blocking={this.state.blocking} keepInView={true} className='d-block' loader={CustomJS.loaderSpinners[random(CustomJS.loaderSpinners.length - 1)]}>                            
                        <ToastContainer containerId='toastContainer6' transition={Zoom} style={{ fontSize: '1.3vw', color: 'midnightblue', fontWeight: 'bold', width: '30vw' }}></ToastContainer>
                        <div className="control-section card-control-section basic_card_layout">
                            <div className="e-card-resize-container">
                                <div className="card-layout">
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">                                
                                        <div tabIndex="0" className="e-card" id="main_card">
                                            <div className="e-card-header">
                                                <div className="e-card-header-caption">
                                                    <div className="row">
                                                        <div className='col-lg-3 col-md-3 col-sm-3 col-xs-12' style={{ paddingTop: '40px', paddingLeft: '15px' }}>
                                                            <span style={{ fontSize: '1.9vw', fontWeight: 'bolder', color:'#133B95'}}>CALENDARIO INTERVENTI</span>
                                                        </div>
                                                        <div className='col-lg-9 col-md-9 col-sm-9 col-xs-12 text-end' style={{ paddingTop: '60px' }}> 
                                                            <RadioButtonComponent id="radioCalendGener" label="Calendario Generale Interventi" name="SceltaCalendario" checked={false} value="CGENE" change={this.onChangeTipoCalend}></RadioButtonComponent>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                            <RadioButtonComponent id="radioCalendAddetti" label="Calendario Addetti" name="SceltaCalendario" checked={true} value="CPIAN" change={this.onChangeTipoCalend}></RadioButtonComponent>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                            <RadioButtonComponent id="radioCalendGoogle" label="Calendario Google" name="SceltaCalendario" checked={false} value="CGOOG" change={this.onChangeTipoCalend}></RadioButtonComponent>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                            <ProgressButtonComponent id='btnAggiornaCalend' content="Aggiorna Calendari" enableProgress duration='1000' spinSettings={{ position: 'Center' }} 
                                                                animationSettings={{effect: 'SlideRight'}} created={this.ProgrButtCreated1.bind(this)} cssClass="e-fill e-info"></ProgressButtonComponent> 
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="control-section card-control-section basic_card_layout">
                            <div className="e-card-resize-container">
                                <div className="card-layout">
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">                                
                                        <div tabIndex="0" className="e-card" id="main_card">
                                            <div className="e-card-header">
                                                <div className="e-card-header-caption">
                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                                            <div id="titoloCalendInterv" className="e-card-header-title" style={{fontSize:'1.8em'}}></div>
                                                        </div>
                                                        <div id="divDateCalendario" className="col-lg-2 offset-lg-2 col-md-3 offset-md-1 col-sm-3 offset-sm-1 col-xs-2 offset-xs-2" style={{ display: 'none' }}>
                                                            <DateRangePickerComponent id='RangeDateVisualCalend' format='dd/MM/yyyy' allowEdit="false" showClearButton="false"
                                                                    ref={ drpObj => this.drpInstance = drpObj } placeholder='Periodo Visualizzazione (Inizio Lavori Da - A)' floatLabelType='Auto'>
                                                            </DateRangePickerComponent>
                                                        </div>
                                                        <div id="divTipoIncarichi" className="col-lg-2 col-md-2 col-sm-2 col-xs-2 text-end" style={{ display: 'none' }}>
                                                             <DropDownListComponent id='ddlTipoIncarichi' fields={this.ddlFields} ref={ ddlObj => this.ddlnstance2 = ddlObj } change={this.onChangeTipoIncarichi.bind(this)}  
                                                                        dataSource={this.dsTipoIncarichi} placeholder="Visualizza Incarichi" floatLabelType='Auto' popupHeight="250px" cssClass='e-outline'/>       
                                                        </div>
                                                        <div id="divCalendariGoogle" className="col-lg-3 offset-lg-3 col-md-3 offset-md-3 col-sm-3 offset-sm-3 col-xs-3 offset-xs-3 text-end" style={{ display: 'none' }}>
                                                             <DropDownListComponent id='ddlCalendariGoogle' fields={this.ddlFields} ref={ ddlObj => this.ddlnstance1 = ddlObj } change={this.onChangeCalendGoogle.bind(this)}  
                                                                        placeholder="Calendario Google Visualizzato" floatLabelType='Auto' popupHeight="250px" cssClass='e-outline'/>       
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="e-card-content">  
                                                <div id="CalendarioGenerale" className="row">
                                                    <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'> 
                                                        <ScheduleComponent id='CalendarioInterventiGenerale' ref={(schedule) => this.scheduleObj1 = schedule} width='100%' height='750px' currentView={this.state.vistaCorrente}  
                                                            rowAutoHeight={true} selectedDate={this.state.dataCorrente} readonly={true} eventRendered={this.onEventRendered3} actionComplete={this.onActionCompleteIntervGenerale}
                                                            enableHtmlSanitizer={false}>
                                                        <Inject services={[Day, Week, WorkWeek, Month, Year, Agenda, TimelineViews, TimelineMonth, TimelineYear, DragAndDrop, Resize, Print, ExcelExport, ICalendarImport, ICalendarExport]}/>
                                                        </ScheduleComponent>              
                                                    </div>
                                                    <div>
                                                        <ContextMenuComponent ref={menu => this.menuObj1 = menu} target='#CalendarioInterventiGenerale' items={this.menuItems} beforeOpen={this.onContextMenuBeforeOpen.bind(this)} select={this.onMenuItemSelect1.bind(this)}/>
                                                    </div>
                                                </div>
                                                <div id="CalendarioAddetti" className="row">
                                                    <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'> 
                                                        <ScheduleComponent id='CalendarioInterventiAddetti' ref={(schedule) => this.scheduleObj2 = schedule} width='100%' height='750px' currentView={this.state.vistaCorrente} 
                                                            rowAutoHeight={true} selectedDate={this.state.dataCorrente} readonly={true} eventRendered={this.onEventRendered2} popupOpen={this.onPopupOpen} actionComplete={this.onActionCompleteIntervAddetti}
                                                            enableHtmlSanitizer={false}>              
                                                            <ResourcesDirective>
                                                                <ResourceDirective field='IdAddetto' title="Addetti" name="Addetti" textField="NomeAddetto" idField="IdAddetto" colorField="ColoreAddetto" allowMultiple={true}>                                                        
                                                                </ResourceDirective>
                                                            </ResourcesDirective>    
                                                        <Inject services={[Day, Week, WorkWeek, Month, Year, Agenda, TimelineViews, TimelineMonth, TimelineYear, DragAndDrop, Resize, Print, ExcelExport, ICalendarImport, ICalendarExport]}/>
                                                        </ScheduleComponent>
                                                    </div>
                                                    <div>
                                                        <ContextMenuComponent ref={menu => this.menuObj2 = menu} target='#CalendarioInterventiAddetti' items={this.menuItems} beforeOpen={this.onContextMenuBeforeOpen.bind(this)} select={this.onMenuItemSelect2.bind(this)}/>
                                                    </div>
                                                </div>
                                                <div id="CalendarioGoogle" className="row">
                                                    <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>     
                                                        <ScheduleComponent id='CalendarioEventiGoogle' ref={(schedule) => this.scheduleObj3 = schedule} width='100%' height='750px' currentView={this.state.vistaCorrente}
                                                                rowAutoHeight={true} selectedDate={this.state.dataCorrente} eventRendered={this.onEventRendered} popupOpen={this.onPopupOpen2} readonly={true} actionComplete={this.onActionCompleteEventiGoogle}
                                                                enableHtmlSanitizer={false}>
                                                        <Inject services={[Day, Week, WorkWeek, Month, Year, Agenda, TimelineViews, TimelineMonth, TimelineYear, DragAndDrop, Resize, Print, ExcelExport, ICalendarImport, ICalendarExport]}/>
                                                        </ScheduleComponent>              
                                                    </div>
                                                    <div>
                                                        <ContextMenuComponent ref={menu => this.menuObj3 = menu} target='#CalendarioEventiGoogle' items={this.menuItems} beforeOpen={this.onContextMenuBeforeOpen.bind(this)} select={this.onMenuItemSelect3.bind(this)}/>
                                                    </div>             
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </BlockUi>
            </React.Fragment>);
    }
    componentDidMount() {
        sessionStorage.setItem('JellyfishTeamsMobileArrivoDaCalend', 'false');
        this.drpInstance.startDate = CustomJS.isNullEmpty(sessionStorage.getItem('JellyfishTeamsMobileDataCalendarioDa')) ? new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1) 
                                     : new Date(sessionStorage.getItem('JellyfishTeamsMobileDataCalendarioDa'));
        this.drpInstance.endDate = CustomJS.isNullEmpty(sessionStorage.getItem('JellyfishTeamsMobileDataCalendarioA')) ? new Date(new Date().getFullYear(), new Date().getMonth() + 2, 0) 
                                   : new Date(sessionStorage.getItem('JellyfishTeamsMobileDataCalendarioA'));
        this.ddlnstance2.value = 'TUTTI';
        this.onChangeTipoCalend();
        this.caricaDati();
    }
    caricaDati() {
        const datiSoc = {
            CodDiv: JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileAuthenticationPayload')).data[0].COD_DIV,
            CodSoc: JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileAuthenticationPayload')).data[0].COD_SOC,
            CodFil: JSON.parse(sessionStorage.getItem('JellyfishTeamsMobileAuthenticationPayload')).data[0].COD_FIL,
            LinguaAppl: 'ITA',
            FormatoData: 'dd/MM/yyyy'
        }
        this.toggleBlocking();
        CustomJS.getRemoteData4(`${CustomJS.URLApplicazioneProd}/api/CalendarioInterventiFromOutside/CalendarioInterventi`, 
             { dati_soc_json: JSON.stringify(datiSoc), dataCalendarioDa: moment(this.drpInstance.startDate).format('YYYY/MM/DD'), dataCalendarioA: moment(this.drpInstance.endDate).format('YYYY/MM/DD') },
             'POST', {'Authorization':  CustomJS.tokenAuthAPIAppTeamsBackoffice }, retData => {
            this.toggleBlocking();
            this.ddlnstance1.dataSource = retData.Rest.Item6;
            this.listeEventiGoogle = retData.Rest.Item4;
            this.tuttiInterventiGenerali = _.union(retData.Item1, retData.Item2, retData.Item3, retData.Item4, retData.Item5, retData.Rest.Item7, retData.Rest.Rest.Item2, retData.Rest.Rest.Item4, retData.Rest.Rest.Item6, retData.Rest.Rest.Rest.Item1); // Tutti gli interventi
            this.interventiGeneraliAperti = _.union(retData.Item1, retData.Item2, retData.Item3, retData.Rest.Rest.Rest.Item1); //Tutti esclusi i lavori finiti
            this.tuttiInterventiAddetti = _.union(retData.Item6, retData.Item7, retData.Rest.Item1, retData.Rest.Item2, retData.Rest.Item3, retData.Rest.Rest.Item1, retData.Rest.Rest.Item3, retData.Rest.Rest.Item5, retData.Rest.Rest.Item7, retData.Rest.Rest.Rest.Item1); // Tutti gli interventi
            this.interventiAddettiAperti = _.union(retData.Item6, retData.Item7, retData.Rest.Item1, retData.Rest.Rest.Rest.Item1); //Tutti esclusi i lavori finiti
            setTimeout(() => {
                this.scheduleObj1.eventSettings.dataSource = this.ddlnstance2.value === 'APERTI' ? this.interventiGeneraliAperti : this.tuttiInterventiGenerali;
                this.scheduleObj2.resources[0].dataSource = retData.Rest.Item5;
                this.scheduleObj2.eventSettings.dataSource = this.ddlnstance2.value === 'APERTI' ? this.interventiAddettiAperti : this.tuttiInterventiAddetti;
                this.scheduleObj3.eventSettings.dataSource = this.listeEventiGoogle.length > 0 ? this.listeEventiGoogle[0] : null;
                this.ddlnstance1.value = '0';
            }, 300);
            sessionStorage.setItem('JellyfishTeamsMobileDataCalendarioDa', moment(this.drpInstance.startDate).format('YYYY/MM/DD'));
            sessionStorage.setItem('JellyfishTeamsMobileDataCalendarioA', moment(this.drpInstance.endDate).format('YYYY/MM/DD'));
            let calendarioScelto = sessionStorage.getItem("JellyfishTeamsMobileCalendarioScelto");
            let idCalendarioScelto = calendarioScelto === "calendAddetti" ? 'CalendarioInterventiAddetti' : calendarioScelto === "calendGenerale" ? 'CalendarioInterventiGenerale' : 'CalendarioEventiGoogle';
            setTimeout(() => {
                if(!CustomJS.isNullEmpty(calendarioScelto)) {
                    if(calendarioScelto === "calendAddetti") {
                       this.onChangeTipoCalend({ value: 'CPIAN' }); 
                       document.getElementById('radioCalendAddetti').ej2_instances[0].checked = true;
                    }
                    else if(calendarioScelto === "calendGenerale") {
                       this.onChangeTipoCalend({ value: 'CGENE' }); 
                       document.getElementById('radioCalendGener').ej2_instances[0].checked = true;
                    }
                    else if(calendarioScelto === "calendGoogle") {
                       this.onChangeTipoCalend({ value: 'CGOOG' }); 
                       document.getElementById('radioCalendGoogle').ej2_instances[0].checked = true;
                    }
                }
                else {
                    this.onChangeTipoCalend({ value: 'CPIAN' }); 
                    document.getElementById('radioCalendAddetti').ej2_instances[0].checked = true;
                }
                if(!CustomJS.isNullEmpty(sessionStorage.getItem("JellyfishTeamsMobileActiveCellsData"))){
                   setTimeout(()=>{ 
                        this.setState({
                            selectedDate: new Date(sessionStorage.getItem("JellyfishTeamsMobileActiveCellsData")),
                            currentView: sessionStorage.getItem("JellyfishTeamsMobileCurrentView")
                        });
                    }, 300);
                }      
                $('.e-all-day-appointment-section.e-icons.e-appointment-expand').click();
            }, 500); 
        }, error => {
            let messaggio = _.isString(error) ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
            toast.warn(messaggio, {
                containerId: 'toastContainer6',
                onClose: () => toast.dismiss(),                    
                position: "bottom-right",
                autoClose: 15000,
                hideProgressBar: false,            
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                newestOnTop: true,
                rtl: false,
                pauseOnFocusLoss: true});
            this.toggleBlocking();
        }, true, false);
    }
}